import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addLoan,
  updateLoan,
  addLoanBorrower,
  updateLoanBorrower,
  deleteLoanBorrower,
  addLoanGuarantor,
  updateLoanGuarantor,
  deleteLoanGuarantor,
  deleteLoan,
  duplicateLoan,
  shareLoan,
  transferLoan,
} from 'admin/services/api/loans'
import {
  KEY_DOCUMENTS,
  KEY_LOAN_CHARGES,
  KEY_LOANS,
  KEY_LENDER_OF_RECORD,
} from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Loan, NewLoan } from 'types'
import { message } from 'utils/message'

const useAddLoan = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (loan: NewLoan) => addLoan(loan),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS] })
      message.success('Loan created')
    },
    onError: handleErrorResponse,
  })
}

const useDuplicateLoan = ({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: (loan: Loan) => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => duplicateLoan(id),
    onSuccess: (loan: Loan) => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS] })
      message.success('Loan duplicated')
      onSuccess && onSuccess(loan)
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoan = ({
  id,
  silent,
  onSuccess,
}: {
  id?: string
  silent?: boolean
  onSuccess?: () => void
} = {}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: Partial<Loan>) => updateLoan({ id, ...data }),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      !silent && message.success('Loan updated')
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_CHARGES, id] })
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS] })
      queryClient.invalidateQueries({ queryKey: [KEY_LENDER_OF_RECORD] })
      onSuccess && onSuccess()
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoan = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteLoan(id),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [KEY_LOANS], exact: true })
        message.success('Loan deleted')
      }, 50)
    },
    onError: handleErrorResponse,
  })
}

const useAddLoanBorrower = ({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: { id: string; borrow: { primary: boolean } }) =>
      addLoanBorrower({ id, borrower: data }),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      queryClient.invalidateQueries({
        queryKey: [KEY_LOANS, loan.id, 'people'],
      })
      message.success('Loan updated')
      onSuccess && onSuccess()
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanBorrower = ({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      borrowerId,
      primary,
    }: {
      borrowerId: string
      primary: boolean
    }) => updateLoanBorrower({ id, borrowerId, primary }),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      queryClient.invalidateQueries({
        queryKey: [KEY_LOANS, loan.id, 'people'],
      })
      message.success('Loan updated')
      onSuccess && onSuccess()
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanBorrower = ({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (borrowerId: string) => deleteLoanBorrower({ id, borrowerId }),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      queryClient.invalidateQueries({
        queryKey: [KEY_LOANS, loan.id, 'people'],
      })
      message.success('Loan updated')
      onSuccess && onSuccess()
    },
    onError: handleErrorResponse,
  })
}

const useAddLoanGuarantor = ({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: { id: string; guarantee: { primary: boolean } }) =>
      addLoanGuarantor({ id, guarantor: data }),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      queryClient.invalidateQueries({
        queryKey: [KEY_LOANS, loan.id, 'people'],
      })
      message.success('Loan updated')
      onSuccess && onSuccess()
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanGuarantor = ({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      guarantorId,
      primary,
    }: {
      guarantorId: string
      primary: boolean
    }) => updateLoanGuarantor({ id, guarantorId, primary }),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      queryClient.invalidateQueries({
        queryKey: [KEY_LOANS, loan.id, 'people'],
      })
      message.success('Loan updated')
      onSuccess && onSuccess()
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanGuarantor = ({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (guarantorId: string) =>
      deleteLoanGuarantor({ id, guarantorId }),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      queryClient.invalidateQueries({
        queryKey: [KEY_LOANS, loan.id, 'people'],
      })
      message.success('Loan updated')
      onSuccess && onSuccess()
    },
    onError: handleErrorResponse,
  })
}

const useShareLoan = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      clientId,
      message,
      accepted,
    }: {
      clientId?: string
      message?: string
      accepted?: boolean
    }) => shareLoan({ id, clientId, message, accepted }),
    onSuccess: (loan) => {
      if (loan.id) {
        queryClient.setQueryData([KEY_LOANS, loan.id], loan)
      }
      if (loan.id !== id) {
        queryClient.invalidateQueries({ queryKey: [KEY_LOANS] })
      }
    },
    onError: handleErrorResponse,
  })
}

const useTransferLoan = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      clientId,
      message,
      accepted,
    }: {
      clientId?: string
      message?: string
      accepted?: boolean
    }) => transferLoan({ id, clientId, message, accepted }),
    onSuccess: (loan) => {
      if (loan.id) {
        queryClient.setQueryData([KEY_LOANS, loan.id], loan)
      }
      if (loan.id !== id) {
        setTimeout(
          () => queryClient.invalidateQueries({ queryKey: [KEY_LOANS] }),
          300
        )
      }
    },
    onError: handleErrorResponse,
  })
}

export {
  useAddLoan,
  useUpdateLoan,
  useDeleteLoan,
  useAddLoanBorrower,
  useUpdateLoanBorrower,
  useDeleteLoanBorrower,
  useAddLoanGuarantor,
  useUpdateLoanGuarantor,
  useDeleteLoanGuarantor,
  useDuplicateLoan,
  useShareLoan,
  useTransferLoan,
}
