import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { KEY_LOAN_BUDGET } from 'constants/query-keys'
import {
  getBudget,
  addBudgetItem,
  updateBudgetItem,
  updateBudgetStatus,
  deleteBudgetItem,
} from 'services/api/budget'
import { BudgetItem, BudgetStatus, NewBudgetItem } from 'types'
import { handleErrorResponse } from '../services/request'

const useBudget = (loanId: string, addressId: string) => {
  return useQuery({
    queryKey: [KEY_LOAN_BUDGET, loanId, addressId],
    queryFn: () => getBudget(loanId, addressId),
  })
}

const useAddBudgetItem = (
  loanId: string,
  addressId: string,
  silent = false
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (item: NewBudgetItem) => addBudgetItem(loanId, addressId, item),
    onSuccess: () => {
      if (!silent) {
        queryClient.invalidateQueries({
          queryKey: [KEY_LOAN_BUDGET, loanId, addressId],
        })
      }
    },
    onError: handleErrorResponse,
  })
}

const useUpdateBudgetItem = (
  loanId: string,
  addressId: string,
  silent = false
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (item: Partial<BudgetItem & { message?: string }>) =>
      updateBudgetItem(loanId, addressId, item),
    onSuccess: () => {
      if (!silent) {
        queryClient.invalidateQueries({
          queryKey: [KEY_LOAN_BUDGET, loanId, addressId],
        })
      }
    },
    onError: handleErrorResponse,
  })
}

const useUpdateBudgetStatus = (loanId: string, addressId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (status: BudgetStatus) =>
      updateBudgetStatus(loanId, addressId, status),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId],
      })
    },
    onError: handleErrorResponse,
  })
}

const useDeleteBudgetItem = (loanId: string, addressId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (itemId: string) => deleteBudgetItem(loanId, addressId, itemId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId],
      })
    },
    onError: handleErrorResponse,
  })
}

export {
  useBudget,
  useAddBudgetItem,
  useUpdateBudgetItem,
  useUpdateBudgetStatus,
  useDeleteBudgetItem,
}
