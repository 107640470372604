import { Formik } from 'formik'
import { useState, useCallback, useMemo } from 'react'
import { Flex } from 'components/Flex'
import { Form } from 'components/Form'
import { sendLoanMagicLink } from 'services/api/application'
import { createScheme, email, mergeRules, required } from 'utils/schemas'
import { CheckInbox } from './CheckInbox'
import { SendMagicLink } from './SendMagicLink'
import styles from './styles.module.scss'

interface MagicLinkFormProps {
  isSubmitting: boolean
  isSubmitted: boolean
  preSubmitHook: () => Promise<{ loanId: string; token: string } | null>
  onBackToParent: () => void
}

const MagicLinkForm = ({
  isSubmitting,
  isSubmitted,
  onBackToParent,
  preSubmitHook,
}: MagicLinkFormProps) => {
  const [showCheckInbox, setShowCheckInbox] = useState(false)

  const initialValues = useMemo(
    () => ({
      'user-name': '',
      'user-email': '',
    }),
    []
  )

  const handleSubmit = useCallback(
    async (values: { 'user-name': string; 'user-email': string }) => {
      try {
        const result = await preSubmitHook()

        if (!result) {
          console.error('Error: No result available for magic link')
          return
        }

        await sendLoanMagicLink(
          values['user-email'],
          values['user-name'],
          result
        )
        setShowCheckInbox(true)
      } catch (error) {
        console.error('Error during submission:', error)
      }
    },
    [preSubmitHook, setShowCheckInbox]
  )

  const validationSchema = createScheme({
    'user-name': required,
    'user-email': mergeRules(email, required),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formContext) => (
        <Form>
          <Flex stack gap={32} className={styles.panel}>
            {showCheckInbox ? (
              <CheckInbox
                onReenterEmail={() => {
                  setShowCheckInbox(false)
                }}
                email={formContext.values['user-email']}
              />
            ) : (
              <SendMagicLink
                onBack={onBackToParent}
                magicLinkButtonLoading={isSubmitting}
                backButtonDisabled={isSubmitted || isSubmitting}
                hideBackButton={isSubmitted}
              />
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export { MagicLinkForm }
