import { cloneDeep, isEqual, omit } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { TopMenu } from 'admin/components/TopMenu'
import {
  useAddTaskTemplateTask,
  useEditTaskTemplateTask,
} from 'admin/hooks/use-task-templates'
import { Button } from 'components/Button'
import { Drawer } from 'components/Drawer'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { TaskTemplateTask } from 'types'
import { TemplateTaskForm } from './TemplateTaskForm'

interface Props {
  templateId: string
  templateTask?: TaskTemplateTask
  onClose: () => void
  onDelete: (id: string) => void
}
function TemplateTaskDrawer({
  templateId,
  templateTask: currentTask,
  onClose,
  onDelete,
}: Props) {
  const [task, setTask] = useState<Partial<TaskTemplateTask>>(
    cloneDeep(currentTask) || {
      name: '',
      description: '',
      daysDue: '' as unknown as number,
      subtasks: [],
      owners: [],
    }
  )

  const { mutate: addTask, isPending: isAdding } =
    useAddTaskTemplateTask(templateId)
  const { mutate: editTask, isPending: isEditing } =
    useEditTaskTemplateTask(templateId)

  const handleSave = useCallback(() => {
    if (task.id) {
      editTask(
        {
          ...task,
          daysDue: (task.daysDue as unknown) === '' ? undefined : task.daysDue,
          owners: task.owners?.map(
            ({ id }) => id
          ) as unknown as TaskTemplateTask['owners'],
        } as TaskTemplateTask,
        { onSuccess: onClose }
      )
    } else {
      addTask(
        {
          ...task,
          taskTemplateId: templateId,
          daysDue: (task.daysDue as unknown) === '' ? undefined : task.daysDue,
          subtasks: task.subtasks?.filter(({ name }) => !!name),
          owners: task.owners?.map(
            ({ id }) => id
          ) as unknown as TaskTemplateTask['owners'],
        },
        { onSuccess: onClose }
      )
    }
  }, [task, addTask])

  const handleTaskChange = useCallback(
    (nextTask: Partial<TaskTemplateTask>) => {
      setTask((prevTask) => ({ ...prevTask, ...nextTask }))
    },
    []
  )

  const isEditDirty = useMemo(() => {
    return (
      !!currentTask &&
      !isEqual(omit(task, 'subtasks'), omit(currentTask, 'subtasks'))
    )
  }, [task, currentTask])

  return (
    <Drawer onClose={onClose} className="px-4 max-w-[640px]">
      <Drawer.Header className="px-2.5 pr-0 -mx-4">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="h-full"
        >
          <Flex alignItems="center">
            <Button variant="ghost" onClick={onClose} className="w-7 h-7">
              <Icon
                name={IconName.doubleArrowRight}
                className="text-black-100"
              />
            </Button>
            <Text className="font-bold">{task.id ? '' : 'New Task'}</Text>
          </Flex>
          {isEditDirty || !currentTask ? (
            <Flex alignItems="center" gap={10} className="h-12">
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                loading={isAdding || isEditing}
                onClick={handleSave}
                className="mr-4"
              >
                Save
              </Button>
            </Flex>
          ) : (
            <TopMenu />
          )}
        </Flex>
      </Drawer.Header>
      <Drawer.Content className="w-auto mt-4 pt-2 pl-0 pr-4 mr-[-14px]">
        <TemplateTaskForm
          task={task}
          onChange={handleTaskChange}
          onDelete={onDelete}
        />
      </Drawer.Content>
    </Drawer>
  )
}

export { TemplateTaskDrawer }
