import { memo, useMemo } from 'react'
import { useLoanSubstatuses } from 'admin/hooks/use-loan-substatuses'
import { Flex } from 'components/Flex'
import { Indicator } from 'components/Indicator'
import { Text } from 'components/Text'
import { substatusToColor } from 'constants/substatus'

interface Props {
  substatus: string
  className?: string
}

const SubstatusBadge = memo(({ substatus }: Props) => {
  const { data: substatuses } = useLoanSubstatuses()

  const color = useMemo(() => {
    const color = substatuses?.find(({ name }) => name === substatus)?.colour
    return color || substatusToColor(substatus)
  }, [substatuses, substatus])

  return (
    <Flex alignItems="center" gap={6}>
      <Flex alignItems="center" justifyContent="center" className="w-3 h-3">
        <Indicator color={color} className="rounded-[3px]" />
      </Flex>
      <Text variant="m">{substatus}</Text>
    </Flex>
  )
})

SubstatusBadge.displayName = 'SubstatusBadge'

export { SubstatusBadge }
