import { without } from 'lodash'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDockContext } from 'admin/components/Dock/DockContext'
import { DockThreadTab } from 'admin/components/DockThreadTab/DockThreadTab'
import { ModalLinkContact } from 'admin/pages/Thread/PanelsForLinking/ModalLinkContact'
import { useThreadContext } from 'admin/pages/Thread/ThreadContext'
import { pathTo } from 'admin/path-to'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Redact } from 'components/Redact'
import { Person } from 'types'

const LinkedContacts = () => {
  const navigate = useNavigate()
  const [isLinkingToContactModal, setIsLinkingToContactModal] = useState(false)
  const { thread, updateThread } = useThreadContext()
  const { addTab } = useDockContext()

  const unlinkContact = useCallback(
    (id: string) => {
      updateThread({
        people: without(
          thread.people.map(({ id }) => id),
          id
        ),
      })
    },
    [updateThread, thread.people]
  )

  const handleContactClick = useCallback(
    (contact: Person) => {
      if (contact.isBorrower) {
        navigate(pathTo('borrower', contact.id))
      }
      if (contact.isInvestor) {
        navigate(pathTo('investor', contact.id))
      }
      if (contact.isVendor) {
        navigate(pathTo('vendor', contact.id))
      }
      addTab({
        id: thread.id as string,
        title: thread.mails[0]?.subject as string,
        content: <DockThreadTab threadId={thread.id as string} />,
        expandByDefault: true,
        onMaximizeClick: () => navigate(pathTo('thread', thread.id as string)),
      })
    },
    [navigate]
  )

  return (
    <div className="mt-3">
      <div className="uppercase text-[10px] text-grey-600 font-bold">
        in this conversation
      </div>
      {thread.people.length ? (
        <Flex
          stack
          gap={0}
          className="w-full mt-4 rounded border-solid border-[1px] border-grey-200"
        >
          {thread.people.map((contact) => (
            <Flex
              key={contact.id}
              gap={8}
              alignItems="center"
              justifyContent="space-between"
              onClick={() =>
                contact.hasAccess ? handleContactClick(contact) : null
              }
              className={[
                'py-2.5 px-2 border-0 border-b last:border-b-0 border-grey-200 border-solid',
                contact.hasAccess && 'cursor-pointer',
              ]
                .filter(Boolean)
                .join(' ')}
            >
              <Flex gap={12} alignItems="center">
                <Avatar
                  id={contact.id}
                  name={contact.hasAccess ? contact.name : ''}
                  className="h-7 w-7 basis-7 text-[11px]"
                />
                <div className="text-black-100">
                  <Redact value={contact.name} hide={!contact.hasAccess} />
                </div>
              </Flex>
              <EllipsesActions>
                <EllipsesActions.Item
                  onSelect={() => unlinkContact(contact.id)}
                >
                  <Icon
                    name={IconName.unlinkIcon}
                    className="mr-2 text-grey-600"
                  />
                  Unlink contact
                </EllipsesActions.Item>
              </EllipsesActions>
            </Flex>
          ))}
        </Flex>
      ) : (
        <div className="mt-4 mb-5 text-grey-900 leading-[18px]">
          Find an existing contact to link to.
        </div>
      )}
      <Button
        variant="secondary"
        onClick={() => setIsLinkingToContactModal(true)}
        className="w-full mt-2.5"
      >
        Link to Contact
      </Button>
      {isLinkingToContactModal && (
        <ModalLinkContact onClose={() => setIsLinkingToContactModal(false)} />
      )}
    </div>
  )
}

export { LinkedContacts }
