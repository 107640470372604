import { request } from 'services/request'
import { IBudgetWorksheet } from 'types'

const getBudgetWorksheets = async (): Promise<{
  worksheets: IBudgetWorksheet[]
}> => {
  const {
    data: { worksheets },
  } = await request.get('/budget/worksheet')
  return { worksheets }
}

const getBudgetWorksheet = async (
  worksheetId: string
): Promise<IBudgetWorksheet> => {
  const {
    data: { worksheet },
  } = await request.get(`/budget/worksheet/${worksheetId}`, {
    params: { noCamelCase: true },
  })
  return worksheet
}

const addBudgetWorksheet = async (
  payload: Partial<IBudgetWorksheet>
): Promise<IBudgetWorksheet> => {
  const {
    data: { worksheet },
  } = await request.post('/budget/worksheet', payload)
  return worksheet
}

const updateBudgetWorksheet = async ({
  id,
  ...payload
}: Partial<IBudgetWorksheet>): Promise<IBudgetWorksheet> => {
  const {
    data: { worksheet },
  } = await request.patch(`/budget/worksheet/${id}`, payload, {
    params: { noSnakeCase: true, noCamelCase: true },
  })
  return worksheet
}

const duplicateBudgetWorksheet = async (
  id: string
): Promise<IBudgetWorksheet> => {
  const {
    data: { worksheet },
  } = await request.put(`/budget/worksheet/${id}`)
  return worksheet
}

const deleteBudgetWorksheet = async (
  worksheetId: string
): Promise<{ success: boolean }> => {
  const { data } = await request.delete(`/budget/worksheet/${worksheetId}`)
  return data
}

export {
  getBudgetWorksheets,
  getBudgetWorksheet,
  addBudgetWorksheet,
  updateBudgetWorksheet,
  duplicateBudgetWorksheet,
  deleteBudgetWorksheet,
}
