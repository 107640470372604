import { get, isArray, omit, toNumber, toString } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { attributeTypeToJssType } from 'admin/pages/Settings/Worksheet/fields'
import { getSectionsOrder } from 'admin/pages/Settings/Worksheet/helpers'
import { isLoanServicing, pathToLoan } from 'admin/path-to'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { jspreadsheet } from 'components/Spreadsheet'
import { useBudgetWorksheet } from 'hooks/use-budget-worksheet'
import {
  useAddBudgetItem,
  useBudget,
  useUpdateBudgetItem,
} from 'hooks/use-loan-budget'
import { useLoan } from 'hooks/use-loans'
import { useSession } from 'hooks/use-session'
import { Field } from 'types'
import { formatUsd } from 'utils/currency'
import { formatField } from 'utils/fields'
import { sumDecimal } from 'utils/math'
import { isAdminRoute } from 'utils/routes'
import { SectionWorksheet } from './SectionWorksheet'
import './styles.module.scss'

const LoanScopeOfWork = () => {
  const navigate = useNavigate()
  const [totalBySection, setTotalBySection] = useState<Record<string, number>>()
  const [saving, setSaving] = useState(false)
  const [savingPromises, setSavingPromises] = useState<Promise<unknown>[]>([])
  const { loanId, addressId } = useParams() as {
    loanId: string
    addressId: string
  }
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    jspreadsheetLicense: undefined,
  })
  jspreadsheet.setLicense(
    settings.jspreadsheetLicense ?? import.meta.env.VITE_APP_JSPREADSHEET
  )
  const { data: loan } = useLoan({ id: loanId })
  const { data: budget } = useBudget(loanId, addressId)
  const { data: worksheet } = useBudgetWorksheet(
    loan?.settings.budgetWorksheetId || 'default'
  )
  console.log(worksheet)
  const { mutateAsync: addBudgetItem } = useAddBudgetItem(
    loanId,
    addressId,
    true
  )
  const { mutateAsync: updateBudgetItem } = useUpdateBudgetItem(
    loanId,
    addressId,
    true
  )

  const handleBack = useCallback(() => {
    if (isAdminRoute()) {
      navigate(pathToLoan(loan!, 'loanTab', 'scopeOfWork'))
    } else {
      const isActiveLoan = isLoanServicing(loan!.status)
      if (isActiveLoan) {
        navigate(pathTo('loanTabEntityId', loanId, 'scopeOfWork', addressId))
      } else {
        navigate(
          pathTo('applicationSubmitted', loanId, 'scopeOfWork', addressId)
        )
      }
    }
  }, [loan, loanId, addressId])

  const columns = useMemo(() => {
    return (
      worksheet?.attributes.map((attribute) => {
        const type = attribute.type || 'text'
        const jssType = attributeTypeToJssType.get(type)

        return {
          title: attribute.name,
          name: attribute.name,
          type: jssType,
          autoCasting: false,
          source: attribute.options?.map(({ label }) => label),
          locale: [
            'number',
            'decimal',
            'percentage',
            'currency',
            'date',
          ].includes(type)
            ? 'en-US'
            : undefined,
          width: 200,
          align: ['number', 'decimal', 'percentage', 'currency'].includes(type)
            ? 'right'
            : 'left',
          render: (td: HTMLTableCellElement, value: string) => {
            if (td && jssType !== 'checkbox') {
              td.innerText = formatField({
                type: [attribute.type],
                property: { value: [value] },
              } as Field)
            }
          },
          options: {
            ...(type === 'option' && { newOptions: false }),
            ...(type === 'currency' && {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
          },
        }
      }) || []
    )
  }, [worksheet])

  const sectionsOrder = useMemo(() => getSectionsOrder(worksheet), [worksheet])

  const itemsBySection = useMemo(
    () =>
      worksheet?.show_sections
        ? budget?.items?.reduce(
            (acc, item) => {
              const worksheetItem = worksheet.items.find(
                (worksheetItem) => worksheetItem['Work Item'] === item.name
              )
              const section = sectionsOrder.includes(item.section!)
                ? item.section!
                : worksheetItem?.Section || ''
              if (!acc[section]) {
                acc[section] = []
              }
              const row = columns.map(({ name }) => {
                if (name === 'Work Item') {
                  return { 'Work Item': item.name }
                }
                if (name === 'Amount') {
                  return { Amount: toString(item.amount) }
                }
                return {
                  [name]: isArray(item.data)
                    ? item.data.find((data) => data.name === name)?.value || ''
                    : '',
                }
              })
              acc[section].push(Object.assign({ id: item.id }, ...row))
              return acc
            },
            {} as Record<string, Record<string, string>[]>
          )
        : {
            '': budget?.items?.reduce(
              (acc, item) => {
                const row = columns.map(({ name }) => {
                  if (name === 'Work Item') {
                    return { 'Work Item': item.name }
                  }
                  if (name === 'Amount') {
                    return { Amount: toString(item.amount) }
                  }
                  return {
                    [name]: isArray(item.data)
                      ? item.data.find((data) => data.name === name)?.value ||
                        ''
                      : '',
                  }
                })
                acc.push(Object.assign({ id: item.id }, ...row))
                return acc
              },
              [] as Record<string, string>[]
            ),
          },
    [sectionsOrder, worksheet, budget]
  )

  const handleSave = useCallback(
    async (data: Record<string, string>[], section: string) => {
      const promises = data.map((row) => {
        const isEmpty = Object.values(row).every((value) => !value)
        if (!isEmpty) {
          // if it's a new budget item
          if (!row.id) {
            return addBudgetItem({
              amount: toNumber(row.Amount),
              name: row['Work Item'],
              section,
              data: Object.entries(
                omit(row, 'Amount', 'Work Item', 'Section')
              ).map(([name, value]) => ({ name, value })),
            })
          }
          // otherwise check if we should update it
          const initialBudgetItem = budget?.items?.find(
            ({ id }) => id === row.id
          )
          const isDirty = Object.entries(row).some(([key, value]) => {
            if (key === 'Amount') {
              return toNumber(value) !== toNumber(initialBudgetItem?.amount)
            }
            if (key === 'Work Item') {
              return value !== initialBudgetItem?.name
            }
            return (
              value !==
              initialBudgetItem?.data?.find(({ name }) => name === key)?.value
            )
          })
          if (isDirty) {
            return updateBudgetItem({
              id: row.id,
              amount: toNumber(row.Amount),
              name: row['Work Item'],
              section,
              data: Object.entries(
                omit(row, 'Amount', 'Work Item', 'Section')
              ).map(([name, value]) => ({ name, value })),
            })
          }
        }
        return Promise.resolve()
      })

      setSavingPromises((currentPromises) => [...currentPromises, ...promises])
    },
    [navigate, budget, loan]
  )

  const handleTotalChange = useCallback(
    (sectionTotal: number, section: string) => {
      setTotalBySection((totalBySection) => ({
        ...totalBySection,
        [section]: sectionTotal,
      }))
    },
    []
  )

  const total = useMemo(() => {
    return sectionsOrder.reduce((acc, section) => {
      const sectionAmount =
        totalBySection?.[section] ??
        sumDecimal(itemsBySection?.[section]?.map(({ Amount }) => Amount))
      return acc + sectionAmount
    }, 0)
  }, [sectionsOrder, totalBySection, itemsBySection])

  useEffect(() => {
    if (savingPromises.length) {
      Promise.all(savingPromises).then(() => {
        handleBack()
        setSaving(false)
      })
    }
  }, [loan, savingPromises, handleBack])

  return (
    <MainContent className="scopeOfWorkCss p-0">
      {worksheet && itemsBySection ? (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className="md:h-12 pl-2"
          >
            <Flex alignItems="center" gap={4}>
              <Button variant="ghost" icon onClick={handleBack}>
                <Icon name={IconName.arrowLeftLong} />
              </Button>
              <Header variant="h4">Scope of Work</Header>
            </Flex>
            <Flex alignItems="center" gap={24} className="pr-5">
              <Flex alignItems="center" gap={4}>
                Total Cost: {formatUsd(total)}
              </Flex>
              <Button
                variant="primary"
                loading={saving}
                onClick={() => setSaving(true)}
              >
                Save &amp; Exit
              </Button>
            </Flex>
          </Flex>
          <div className="relative overflow-x-auto flex-grow pb-10">
            <div className="bg-[#f3f3f3] absolute p-3 w-full border border-solid border-[#ccc] leading-4">
              <div className="border border-solid border-transparent">
                &nbsp;
              </div>
            </div>
            {sectionsOrder.map((section, index) => (
              <SectionWorksheet
                key={section}
                columns={columns}
                data={itemsBySection[section]}
                section={section}
                sectionItems={worksheet.items
                  .filter(
                    ({ Section }) =>
                      !worksheet.show_sections ||
                      (worksheet.show_sections && Section === section)
                  )
                  .map(({ 'Work Item': name }) => name)}
                hideHeaders={index !== 0}
                lastSection={index === sectionsOrder.length - 1}
                saving={saving}
                onSave={handleSave}
                onTotalChange={handleTotalChange}
              />
            ))}
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { LoanScopeOfWork }
