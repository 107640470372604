import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApplicationScheme } from 'admin/hooks/use-application'
import { pathTo } from 'borrower/path-to'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { PageLoader } from 'components/LoaderOverlay'
import { Logo } from 'components/Logo'
import { useClient } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'
import { readDraftGuestApplication } from 'services/storage'
import { GuestApplication } from './GuestApplication'
import styles from './styles.module.scss'

function GuestApplicationPage() {
  const navigate = useNavigate()
  const { user } = useSession()
  const { data: client } = useClient()
  useEffect(() => {
    if (user?.actor?.isBorrower) {
      navigate(pathTo('applications'))
    }
  }, [user])

  const savedApplication = readDraftGuestApplication()
  const { data: defaultScheme } = useApplicationScheme(
    { clientId: client?.id },
    { enabled: !savedApplication && !!client?.id }
  )
  const scheme = savedApplication || defaultScheme

  return (
    <div className={styles.layout}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className={styles.header}
      >
        <Header variant="h2">New Application</Header>
        <Logo to="/" type="statement" />
      </Flex>
      <div className={styles.content}>
        {scheme && client?.id ? (
          <GuestApplication
            clientId={client.id}
            scheme={scheme}
            requireMagicLink={scheme.requireMagicLink}
          />
        ) : (
          <PageLoader />
        )}
      </div>
    </div>
  )
}

export { GuestApplicationPage }
