import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { isArray } from 'lodash'
import { Fragment, useMemo } from 'react'
import { getSectionsOrder } from 'admin/pages/Settings/Worksheet/helpers'
import { BadgeBudgetStatus } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Thead, BodyRow } from 'components/Table'
import { Empty } from 'components/Table/Empty'
import tableStyles from 'components/Table/styles.module.scss'
import styles from 'components/Table/styles.module.scss'
import { useTable } from 'components/Table/use-table'
import { Tooltip } from 'components/Tooltip'
import { BudgetItem, Field, IBudgetWorksheet } from 'types'
import { formatUsd } from 'utils/currency'
import { formatField } from 'utils/fields'
import { sumDecimal } from 'utils/math'

interface Props {
  worksheet: IBudgetWorksheet
  data?: BudgetItem[]
  onRemove?: (item: BudgetItem) => void
  onEdit?: (item: BudgetItem) => void
  onAccept?: (item: BudgetItem) => void
  onReject?: (item: BudgetItem, message?: string) => void
}

const rejectReasons = [
  'Budget details incomplete',
  'Amounts mismatch with project scope',
  'Ambiguous or unclear line items',
  'Overestimated expenses',
  'Other',
]

function TableRehabBudget({
  worksheet,
  data = [],
  onRemove,
  onEdit,
  onAccept,
  onReject,
}: Props) {
  const columns: ColumnDef<BudgetItem>[] = useMemo(
    () => [
      ...worksheet.attributes.map((attr) => ({
        header: attr.name,
        id: attr.name,
        cell: ({ row }) => {
          if (attr.name === 'Amount') {
            return formatUsd(row.original.amount)
          }
          if (attr.name === 'Work Item') {
            return row.original.name
          }
          const dataItem = isArray(row.original.data)
            ? row.original.data.find(({ name }) => attr.name === name)
            : undefined
          return formatField({
            type: [attr.type],
            property: { value: [dataItem?.value] },
          } as Field)
        },
      })),
      {
        header: 'Status',
        accessorKey: 'status',
        size: 100,
        cell: ({ row }) => {
          if (row.original.status === 'Rejected' && row.original.reason) {
            return (
              <Tooltip content={row.original.reason}>
                <span>
                  <BadgeBudgetStatus
                    status={row.original.status}
                    reason={row.original.reason}
                  />
                </span>
              </Tooltip>
            )
          }

          return <BadgeBudgetStatus status={row.original.status} />
        },
      },
      {
        id: 'actions',
        header: '',
        size: 40,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              {onAccept ? (
                <EllipsesActions.Item
                  icon
                  onSelect={() => onAccept(row.original)}
                >
                  <Icon name={IconName.check} />
                  Accept
                </EllipsesActions.Item>
              ) : (
                <></>
              )}
              {onReject ? (
                <EllipsesActions.MenuItem
                  label={
                    <Flex gap={6} alignItems="center">
                      <Icon name={IconName.close} />
                      Reject
                    </Flex>
                  }
                >
                  {rejectReasons.map((reason) => (
                    <EllipsesActions.Item
                      key={reason}
                      onSelect={() => onReject(row.original, reason)}
                    >
                      {reason}
                    </EllipsesActions.Item>
                  ))}
                </EllipsesActions.MenuItem>
              ) : (
                <></>
              )}
              <EllipsesActions.Item
                icon
                disabled={!onEdit}
                onSelect={() => onEdit && onEdit(row.original)}
              >
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                disabled={!onRemove}
                onSelect={() => onRemove && onRemove(row.original)}
                variant="danger"
              >
                <Icon name={IconName.delete} />
                Remove
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    [worksheet]
  )

  const sectionsOrder = useMemo(() => getSectionsOrder(worksheet), [worksheet])

  const itemsBySection = useMemo(
    () =>
      worksheet.show_sections
        ? data.reduce(
            (acc, item) => {
              const worksheetItem = worksheet.items.find(
                (worksheetItem) => worksheetItem['Work Item'] === item.name
              )
              const section = sectionsOrder.includes(item.section!)
                ? item.section!
                : worksheetItem?.Section || ''
              if (!acc[section]) {
                acc[section] = []
              }
              acc[section].push(item)
              return acc
            },
            {} as Record<string, BudgetItem[]>
          )
        : { '': data },
    [sectionsOrder, worksheet, data]
  )

  const { table, isEmpty, isScrollbarVisible, hadData, tableWrapperEl } =
    useTable<BudgetItem>({
      data: data,
      columns: columns,
      getRowId: (originalRow: BudgetItem) => originalRow.id,
    })

  return (
    <div className={tableStyles.tableWrapper} ref={tableWrapperEl}>
      <table
        className={clsx(tableStyles.table, {
          [styles.firstColumnBorder]: isScrollbarVisible,
          [styles.empty]: isEmpty,
        })}
      >
        <Thead headerGroups={table.getHeaderGroups()} />
        <tbody>
          {isEmpty && (
            <tr>
              <td colSpan={columns.length}>
                <Empty hadData={hadData} />
              </td>
            </tr>
          )}
          {sectionsOrder.map((section) => {
            const sectionItems = itemsBySection[section] || []
            const total = sumDecimal(sectionItems.map(({ amount }) => amount))

            return (
              <Fragment key={section}>
                {section !== '' &&
                  worksheet.show_sections &&
                  sectionItems.length > 0 && (
                    <tr>
                      <td
                        colSpan={columns.length}
                        className={clsx(tableStyles.td, 'bg-grey-50 px-3 py-4')}
                      >
                        <Flex justifyContent="space-between">
                          <div className="font-bold">{section}</div>
                          <div>
                            Section total:{' '}
                            <span className="font-bold">
                              {formatUsd(total)}
                            </span>
                          </div>
                        </Flex>
                      </td>
                    </tr>
                  )}
                {sectionItems.map((item) => {
                  const row = table.getRow(item.id)
                  return <BodyRow key={row.id} row={row} />
                })}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TableRehabBudget
