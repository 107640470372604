import {
  autoUpdate,
  FloatingPortal,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { AssociatedLabels } from 'admin/components/AssociatedLabels'
import { ParticipantBadge } from 'admin/pages/Loan/TabContacts/ParticipantBadge'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Input } from 'components/Input'
import { Text } from 'components/Text'
import { Loan, Person } from 'types'

interface Props {
  className?: string
  size?: 'medium' | 'large'
  person: Person | undefined
  loan: Loan
  onSelect: (person: Person) => void
}

function SelectLoanPerson({
  person,
  loan,
  className,
  size = 'medium',
  onSelect,
}: Props) {
  const [search, setSearch] = useState('')
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [open, setOpen] = useState(false)

  const loanContacts = useMemo(
    () =>
      [
        ...loan.borrowers
          .map((borrower) => {
            // check if this borrower is guarantor
            const guarantor = loan.guarantors.find(
              ({ id }) => id === borrower.id
            )
            if (guarantor) {
              return {
                ...borrower,
                guarantee: guarantor.guarantee,
              }
            }
            return borrower
          })
          .sort((a) => (a.borrow.primary ? -1 : 1)),
        ...loan.guarantors
          .filter(({ id }) =>
            loan.borrowers.every((borrower) => borrower.id !== id)
          )
          .sort((a) => (a.guarantee.primary ? -1 : 1)),
      ].filter(
        (person) =>
          person.name.toLowerCase().includes(search.toLowerCase()) &&
          !!person.email
      ),
    [loan, search]
  )

  const { x, y, refs, context, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [shift()],
  })
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [useRole(context, { role: 'menu' }), useDismiss(context)]
  )

  const handleSelect = useCallback(
    (person: Person) => {
      onSelect(person)
      setIsSearchMode(false)
      setOpen(false)
      setSearch('')
    },
    [onSelect]
  )

  useEffect(() => {
    if (!open) {
      setIsSearchMode(false)
      setSearch('')
    }
  }, [open])

  return (
    <div className={clsx('inline-block', className)}>
      <Flex
        className={clsx(
          'rounded py-1 cursor-pointer',
          size === 'large' && 'pl-3 pr-1.5 py-2'
        )}
        justifyContent="space-between"
        alignItems="center"
        gap={6}
        {...getReferenceProps({ ref: refs.setReference })}
      >
        <Input
          placeholder="Search..."
          value={isSearchMode ? search : person?.name}
          onChange={(e) => {
            setIsSearchMode(true)
            setSearch(e.target.value)
          }}
          onFocus={() => setOpen(true)}
          className="w-full"
        />
      </Flex>
      {open && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: refs.reference.current?.getBoundingClientRect().width,
              },
            })}
            className="z-2 border-solid border p-1 bg-white-100 rounded border-grey-100 cursor-pointer shadow-300 overflow-y-auto max-h-[300px]"
          >
            {!loanContacts?.length ? (
              <Flex
                stack
                alignItems="center"
                justifyContent="center"
                className="pt-12 pb-16"
              >
                <Icon
                  name={IconName.magnifyingGlass}
                  className="text-grey-500 w-7 h-7"
                />
                <Text variant="l">No search results</Text>
              </Flex>
            ) : (
              <div>
                {!!loanContacts.length && (
                  <div className="text-grey-700 px-2 pt-3 pb-2 font-bold text-sm">
                    Contacts in this loan
                  </div>
                )}
                {loanContacts.map((person) => {
                  return (
                    <Fragment key={person.id}>
                      <Flex
                        justifyContent="left"
                        alignItems="center"
                        gap={8}
                        className="p-2 rounded hover:bg-grey-75"
                        {...getItemProps({
                          onClick: () => handleSelect(person),
                        })}
                      >
                        <Avatar id={person.id} name={person.name} />
                        <Flex stack gap={4} className="flex-grow">
                          <div className="whitespace-nowrap">
                            <AssociatedLabels
                              name={person.name}
                              associatedWith={person.associatedWith}
                            />
                          </div>
                          {person.email && (
                            <div className="whitespace-nowrap text-grey-700">
                              {person.email}
                            </div>
                          )}
                        </Flex>
                        <ParticipantBadge participant={person} />
                      </Flex>
                    </Fragment>
                  )
                })}
              </div>
            )}
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}

export { SelectLoanPerson }
