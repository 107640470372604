import { request } from 'services/request'
import { Person } from 'types'

const getLoanPeople = async ({
  loanId,
}: {
  loanId: string
}): Promise<Person[]> => {
  const {
    data: { people },
  } = await request.get(`/loan/${loanId}/people`)
  return people
}

const addLoanViewer = async ({
  loanId,
  id,
}: {
  loanId: string
  id: string
}): Promise<{ people: Person[] }> => {
  const {
    data: { people },
  } = await request.put(`/loan/${loanId}/viewer`, { id })
  return { people }
}

export { getLoanPeople, addLoanViewer }
