import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ModalSignature } from 'borrower/components/ModalSignature'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { Logo } from 'components/Logo'
import { TextLink } from 'components/TextLink'
import { useInvalidateDocument, usePublicDocuments } from 'hooks/use-document'
import { pluralize } from 'utils/pluralize'

export const PublicDocuments = () => {
  const { authToken } = useParams()
  const invalidateDocument = useInvalidateDocument()
  if (!authToken) {
    return
  }
  const [signDocumentId, setSignDocumentId] = useState<string>()
  const { data, isLoading } = usePublicDocuments(authToken)
  const userId = useMemo(() => data?.person.id, [data])
  const visibleDocuments = useMemo(
    () =>
      data?.documents.filter(({ esignature }) => !!esignature?.status) || [],
    [data]
  )
  const pendingDocuments = useMemo(
    () =>
      visibleDocuments.filter(
        (document) =>
          document.esignature?.currentSigner === userId &&
          !document.esignature?.currentSigned
      ) || [],
    [visibleDocuments, userId]
  )
  const pendingDocumentIds = useMemo(
    () => pendingDocuments.map(({ id }) => id),
    [pendingDocuments]
  )

  return isLoading ? (
    <PageLoader />
  ) : (
    <div>
      <Flex
        justifyContent="center"
        alignItems="center"
        className="h-13 p-2 bg-grey-900"
      >
        <Logo to="/" />
      </Flex>

      <Flex justifyContent="center" className="mt-16 px-4 md:px-16">
        <Flex stack gap={46} alignItems="center" className="max-w-xxl w-full">
          <Flex
            stack
            gap={6}
            alignItems="center"
            className="text-5xl text-center"
          >
            <div className="font-bold text-grey-800">
              Hi {data?.person.name},
            </div>
            <div className="text-grey-700">
              You have {pendingDocuments.length || 'no'} pending signature{' '}
              {pluralize(pendingDocuments.length, 'request', false)}
            </div>
          </Flex>

          <Flex stack gap={0} className="w-full py-6">
            {visibleDocuments.map((document) => (
              <Flex
                key={document.id}
                justifyContent="space-between"
                className="py-5 px-2 border-0 border-b last:border-b-0 border-grey-200 border-solid"
              >
                <Flex gap={12} alignItems="center">
                  <Icon
                    name={IconName.documentBlank}
                    className="text-grey-600"
                  />
                  <div className="text-black-100">{document.name}</div>
                </Flex>
                <Flex gap={12} alignItems="center" className="px-3.5 font-bold">
                  {pendingDocumentIds.includes(document.id) ? (
                    <TextLink
                      onClick={() => setSignDocumentId(document.id)}
                      className="!text-blue-100"
                    >
                      Review & Sign
                      <Icon name={IconName.arrowRight} />
                    </TextLink>
                  ) : (
                    <div className="w-[100px] flex gap-2">
                      {['declined', 'recalled'].includes(document.status) ? (
                        <Icon
                          name={IconName.minusCircle}
                          size="lg"
                          className="text-red-100"
                        />
                      ) : (
                        <Icon
                          name={IconName.circleCheck}
                          size="lg"
                          className="text-lime-100"
                        />
                      )}
                      <div>
                        {document.esignature?.status === 'In Progress' &&
                        document.esignature?.currentSigned === true
                          ? 'Signed'
                          : document.esignature?.status || 'Signed'}
                      </div>
                    </div>
                  )}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

      {signDocumentId && (
        <ModalSignature
          isPublic
          authToken={data?.authToken}
          document={visibleDocuments.find(({ id }) => id === signDocumentId)}
          onClose={() => {
            setSignDocumentId(undefined)
            invalidateDocument()
          }}
        />
      )}
    </div>
  )
}
