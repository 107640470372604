import clsx from 'clsx'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo as adminPathTo } from 'admin/path-to'
import {
  useDeleteApplication,
  useDeleteDraftApplication,
  useUpdateApplication,
} from 'borrower/hooks/use-application'
import { pathTo as borrowerPathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { useDraftApplication } from 'hooks/use-application'
import { useDownloadPdf } from 'hooks/use-pdf'
import { isAdminRoute } from 'utils/routes'
import { PanelApplicationPage } from './PanelApplicationPage'
import styles from './styles.module.scss'

interface Props {
  loanId: string
  applicationId: string
  btnClassName?: string
  showActions?: boolean
  isDraft?: boolean
}

export const TabLoanApplication = ({
  loanId,
  applicationId,
  btnClassName,
  showActions = false,
  isDraft = false,
}: Props) => {
  const navigate = useNavigate()
  const onSuccess = () => navigate(borrowerPathTo('applications'))
  const { mutate: deleteApplication } = useDeleteApplication()
  const { mutate: archiveDraft } = useDeleteDraftApplication({
    isArchived: true,
  })
  const { mutate: deleteDraftApplication } = useDeleteDraftApplication()
  const { data: application } = useDraftApplication(applicationId)
  const { mutate: archiveSubmitted } = useUpdateApplication({
    id: applicationId,
    isArchived: true,
  })
  const { mutate: update } = useUpdateApplication({
    id: applicationId,
    dontSetQueryData: true,
  })
  const { mutate: generatePdf } = useDownloadPdf()
  const pathTo = isAdminRoute() ? adminPathTo : borrowerPathTo

  const handleArchive = useCallback(() => {
    isDraft
      ? archiveDraft(applicationId, { onSuccess })
      : archiveSubmitted({ status: 'archived' }, { onSuccess })
  }, [isDraft, applicationId])

  const handleDeleteApplication = useCallback(() => {
    isDraft
      ? deleteDraftApplication(applicationId, { onSuccess })
      : deleteApplication(applicationId, { onSuccess })
  }, [isDraft, applicationId])

  return !application ? (
    <PageLoader />
  ) : (
    <div className={styles.tabContent}>
      {showActions ? (
        <EllipsesActions
          trigger={
            <div className={clsx(styles.tabButton, btnClassName)}>
              <Button iconRight={<Icon name={IconName.arrowDown} />}>
                Actions
              </Button>
            </div>
          }
        >
          <EllipsesActions.Item
            icon
            onSelect={() =>
              generatePdf({ url: pathTo('loanApplicationPrintable', loanId) })
            }
          >
            <Icon name={IconName.download} size="sm" />
            Download PDF
          </EllipsesActions.Item>
          <EllipsesActions.Item
            icon
            onSelect={() => update({ status: 'draft' }, { onSuccess })}
          >
            <Icon name={IconName.edit} />
            Withdraw
          </EllipsesActions.Item>
          <EllipsesActions.Item icon onSelect={handleArchive}>
            <Icon name={IconName.upload} size="sm" />
            Archive
          </EllipsesActions.Item>
          <EllipsesActions.Item icon onSelect={handleDeleteApplication}>
            <Icon name={IconName.delete} />
            Delete
          </EllipsesActions.Item>
        </EllipsesActions>
      ) : (
        <Button
          className={clsx(styles.tabButton, btnClassName)}
          onClick={() =>
            generatePdf({ url: pathTo('loanApplicationPrintable', loanId) })
          }
          iconLeft={<Icon name={IconName.download} size="sm" />}
        >
          Download PDF
        </Button>
      )}
      <Flex stack gap={16}>
        {application.data.pages.map((page) => (
          <PanelApplicationPage
            key={page.id}
            page={page}
            dateSubmitted={application.dateSubmitted}
          />
        ))}
      </Flex>
    </div>
  )
}
