import { RowData, ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { BodyLoader } from 'components/Table/BodyLoader'
import { BodyRow } from 'components/Table/BodyRow'
import { Empty } from 'components/Table/Empty'
import { Thead } from 'components/Table/Thead'
import { useTable } from 'components/Table/use-table'
import { Sort, ColumnMeta } from 'types'
import TableCell from './Cell'
import styles from './styles.module.scss'

interface Props<T> {
  loading?: boolean
  className?: string
  wrapperClassName?: string
  minWidth?: number
  columns: Array<ColumnDef<T>>
  data?: Array<T>
  sort?: Sort
  noResultsEmptyScreen?: boolean
  onSort?: (sort: Sort | string | undefined) => void
  onFileDrop?: (row: T, files: FileList) => void
  onClick?: (row?: T) => void
}

const Table = <T extends RowData>({
  loading,
  className,
  wrapperClassName,
  minWidth = 0,
  data = [],
  sort,
  noResultsEmptyScreen = false,
  onSort,
  columns,
  onFileDrop,
  onClick,
}: Props<T>) => {
  const {
    table,
    hadData,
    isScrollbarVisible,
    tableWrapperEl,
    rowDropId,
    setRowDropId,
    isTopTotalRowVisible,
    isLoaderVisible,
    isEmpty,
    handleDrag,
    handleDragLeave,
  } = useTable<T>({ data, loading, columns, onFileDrop })

  return (
    <div
      className={clsx(styles.tableWrapper, wrapperClassName)}
      ref={tableWrapperEl}
    >
      <table
        className={clsx(
          styles.table,
          {
            [styles.firstColumnBorder]: isScrollbarVisible,
            [styles.loading]: isLoaderVisible,
            [styles.empty]: isEmpty,
          },
          className
        )}
        style={{ minWidth }}
        onDragLeave={handleDragLeave}
      >
        <Thead
          isLoaderVisible={isLoaderVisible}
          headerGroups={table.getHeaderGroups()}
          sort={sort}
          onSort={onSort}
        />

        <tbody onDragLeave={(e) => e.stopPropagation()}>
          {isEmpty && (
            <tr>
              <td colSpan={columns.length}>
                <Empty hadData={hadData || noResultsEmptyScreen} />
              </td>
            </tr>
          )}
          {isLoaderVisible ? (
            <BodyLoader />
          ) : (
            <>
              {isTopTotalRowVisible ? (
                <tr>
                  {table
                    .getRowModel()
                    .rows[0].getVisibleCells()
                    .map((cell) => {
                      const meta = cell.column.columnDef.meta as ColumnMeta
                      const row = table.getRowModel().rows[0]

                      return (
                        <td
                          key={cell.id}
                          data-label={
                            typeof cell.column.columnDef.header === 'string'
                              ? cell.column.columnDef.header
                              : meta?.plainHeader || ''
                          }
                          className={clsx(
                            styles.td,
                            '!bg-grey-75',
                            (row.original as any)?.rowClassName
                          )}
                        >
                          <TableCell {...cell.getContext()}>
                            {meta?.getTopCellValue?.({
                              rows: table.getRowModel().rows,
                            })}
                          </TableCell>
                        </td>
                      )
                    })}
                </tr>
              ) : null}
              {table.getRowModel().rows.map((row) => (
                <BodyRow
                  key={row.id}
                  row={row}
                  onClick={onClick}
                  onDragEnter={handleDrag}
                  updateRowDropId={setRowDropId}
                  rowDropId={rowDropId}
                  onFileDrop={onFileDrop}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

Table.displayName = 'Table'

export default Table
