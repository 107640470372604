import clsx from 'clsx'
import { debounce } from 'lodash'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  forwardRef,
  ForwardedRef,
} from 'react'
import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'

interface SearchProps {
  className?: string
  fieldClassName?: string
  delay?: number
  placeholder?: string
  search?: string
  autoFocus?: boolean
  onSearch: Dispatch<SetStateAction<string | undefined>>
}

const Search = forwardRef(
  (
    {
      className,
      fieldClassName,
      delay = 500,
      placeholder = 'Search',
      autoFocus = true,
      search,
      onSearch,
    }: SearchProps,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    const handleBlur = useCallback((e: MouseEvent) => {
      const { value } = e.target as HTMLInputElement
      onSearch(value || undefined)
    }, [])

    const handleChange = useCallback(
      debounce((e: KeyboardEvent) => {
        const { value } = e.target as HTMLInputElement
        onSearch(value || undefined)
      }, delay),
      []
    )

    return (
      <div className={clsx(styles.field, fieldClassName)}>
        <Icon
          className={styles.inputIcon}
          name={IconName.magnifyingGlass}
          size="md"
        />
        <input
          ref={ref}
          type="search"
          defaultValue={search}
          className={clsx(styles.input, className)}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onBlur={handleBlur as any}
          onChange={handleChange as any}
        />
      </div>
    )
  }
)

Search.displayName = 'Search'

export default Search
