import { isBefore, parseISO } from 'date-fns'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { Flex } from 'components/Flex'

const TabApplicationIndicator = () => {
  const { loan } = useLoanContext()
  const { applicationId, dateApplicationRequested, dateApplicationSubmitted } =
    loan
  const isRequested =
    dateApplicationRequested &&
    (!dateApplicationSubmitted ||
      (dateApplicationSubmitted &&
        isBefore(
          parseISO(dateApplicationSubmitted),
          parseISO(dateApplicationRequested)
        )))

  if (!applicationId && isRequested) {
    return (
      <Flex className="w-3 h-3" justifyContent="center" alignItems="center">
        <div className="border-[1.5px] border-solid border-blueberry-9 w-2 h-2 rounded-xs" />
      </Flex>
    )
  }
  if (!applicationId) {
    return (
      <Flex className="w-3 h-3" justifyContent="center" alignItems="center">
        <div className="border-[1.5px] border-solid border-grey-500 w-2 h-2 rounded-xs" />
      </Flex>
    )
  }
  if (applicationId && isRequested) {
    return (
      <Flex className="w-3 h-3" justifyContent="center" alignItems="center">
        <div className="bg-orange-100 w-2 h-2 rounded-xs" />
      </Flex>
    )
  }
  if (applicationId) {
    return (
      <Flex className="w-3 h-3" justifyContent="center" alignItems="center">
        <div className="bg-lime-100 w-2 h-2 rounded-xs" />
      </Flex>
    )
  }
  return null
}

export { TabApplicationIndicator }
