import { request } from 'services/request'
import { Loan } from 'types'

const requestApplication = async ({
  loanId,
  borrowerId,
  subject,
  body,
}: {
  loanId: string
  borrowerId: string
  subject: string
  body: string
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.post(`/loan/${loanId}/application`, {
    borrowerId,
    subject,
    body,
  })
  return loan
}

export { requestApplication }
