import clsx from 'clsx'
import { isSameDay } from 'date-fns'
import DOMPurify from 'dompurify'
import { useRef, useState, useEffect, useCallback, memo, useMemo } from 'react'
import { useThreadMail } from 'admin/hooks/use-threads'
import { useThreadContext } from 'admin/pages/Thread/ThreadContext'
import { ThreadForm } from 'admin/pages/Thread/ThreadForm'
import { Avatar } from 'components/Avatar'
import { Badge } from 'components/Badge'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IMail } from 'types'
import { friendlyDateTime, formatRelativeDate } from 'utils/date'
import { MailActions } from './MailActions'
import { MailAttachments } from './MailAttachments'

interface Props {
  mailId: string
  isFirstMail?: boolean
  isLastMail?: boolean
  showActions?: boolean
  showDraftInEditor?: boolean
  className?: string
  onDraftClick?: (mail: IMail) => void
  onReply?: (mail: IMail) => void
  onDelete?: (mail: IMail) => void
}

const Mail = memo(
  ({
    mailId,
    isFirstMail = false,
    isLastMail = false,
    showActions = false,
    showDraftInEditor = false,
    className,
    onDraftClick,
    onReply,
    onDelete,
  }: Props) => {
    const bodyRef = useRef<HTMLIFrameElement>(null)
    const [addingMode, setAddingMode] = useState<
      'reply' | 'reply-all' | 'forward'
    >()
    const { thread, isAdding } = useThreadContext()
    const threadMail = useMemo(
      () => thread.mails.find(({ id }) => id === mailId)!,
      [thread.mails, mailId]
    )
    const { data: responseMail } = useThreadMail(
      { mailId },
      { enabled: threadMail.isDraft }
    )
    const mail = threadMail.isDraft && responseMail ? responseMail : threadMail
    const [isExpanded, setIsExpanded] = useState(isLastMail && !mail.isDraft)
    const [isShort, setIsShort] = useState(!isFirstMail)
    const { addMail } = useThreadContext()

    const handleReply = useCallback(
      (mode: 'reply' | 'reply-all' | 'forward') => {
        setAddingMode(mode)
        if (mode === 'reply') {
          addMail(
            { replyTo: mail.id },
            {
              onSuccess: (mail: IMail) => {
                onReply?.(mail)
                setAddingMode(undefined)
              },
            }
          )
        }
        if (mode === 'reply-all') {
          addMail(
            { replyAllTo: mail.id },
            {
              onSuccess: (mail: IMail) => {
                onReply?.(mail)
                setAddingMode(undefined)
              },
            }
          )
        }
        if (mode === 'forward') {
          addMail(
            { forwardTo: mail.id },
            {
              onSuccess: (mail: IMail) => {
                onReply?.(mail)
                setAddingMode(undefined)
              },
            }
          )
        }
      },
      [addMail, onReply, mail.id]
    )
    const handleHeaderClick = useCallback(() => {
      if (mail.isDraft) {
        onDraftClick?.(mail)
      } else {
        !isLastMail && setIsExpanded((state) => !state)
      }
    }, [onDraftClick, mail, isLastMail])

    useEffect(() => {
      const setHeight = () => {
        if (bodyRef.current) {
          bodyRef.current.style.height = '1px'
          bodyRef.current.style.height = `${bodyRef.current.contentDocument?.body.scrollHeight}px`
        }
      }

      if (isExpanded && bodyRef.current) {
        bodyRef.current.addEventListener('load', setHeight)
        const doc = bodyRef.current.contentDocument!

        doc.open()
        doc.write(DOMPurify.sanitize(isShort ? mail.bodyShort : mail.body))
        doc.close()

        doc.body.style.fontFamily = 'Arial, sans-serif'
        doc.body.style.fontSize = '13px'
        doc.body.style.lineHeight = '1.4'
        doc.body.style.color = '#343332'
        doc.body.style.overflowY = 'hidden'
        doc.body.style.marginLeft = '0px'
        doc.body.style.marginTop = '0px'
        doc.body.style.marginRight = '0px'
        doc.body.style.marginBottom = '0px'

        const links = doc.querySelectorAll('a')
        links.forEach((link) => {
          link.setAttribute('target', '_blank')
          link.setAttribute('rel', 'noopener noreferrer')
        })

        return () => bodyRef.current?.removeEventListener('load', setHeight)
      }
    }, [isExpanded, isShort, mail.body])

    const formattedDate = useMemo(() => {
      const date = mail.date || mail.updatedAt
      return `${
        isSameDay(date, Date.now())
          ? friendlyDateTime(date, 'h:mm a')
          : friendlyDateTime(date, 'MMM d')
      } 
      (${formatRelativeDate(date)})`
    }, [mail.date, mail.updatedAt])

    return showDraftInEditor && mail.isDraft ? (
      <div
        className={clsx(
          'pt-6 pr-4 pb-5 pl-[68px] border-0 border-b border-solid border-grey-200',
          className
        )}
      >
        <ThreadForm thread={thread} mail={mail} onDelete={onDelete} />
      </div>
    ) : (
      <>
        <Flex
          stack
          gap={20}
          className={clsx(
            'py-5 px-4 border-0 border-b border-solid border-grey-200',
            className
          )}
        >
          <Flex
            gap={20}
            justifyContent="space-between"
            className={clsx((!isLastMail || mail.isDraft) && 'cursor-pointer')}
            onClick={handleHeaderClick}
          >
            <Flex gap={20} className="truncate">
              <div className="rounded-full">
                <Avatar
                  id={mail.from.email}
                  name={mail.from.name || mail.from.email}
                  className="h-8 w-8 text-base cursor-pointer"
                />
              </div>
              <Flex stack gap={4} className="overflow-hidden">
                <Flex
                  gap={8}
                  className="text-lg text-grey-900 font-bold leading-5 truncate"
                >
                  {mail.from.name || mail.from.email}
                  {mail.isDraft && <Badge color="red">Draft</Badge>}
                </Flex>
                <div className="text-grey-700 leading-[18px]">
                  {isExpanded ? (
                    <Flex stack gap={4}>
                      <div className="truncate">
                        To:{' '}
                        {mail.to
                          .map((mailTo) => mailTo.name || mailTo.email)
                          .join(', ')}
                      </div>
                      {mail.cc.length > 0 && (
                        <div className="truncate">
                          Cc:{' '}
                          {mail.cc
                            .map((mailTo) => mailTo.name || mailTo.email)
                            .join(', ')}
                        </div>
                      )}
                    </Flex>
                  ) : (
                    <div className="truncate">{mail.subject}</div>
                  )}
                </div>
              </Flex>
            </Flex>
            <Flex gap={20} alignItems="center" className="h-8">
              <div className="text-sm text-grey-700 leading-[18px] whitespace-nowrap">
                {formattedDate}
              </div>
              {isExpanded && (
                <Flex gap={10} alignItems="center">
                  <Button
                    variant="ghost"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleReply('reply')
                    }}
                    className="w-8"
                  >
                    <Icon
                      name={IconName.arrowReply}
                      size="md"
                      className="text-grey-600"
                    />
                  </Button>
                  <MailActions
                    onReplyAll={() => handleReply('reply-all')}
                    onForward={() => handleReply('forward')}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
          {isExpanded && (
            <Flex stack gap={0} className="mb-3 ml-[52px]">
              <iframe ref={bodyRef} className="border-0" />
              {isShort && (
                <div className="ml-[-10px]">
                  <Button variant="ghost" onClick={() => setIsShort(false)}>
                    <Icon name={IconName.ellipses} />
                  </Button>
                </div>
              )}
              <MailAttachments attachments={mail.attachments} mailId={mailId} />
            </Flex>
          )}
        </Flex>

        {showActions && (
          <Flex gap={8} className="ml-[52px] py-5 px-4">
            {[
              {
                id: 'reply',
                buttonName: 'Reply',
                iconName: IconName.arrowReply,
              },
              {
                id: 'reply-all',
                buttonName: 'Reply all',
                iconName: IconName.arrowReplyAll,
              },
              {
                id: 'forward',
                buttonName: 'Forward',
                iconName: IconName.arrowForward,
              },
            ].map((btn) => (
              <Button
                key={btn.id}
                variant="ghost"
                loading={isAdding && addingMode === btn.id}
                onClick={() =>
                  handleReply(btn.id as 'reply' | 'reply-all' | 'forward')
                }
              >
                <Icon name={btn.iconName} className="text-grey-600" />
                <div className="leading-5 text-grey-900">{btn.buttonName}</div>
              </Button>
            ))}
          </Flex>
        )}
      </>
    )
  }
)

Mail.displayName = 'Mail'

export { Mail }
