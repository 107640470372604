import {
  ColumnDef,
  getCoreRowModel,
  getFacetedUniqueValues,
  useReactTable,
} from '@tanstack/react-table'
import {
  DragEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useScrollToId } from 'hooks/use-scroll-to-id'
import { ColumnMeta } from 'types'
import { getColumnSizes } from './helpers'

interface Props<T> {
  loading?: boolean
  columns: Array<ColumnDef<T>>
  data: Array<T>
  onFileDrop?: (row: T, files: FileList) => void
  getRowId?: (row: T) => string
}

const useTable = <T>({
  data,
  loading,
  columns,
  onFileDrop,
  getRowId,
}: Props<T>) => {
  const [hadData, setHadData] = useState(false)
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)
  const [columnWidths, setColumnWidths] = useState<number[]>([])
  const tableWrapperEl = useRef<HTMLDivElement>(null)
  const [tableWidth, setTableWidth] = useState(
    tableWrapperEl.current?.offsetWidth || 0
  )
  const [rowDropId, setRowDropId] = useState<string>()
  useEffect(() => {
    setTimeout(() => {
      if (!loading && tableWrapperEl.current) {
        setIsScrollbarVisible(
          tableWrapperEl.current.scrollWidth >
            tableWrapperEl.current.clientWidth + 1
        )
      }
    }, 1)
  }, [
    tableWrapperEl.current?.scrollWidth,
    tableWrapperEl.current?.clientWidth,
    loading,
  ])

  useLayoutEffect(() => {
    setTableWidth(tableWrapperEl.current?.offsetWidth || 0)
  }, [])

  useEffect(() => {
    if (columns.length !== columnWidths.length && data.length) {
      setColumnWidths(getColumnSizes(columns, data, tableWidth))
    }
  }, [columnWidths, columns, data, tableWidth])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const rect = entries[0].contentRect
      // resize should happen only when table was initially rendered
      if (tableWidth !== rect.width && columnWidths.length) {
        setTableWidth(rect.width)
        setColumnWidths(getColumnSizes(columns, data, rect.width))
      }
    })
    if (tableWrapperEl.current) {
      resizeObserver.observe(tableWrapperEl.current)
    }
    return () => resizeObserver?.disconnect()
  }, [columns, data, tableWidth, columnWidths])

  useEffect(() => {
    if (data.length) {
      setHadData(true)
    }
  }, [data])

  const table = useReactTable({
    data,
    columns: columns.map(
      (column, index) =>
        ({
          ...column,
          size: (columnWidths?.[index] || 'auto') as unknown as number,
          meta: column.meta || {},
        }) as ColumnDef<T>
    ),
    getCoreRowModel: getCoreRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId,
  })

  const isEmpty = !loading && table.getRowModel().rows.length === 0
  const isLoaderVisible =
    (loading && table.getRowModel().rows.length === 0) ||
    (columnWidths.length === 0 && !isEmpty)
  const isTopTotalRowVisible = !!(
    table.getRowModel().rows?.[0]?.getVisibleCells()?.[0]?.column?.columnDef
      ?.meta as ColumnMeta
  )?.getTopCellValue
  useScrollToId({ enabled: !isLoaderVisible })

  const handleDrag = useCallback(
    (rowId: string, e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (onFileDrop && (e.type === 'dragenter' || e.type === 'dragover')) {
        setRowDropId(rowId)
      }
    },
    [onFileDrop]
  )
  const handleDragLeave = useCallback(() => {
    setRowDropId(undefined)
    setTimeout(() => setRowDropId(undefined), 1)
  }, [])

  return {
    table,
    hadData,
    isScrollbarVisible,
    tableWrapperEl,
    rowDropId,
    setRowDropId,
    isTopTotalRowVisible,
    isLoaderVisible,
    isEmpty,
    handleDrag,
    handleDragLeave,
  }
}

export { useTable }
