import { FormikProps } from 'formik'
import { compact, isString, isBoolean, has, isArray } from 'lodash'
import { ReactElement, ReactNode, useCallback } from 'react'
import ReactSelect, {
  components as reactSelectComponents,
  GroupBase,
  MenuProps,
  OptionProps,
} from 'react-select'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { CreateOptionProps } from 'components/Select/CreateOption'
import Field, { CustomFieldProps, FieldChildProps } from './Field'
import styles from './styles.module.scss'

type Option = {
  label: ReactNode
  value?: string | boolean
  icon?: ReactNode
  options?: Option[]
}

interface SelectProps extends CustomFieldProps {
  portal?: boolean
  onChange?: (value: Option, form: FormikProps<any>) => void
  options: Option[]
  defaultValue?: Option | string
  createOption?: ReactElement<CreateOptionProps>
}

function Select({
  options,
  portal,
  placeholder,
  onChange = () => {},
  multi,
  disabled,
  noOptionsMessage,
  defaultValue,
  createOption,
  components = {},
  styles: componentStyles = {},
  'data-testid': dataTestId,
  ...rest
}: SelectProps) {
  const defaultValueFormatted =
    typeof defaultValue === 'string'
      ? { value: defaultValue, label: defaultValue }
      : defaultValue

  const IconOption = useCallback(
    (props: OptionProps<Option>) => (
      <reactSelectComponents.Option {...props}>
        <Flex alignItems="center" gap={8}>
          {props.data.icon}
          {props.data.label}
        </Flex>
      </reactSelectComponents.Option>
    ),
    []
  )
  const Option = useCallback(
    (props: OptionProps<Option>) => {
      if (multi) {
        return (
          <reactSelectComponents.Option {...props}>
            <Flex gap={6} alignItems="center">
              <Checkbox checked={props.isSelected} onChange={() => {}} />
              {props.children}
            </Flex>
          </reactSelectComponents.Option>
        )
      }
      if (props.data.icon) {
        return IconOption(props)
      }
      return <reactSelectComponents.Option {...props} />
    },
    [multi]
  )
  const Menu = useCallback(
    (props: MenuProps<Option, boolean, GroupBase<Option>>) =>
      createOption ? (
        <div className="absolute top-full w-full z-1">
          <reactSelectComponents.Menu className="!relative" {...props}>
            {props.children}
          </reactSelectComponents.Menu>
          {createOption}
        </div>
      ) : (
        <reactSelectComponents.Menu {...props}>
          {props.children}
        </reactSelectComponents.Menu>
      ),
    [createOption]
  )

  const formatGroupLabel = useCallback(
    (data: GroupBase<Option>) => (
      <div className="mb-2">
        <span>{data.label || ''}</span>
      </div>
    ),
    []
  )

  return (
    <Field {...rest}>
      {({ form, meta: { touched, error }, field }: FieldChildProps) => (
        <ReactSelect
          classNamePrefix="formFieldSelect"
          data-testid={dataTestId}
          className={touched && error ? styles.errorField : undefined}
          menuShouldBlockScroll
          menuPortalTarget={
            portal ? document.getElementById('select-portal') : null
          }
          placeholder={placeholder}
          options={options}
          components={{
            Menu,
            Option,
            ...components,
          }}
          value={(() => {
            if (Array.isArray(field.value)) {
              return compact(
                field.value.map((value) =>
                  options.find((option) => option.value === value)
                )
              )
            }
            return (
              options
                ? options
                    .map((option) =>
                      isArray(option.options) ? option.options : option
                    )
                    .flat()
                    .find((option) => option.value === field.value)
                : ''
            ) as Option
          })()}
          isMulti={multi}
          isClearable={false}
          onChange={(option) => {
            let value
            if (isString(option) || isBoolean(option)) {
              value = option
            } else if (Array.isArray(option)) {
              value = option.map(({ value }) => value)
            } else if (has(option, 'value')) {
              value = (option as Option).value
            }
            form.setFieldValue(field.name, value)
            onChange(option as Option, form)
          }}
          onBlur={field.onBlur}
          isDisabled={disabled}
          noOptionsMessage={noOptionsMessage}
          defaultValue={defaultValueFormatted}
          styles={componentStyles}
          closeMenuOnSelect={!multi}
          hideSelectedOptions={false}
          formatGroupLabel={formatGroupLabel}
        />
      )}
    </Field>
  )
}

export type { Option }
export default Select
