import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form, Field } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { IBudgetWorksheet } from 'types'
import { createScheme, required } from 'utils/schemas'

const Schema = createScheme({
  name: required,
})

type FormValues = {
  name: string
}

interface Props {
  worksheet?: IBudgetWorksheet
  saving: boolean
  onSave: (values: FormValues) => void
  onCancel: () => void
}

function ModalWorksheet({ worksheet, saving, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    name: worksheet?.name || '',
  }

  return (
    <Modal
      title={worksheet ? 'Rename Worksheet' : 'New Worksheet'}
      onClose={onCancel}
      className="w-[564px]"
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSave}
        validateOnBlur={false}
      >
        <Form>
          <Grid className="mt-4" columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                label="Worksheet Name"
                type="name"
                name="name"
                placeholder="Worksheet name"
                maxLength={100}
                counter
              />
            </Grid.Item>
            <Grid.Item xs={12} className="flex justify-end gap-2.5">
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalWorksheet }
