import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDockContext } from 'admin/components/Dock/DockContext'
import { DockThreadTab } from 'admin/components/DockThreadTab/DockThreadTab'
import { useMailSignatures } from 'admin/hooks/use-thread-signature'
import { useAddThread } from 'admin/hooks/use-threads'
import { EllipsesMenu } from 'admin/pages/Loan/TabContacts/EllipsesMenu'
import { ParticipantBadge } from 'admin/pages/Loan/TabContacts/ParticipantBadge'
import { pathTo, pathToLoan } from 'admin/path-to'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LinkEmail } from 'components/LinkEmail'
import { Person, Loan } from 'types'
import { formatPhone } from 'utils/phone'

interface Participant extends Person {
  guarantee?: { primary: boolean }
  borrow?: { primary: boolean }
}

interface Props {
  participant: Participant
  loan: Loan
}

function ContactCard({ participant, loan }: Props) {
  const navigate = useNavigate()
  const { data: signatures, isLoading: isLoadingSignatures } =
    useMailSignatures()
  const { mutate: addThread, isPending: isAddingThread } = useAddThread()
  const signature = useMemo(
    () => signatures?.find(({ isDefault }) => isDefault),
    [signatures]
  )
  const { addTab } = useDockContext()
  const [open, setOpen] = useState(false)

  const handleComposeEmail = useCallback(() => {
    addThread(
      {
        loanId: loan.id,
        to: [
          {
            name: participant.name,
            email: participant.email,
            personId: participant.id,
          },
        ],
        body: signature ? `<p></p><p></p><p>--</p>${signature.body}` : '',
      },
      {
        onSuccess: (thread) => {
          addTab({
            id: thread.id as string,
            title: thread.mails[0]?.subject as string,
            content: <DockThreadTab threadId={thread.id as string} />,
            expandByDefault: true,
            onMaximizeClick: () =>
              navigate(
                pathToLoan(loan, 'loanTabEntityId', 'mail', thread.id as string)
              ),
          })
        },
      }
    )
  }, [addThread, loan, signature, addTab, navigate])

  return (
    <Flex
      gap={2}
      key={participant.id}
      className={`
        rounded border border-solid border-grey-200 bg-white-100 
        px-5 pb-5 pt-4 overflow-hidden relative group h-48 
        shadow-100 hover:shadow-200 
        xs:w-full 
        sm:w-[calc(50%-8px)] 
        md:w-[calc(33%-10px)] 
        xl:w-[calc(25%-12px)] xl:only-of-type:min-w-[400px]
      `}
      stack
      alignItems="start"
    >
      <Avatar
        name={participant.name}
        className={clsx(participant.borrow && 'rounded-sm')}
      />
      <Flex
        gap={8}
        className={clsx(
          'absolute top-4 right-4 invisible group-hover:visible',
          open && '!visible'
        )}
      >
        {participant.phone && (
          <Button
            variant="panel"
            icon
            className="text-grey-600 bg-grey-25 border-[rgba(0, 0, 0, 0.16)] p-2"
            onClick={() => {
              window.location.href = `tel:${participant.phone}`
            }}
          >
            <Icon name={IconName.phone} />
          </Button>
        )}
        {participant.email && (
          <Button
            variant="panel"
            icon
            className="text-grey-600 bg-grey-25 border-[rgba(0, 0, 0, 0.16)] p-2"
            loading={isLoadingSignatures || isAddingThread}
            onClick={handleComposeEmail}
          >
            <Icon name={IconName.envelope} />
          </Button>
        )}
        <EllipsesMenu
          open={open}
          setOpen={setOpen}
          participant={participant}
          className="bg-grey-25 border-[rgba(0, 0, 0, 0.16)] p-2"
          variant="panel"
          loan={loan}
        />
      </Flex>
      <NavLink
        to={pathTo('borrower', participant.id)}
        className="link text-lg font-bold mt-3 leading-5 w-full truncate"
      >
        {participant.name}
      </NavLink>
      {participant.email && (
        <LinkEmail
          email={participant.email}
          className="!text-grey-700 leading-5 w-full truncate"
        />
      )}
      {participant.phone && (
        <a
          className="font-bold text-grey-700 leading-5 no-underline w-full truncate"
          href={`tel:${participant.phone}`}
        >
          {formatPhone(participant.phone)}
        </a>
      )}
      <Flex gap={8} className="absolute bottom-4">
        <ParticipantBadge participant={participant} />
      </Flex>
    </Flex>
  )
}

export { ContactCard }
