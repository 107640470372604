import { useMutation, useQueryClient } from '@tanstack/react-query'
import { requestApplication } from 'admin/services/api/loan-application'
import { KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useRequestApplication = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: { borrowerId: string; subject: string; body: string }) =>
      requestApplication({ loanId, ...data }),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, loanId], loan)
      message.success('Request sent')
    },
    onError: handleErrorResponse,
  })
}

export { useRequestApplication }
