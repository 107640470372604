export default {
  font: {
    family: '"Inter", sans-serif',
  },
  color: {
    blue200: '#0856d4',
    blue100: '#2e72e0',
    blue50: '#9cc5f6',
    blue25: '#eef7fe',
    blue10: '#0588f0',
    grey700: '#74716e',
    grey600: '#888684',
    grey50: '#f8f7f7',
    grey75: '#f3f2f1',
    green100: '#07735f',
    black100: '#1a2028',
    white100: '#fff',
  },
}
