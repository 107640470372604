import { useNavigate, useParams } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Tabs } from 'components/Tabs'
import { PanelTemplates } from './PanelTemplates'
import { TaskTemplatePanel } from './Tasks/TaskTemplatePanel'
import { TaskTemplatesPanel } from './Tasks/TaskTemplatesPanel'

function Templates() {
  const navigate = useNavigate()
  const { tab, id } = useParams() as { tab: 'documents' | 'tasks'; id?: string }
  const ability = useAbilitiesContext()
  const justMine = !ability.can('read', 'tasks')

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Templates" />
        <Tabs
          tab={tab}
          defaultActiveId="documents"
          onTabSelect={(tab) => {
            navigate(pathTo('settingsTemplates', tab), { replace: true })
          }}
        >
          <Tabs.Pane
            tab={
              <Flex alignItems="center" gap={4}>
                <Icon name={IconName.documentBlank} />
                Document templates
              </Flex>
            }
            id="documents"
          >
            <PanelTemplates />
          </Tabs.Pane>
          {!justMine ? (
            <Tabs.Pane
              tab={
                <Flex alignItems="center" gap={4}>
                  <Icon name={IconName.approvedCircle} />
                  Task templates
                </Flex>
              }
              id="tasks"
            >
              {!id && <TaskTemplatesPanel />}
              {id && <TaskTemplatePanel id={id} />}
            </Tabs.Pane>
          ) : null}
        </Tabs>
      </Flex>
    </MainContent>
  )
}

export { Templates }
