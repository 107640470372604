import clsx from 'clsx'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAddLoanViewer } from 'admin/hooks/use-loan-people'
import {
  useAddLoanBorrower,
  useAddLoanGuarantor,
  useDeleteLoanBorrower,
  useDeleteLoanGuarantor,
  useUpdateLoanBorrower,
  useUpdateLoanGuarantor,
} from 'admin/hooks/use-loans'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { Loan, Person } from 'types'

interface Participant extends Person {
  guarantee?: { primary: boolean }
  borrow?: { primary: boolean }
}

interface Props {
  participant: Participant
  loan: Loan
  open: boolean
  setOpen: (open: boolean) => void
  className?: string
  variant?: 'panel' | 'ghost'
}
function EllipsesMenu({
  open,
  setOpen,
  participant,
  className,
  variant,
  loan,
}: Props) {
  const navigate = useNavigate()

  const { mutate: addGuarantor } = useAddLoanGuarantor({ id: loan.id })
  const { mutate: updateGuarantor } = useUpdateLoanGuarantor({ id: loan.id })
  const { mutate: deleteGuarantor } = useDeleteLoanGuarantor({ id: loan.id })

  const { mutate: addBorrower } = useAddLoanBorrower({ id: loan.id })
  const { mutate: updateBorrower } = useUpdateLoanBorrower({ id: loan.id })
  const { mutate: deleteBorrower } = useDeleteLoanBorrower({ id: loan.id })

  const { mutate: addViewer } = useAddLoanViewer({ id: loan.id })

  const handleNavigate = useCallback(
    (participant?: Participant) => {
      if (participant?.id) {
        navigate(pathTo('borrower', participant.id))
      }
    },
    [navigate]
  )

  const handleStarClick = useCallback(
    (type: 'borrower' | 'guarantor') => () => {
      if (type === 'borrower' && participant.borrow) {
        updateBorrower({
          borrowerId: participant.id,
          primary: !participant.borrow?.primary,
        })
      } else if (type === 'guarantor' && participant.guarantee) {
        updateGuarantor({
          guarantorId: participant.id,
          primary: !participant.guarantee?.primary,
        })
      }
    },
    [participant, updateGuarantor, updateBorrower]
  )

  const handleChange = useCallback(
    (type: 'borrower' | 'guarantor') => {
      if (type === 'borrower') {
        if (participant.borrow) {
          deleteBorrower(participant.id)
          if (!participant.guarantee) {
            addViewer({ personId: participant.id })
          }
        } else {
          addBorrower({
            id: participant.id,
            borrow: { primary: loan.borrowers.length === 0 },
          })
        }
      }

      if (type === 'guarantor') {
        if (participant.guarantee) {
          deleteGuarantor(participant.id)
          if (!participant.borrow) {
            addViewer({ personId: participant.id })
          }
        } else {
          addGuarantor({
            id: participant.id,
            guarantee: { primary: loan.guarantors.length === 0 },
          })
        }
      }
    },
    [
      participant,
      deleteBorrower,
      addBorrower,
      deleteGuarantor,
      addGuarantor,
      addViewer,
    ]
  )

  const handleDelete = useCallback(() => {
    if (participant.guarantee) {
      deleteGuarantor(participant.id)
    }
    if (participant.borrow) {
      deleteBorrower(participant.id)
    }
  }, [])

  return (
    <EllipsesActions
      open={open}
      onOpenChange={setOpen}
      trigger={
        <Button
          variant={variant}
          active={open}
          icon
          className={clsx(className, 'text-grey-600')}
        >
          <Icon name={IconName.ellipses} />
        </Button>
      }
    >
      <EllipsesActions.Section className="text-grey-600">
        Type
      </EllipsesActions.Section>
      <EllipsesActions.Item
        icon
        onSelect={() => {}}
        className="cursor-auto hover:bg-transparent pr-1"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="w-full"
        >
          <Flex gap={4} alignItems="center">
            <Flex gap={4} alignItems="center">
              <Checkbox
                checked={!!participant.borrow}
                onChange={() => {
                  handleChange('borrower')
                }}
                className="ml-0"
              />
              <Text className="text-grey-900">Borrower</Text>
            </Flex>
          </Flex>
          <Tooltip content={participant.borrow?.primary ? '' : 'Make primary'}>
            <Button
              variant="ghost"
              disabled={!participant.borrow}
              icon
              onClick={handleStarClick('borrower')}
            >
              <Icon
                name={
                  participant.borrow?.primary
                    ? IconName.starFilled
                    : IconName.star
                }
                className={clsx(
                  'flex-shrink-0',
                  participant.borrow?.primary
                    ? 'text-[#FFC53D]'
                    : 'text-grey-300'
                )}
              />
            </Button>
          </Tooltip>
        </Flex>
      </EllipsesActions.Item>
      <EllipsesActions.Item
        icon
        onSelect={() => {}}
        className="cursor-auto hover:bg-transparent pr-1"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="w-full"
        >
          <Flex gap={4} alignItems="center">
            <Flex gap={4} alignItems="center">
              <Checkbox
                checked={!!participant.guarantee}
                onChange={() => handleChange('guarantor')}
                className="ml-0"
              />
              <Text className="text-grey-900">Guarantor</Text>
            </Flex>
          </Flex>
          <Tooltip
            content={participant.guarantee?.primary ? '' : 'Make primary'}
          >
            <Button
              variant="ghost"
              disabled={!participant.guarantee}
              icon
              onClick={handleStarClick('guarantor')}
            >
              <Icon
                name={
                  participant.guarantee?.primary
                    ? IconName.starFilled
                    : IconName.star
                }
                className={clsx(
                  'flex-shrink-0',
                  participant.guarantee?.primary
                    ? 'text-[#FFC53D]'
                    : 'text-grey-300'
                )}
              />
            </Button>
          </Tooltip>
        </Flex>
      </EllipsesActions.Item>
      <div className="border-t border-0 border-solid border-grey-200 my-1.5 -mx-1"></div>
      <EllipsesActions.Item icon onSelect={() => {}}>
        <Icon name={IconName.sendThread} className="text-grey-600" />
        Re-send invite
      </EllipsesActions.Item>
      <EllipsesActions.Item icon onSelect={() => handleNavigate(participant)}>
        <Icon name={IconName.person} className="text-grey-600" />
        View profile
      </EllipsesActions.Item>
      <EllipsesActions.Item icon onSelect={() => handleDelete()}>
        <Icon name={IconName.minusRectangle} className="text-grey-600" />
        Remove from loan
      </EllipsesActions.Item>
    </EllipsesActions>
  )
}

export { EllipsesMenu }
