import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getLoanPeople, addLoanViewer } from 'admin/services/api/loan-people'
import { KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useLoanPeople = ({ loanId }: { loanId: string }) => {
  return useQuery({
    queryKey: [KEY_LOANS, loanId, 'people'],
    queryFn: () => getLoanPeople({ loanId }),
  })
}

const useAddLoanViewer = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ personId }: { personId: string }) => {
      addLoanViewer({ loanId: id, id: personId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, id, 'people'] })
      message.success('Loan updated')
    },
    onError: handleErrorResponse,
  })
}

export { useLoanPeople, useAddLoanViewer }
