import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useAddTaskTemplate,
  useRemoveTaskTemplate,
  useTaskTemplates,
  useEditTaskTemplate,
} from 'admin/hooks/use-task-templates'
import { ModalTemplate } from 'admin/pages/Tasks/ModalTemplate'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { LoadMore } from 'components/LoadMore'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { Filter, TaskTemplate } from 'types'
import { TableTemplates } from './TableTemplates'

function TaskTemplatesPanel() {
  const navigate = useNavigate()
  const {
    visibleItems,
    result,
    sort,
    setSort,
    setPagination,
    resetPagination,
  } = usePagination<TaskTemplate>({
    property: 'taskTemplates',
    useData: (params) =>
      useTaskTemplates({
        ...params,
        filter: {
          ...(params.filter || {}),
        } as Filter,
        details: true,
      }),
  })
  const [adding, setAdding] = useState<boolean>(false)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [editingId, setEditingId] = useState<string | null>(null)
  const { mutate: remove, isPending: isRemoving } = useRemoveTaskTemplate()
  const { mutate: edit, isPending: isEditing } = useEditTaskTemplate()
  const { mutate: add, isPending: isAdding } = useAddTaskTemplate()

  return (
    <>
      <Panel>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={10}
          className="mb-5"
        >
          <Header className="text-2xl">Templates</Header>
          <Button onClick={() => setAdding(true)}>New Template</Button>
        </Flex>
        <TableTemplates
          loading={result.isPending}
          templates={visibleItems}
          sort={sort}
          onSort={setSort}
          onClick={(templateId) => {
            navigate(pathTo('settingsTemplates', 'tasks', templateId))
          }}
          onDelete={(templateId) => {
            setRemovingId(templateId)
          }}
          onEdit={(templateId) => {
            setEditingId(templateId)
          }}
        />
        <LoadMore
          loading={result.isPending}
          count={visibleItems.length}
          meta={result.data?.meta}
          onLoadMore={setPagination}
        />
      </Panel>
      {(adding || editingId) && (
        <ModalTemplate
          template={visibleItems.find(({ id }) => id === editingId)}
          onCancel={() => {
            setAdding(false)
            setEditingId(null)
          }}
          onSubmit={(template) => {
            adding
              ? add(template, {
                  onSuccess: (taskTemplate) => {
                    setAdding(false)
                    navigate(
                      pathTo('settingsTemplates', 'tasks', taskTemplate.id)
                    )
                  },
                })
              : edit(
                  { id: editingId as string, ...template },
                  {
                    onSuccess: () => {
                      setEditingId(null)
                    },
                  }
                )
          }}
          saving={isAdding || isEditing}
        />
      )}
      {removingId && (
        <ModalDelete
          resource="Template"
          name={visibleItems.find(({ id }) => id === removingId)?.name}
          loading={isRemoving}
          onDelete={() => {
            remove(removingId, {
              onSuccess: () => {
                resetPagination()
                setEditingId(null)
                setRemovingId(null)
              },
            })
          }}
          onCancel={() => setRemovingId(null)}
        />
      )}
    </>
  )
}
export { TaskTemplatesPanel }
