import { compact, get } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import {
  contextmenu,
  jspreadsheet,
  Spreadsheet,
  Worksheet,
} from 'components/Spreadsheet'
import {
  useBudgetWorksheet,
  useUpdateBudgetWorksheet,
} from 'hooks/use-budget-worksheet'
import { useSession } from 'hooks/use-session'
import { Field, FieldType } from 'types'
import { IBudgetWorksheetItem } from 'types'
import { formatField } from 'utils/fields'
import { attributeTypeToJssType } from './fields'

const SettingsWorksheetItemsBulkEdit = () => {
  const navigate = useNavigate()
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    jspreadsheetLicense: undefined,
  })
  jspreadsheet.setLicense(
    settings.jspreadsheetLicense ?? import.meta.env.VITE_APP_JSPREADSHEET
  )
  const jssRef = useRef<jspreadsheet.Spreadsheet>()
  const { id } = useParams() as { id: string }
  const { data: worksheet } = useBudgetWorksheet(id)
  const { mutate: updateWorksheet, isPending: saving } =
    useUpdateBudgetWorksheet()
  const [isItemNumbersVisible, setIsItemNumbersVisible] = useState(
    worksheet?.show_item_numbers || false
  )
  const [isSectionVisible, setIsSectionVisible] = useState(
    worksheet?.show_sections || false
  )

  const handleSave = useCallback(() => {
    const rows = (jssRef.current?.[0]?.getJson() ||
      []) as IBudgetWorksheetItem[]
    const filledRows = rows.filter(
      (row) =>
        !!row['Work Item'] && Object.values(row).some((value) => value !== '')
    )
    updateWorksheet(
      {
        id,
        items: filledRows,
        show_item_numbers: isItemNumbersVisible,
        show_sections: isSectionVisible,
      },
      { onSuccess: () => navigate(pathTo('settingsWorksheet', id)) }
    )
  }, [updateWorksheet, isItemNumbersVisible, isSectionVisible, id])

  const attributes = useMemo(() => {
    const workItem = worksheet?.attributes.find(
      (attribute) => attribute.name === 'Work Item'
    )
    return compact([
      workItem,
      {
        name: 'Item Number',
        type: 'text' as FieldType,
      },
      {
        name: 'Section',
        type: 'text' as FieldType,
      },
    ])
  }, [worksheet])

  const columns = useMemo(() => {
    return (
      attributes.map((attribute) => {
        const type = attribute.type || 'text'
        const jssType = attributeTypeToJssType.get(type)

        return {
          title: attribute.name,
          name: attribute.name,
          type: jssType,
          autoCasting: false,
          locale: [
            'number',
            'decimal',
            'percentage',
            'currency',
            'date',
          ].includes(type)
            ? 'en-US'
            : undefined,
          width: 150,
          align: ['number', 'decimal', 'percentage', 'currency'].includes(type)
            ? 'right'
            : 'left',
          render: (td, value) => {
            if (td && jssType !== 'checkbox') {
              td.innerText = formatField({
                type: [attribute.type],
                property: { value: [value] },
              } as Field)
            }
          },
          options: {
            ...(type === 'option' && { newOptions: false }),
            ...(type === 'currency' && {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
          },
        }
      }) || []
    )
  }, [attributes])

  const rows = useMemo(() => {
    return worksheet?.items.map((item) => {
      return Object.fromEntries(
        attributes.map((attribute) => [attribute.name, item[attribute.name]])
      )
    })
  }, [worksheet, attributes])

  useEffect(() => {
    setIsItemNumbersVisible(worksheet?.show_item_numbers || false)
    setIsSectionVisible(worksheet?.show_sections || false)
  }, [worksheet])

  useEffect(() => {
    if (worksheet && jssRef.current) {
      if (isSectionVisible) {
        jssRef.current[0]?.showColumn(2)
      } else {
        jssRef.current[0]?.hideColumn(2)
      }
    }
  }, [isSectionVisible])

  useEffect(() => {
    if (worksheet && jssRef.current) {
      if (isItemNumbersVisible) {
        jssRef.current[0]?.showColumn(1)
      } else {
        jssRef.current[0]?.hideColumn(1)
      }
    }
  }, [isItemNumbersVisible])

  return (
    <MainContent className="p-0">
      {worksheet ? (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className="md:h-12 pl-4"
          >
            <Flex alignItems="center" gap={4}>
              <Button
                variant="ghost"
                icon
                onClick={() => navigate(pathTo('settingsWorksheet', id))}
              >
                <Icon name={IconName.arrowLeftLong} />
              </Button>
              <Header variant="h4">Bulk Edit Work Items</Header>
            </Flex>
            <Flex alignItems="center" gap={24} className="pr-5">
              <Button variant="primary" onClick={handleSave} loading={saving}>
                Save &amp; Exit
              </Button>
            </Flex>
          </Flex>
          <Flex className="px-3 pb-4">
            <Checkbox
              label="Assign Item Numbers"
              className="items-center"
              checked={isItemNumbersVisible}
              onChange={(e) => setIsItemNumbersVisible(e.target.checked)}
            />
            <Checkbox
              label="Assign Sections"
              className="items-center"
              checked={isSectionVisible}
              onChange={(e) => setIsSectionVisible(e.target.checked)}
            />
          </Flex>
          <div className="relative h-full overflow-x-auto">
            <div className="bg-[#f3f3f3] absolute p-3 w-full border border-solid border-[#ccc] leading-4">
              <div className="border border-solid border-transparent">
                &nbsp;
              </div>
            </div>
            {columns.length > 0 && (
              <Spreadsheet
                ref={jssRef}
                allowExport={false}
                calculations={true}
                secureFormulas={false}
                editorFormulas={false}
                parseFormulas={false}
                debugFormulas={false}
                contextMenu={contextmenu}
              >
                <Worksheet
                  data={rows}
                  columns={columns}
                  allowInsertColumn={false}
                  allowManualInsertColumn={false}
                  allowDeleteColumn={false}
                  allowRenameColumn={false}
                  columnDrag={false}
                  allowComments={false}
                  columnSorting={false}
                  minDimensions={[columns.length, 15]}
                />
              </Spreadsheet>
            )}
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { SettingsWorksheetItemsBulkEdit }
