import { without } from 'lodash'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { Text } from 'components/Text'
import { useUpdateBudgetWorksheet } from 'hooks/use-budget-worksheet'
import { IBudgetWorksheet, IBudgetWorksheetItem } from 'types'
import { WorkItemsTable } from './WorkItemsTable'

interface Props {
  worksheet: IBudgetWorksheet
}

const WorkItems = ({ worksheet }: Props) => {
  const navigate = useNavigate()
  const [search, setSearch] = useState<string>()
  const { mutate: updateWorksheet } = useUpdateBudgetWorksheet()
  const handleDelete = useCallback(
    (item: IBudgetWorksheetItem) => {
      updateWorksheet({
        id: worksheet.id,
        items: without(worksheet.items, item),
      })
    },
    [worksheet]
  )

  const handleSectionsChange = useCallback(
    (sections: string[]) => {
      updateWorksheet({
        id: worksheet.id,
        sections,
      })
    },
    [worksheet]
  )

  return (
    <Flex className="pt-6 pb-10" gap={40}>
      <Flex
        flexDirection="column"
        gap={20}
        className="basis-[376px] flex-shrink-0"
      >
        <Header variant="h4">Work Items</Header>
        <Text className="text-grey-700">
          This is the list of work items available for the borrower to select in
          their worksheet. Open the worksheet to edit the Work Items in bulk, or
          add sections and cost numbers.
        </Text>
      </Flex>

      <Panel className="flex-grow overflow-hidden">
        <Flex flexDirection="column" gap={16}>
          <Flex gap={10} alignItems="center">
            <Search
              fieldClassName="flex-grow"
              className="w-full"
              onSearch={setSearch}
              search={search}
            />
            <Button
              variant="secondary"
              onClick={() =>
                navigate(pathTo('settingsWorksheetItemsBulkEdit', worksheet.id))
              }
            >
              Edit in Bulk
              <Icon name={IconName.linkExternal} className="text-grey-600" />
            </Button>
          </Flex>

          <WorkItemsTable
            worksheet={worksheet}
            onSectionsChange={handleSectionsChange}
            onDelete={handleDelete}
          />
        </Flex>
      </Panel>
    </Flex>
  )
}

export { WorkItems }
