import { useCallback, useEffect, useState } from 'react'
import { NotificationEditor } from 'admin/components/InlineWysiwyg/NotificationEditor'
import { SelectLoanPerson } from 'admin/components/SelectLoanPerson'
import { useRequestApplication } from 'admin/hooks/use-loan-application'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { useNotificationTemplate } from 'hooks/use-notification-settings'
import { NotificationSettingTemplate, Person } from 'types'

interface Props {
  onCancel: () => void
}

const editorToTemplate = (body: string): string =>
  body?.replace(/<span data-lexical-variable="(.*?)">(.*?)<\/span>/gi, '{{$1}}')

const templateToEditor = (body: string): string =>
  body?.replace(/{{(.*?)}}/gi, '<span data-lexical-variable="$1">$1</span>')

const ModalRequestApplication = ({ onCancel }: Props) => {
  const { loan } = useLoanContext()
  const [editorKey, setEditorKey] = useState(0)
  const [recipient, setRecipient] = useState<Person | undefined>()
  const [template, setTemplate] = useState<NotificationSettingTemplate>()
  const { data: defaultTemplate, isLoading: loading } = useNotificationTemplate(
    loan.applicationId
      ? 'origination/request-application-changes'
      : 'origination/request-application'
  )
  const { mutate: send, isPending: sending } = useRequestApplication(loan.id)
  const handleRestoreToDefault = useCallback(() => {
    setEditorKey((currEditorKey) => currEditorKey + 1)
    setTemplate(defaultTemplate)
  }, [defaultTemplate])
  const handleSend = useCallback(
    () =>
      send(
        {
          borrowerId: recipient!.id,
          subject: (template as NotificationSettingTemplate).subject,
          body: (template as NotificationSettingTemplate).body,
        },
        {
          onSuccess: onCancel,
        }
      ),
    [loan, recipient, template, send, onCancel]
  )

  useEffect(() => {
    setTemplate(defaultTemplate)
    setEditorKey((currEditorKey) => currEditorKey + 1)
  }, [defaultTemplate])

  return (
    <Modal
      title={
        loan.applicationId
          ? 'Request Changes to Application'
          : 'Request Application'
      }
      crossClose
      loading={!template}
      onClose={onCancel}
      className="w-[564px]"
      focusFirstInput={false}
    >
      <Flex stack gap={24} className="-mt-1">
        <Text className="text-grey-700" variant="l">
          The borrower will receive this email and a link to resubmit their
          application.
        </Text>
        <Flex stack gap={0}>
          <Text className="text-grey-600 font-bold" variant="xs">
            TO
          </Text>
          <SelectLoanPerson
            person={recipient}
            loan={loan}
            onSelect={setRecipient}
            className="w-full"
          />
        </Flex>
        <Flex stack gap={8}>
          <NotificationEditor
            key={editorKey}
            subject={templateToEditor(template?.subject as string)}
            body={templateToEditor(template?.body as string)}
            variables={
              template?.params?.map((param) => ({
                value: param,
                label: param,
              })) || []
            }
            onChangeSubject={(subject) => {
              setTemplate((currentTemplate) => ({
                ...(currentTemplate as NotificationSettingTemplate),
                subject: editorToTemplate(subject),
              }))
            }}
            onChangeBody={(body) => {
              setTemplate((currentTemplate) => ({
                ...(currentTemplate as NotificationSettingTemplate),
                body: editorToTemplate(body),
              }))
            }}
          />
          <Flex justifyContent="flex-end">
            <Button variant="ghost" onClick={handleRestoreToDefault}>
              <Icon name={IconName.reverse} />
              Restore to default
            </Button>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={loading || !recipient}
            loading={sending}
            onClick={handleSend}
          >
            Send Request
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export { ModalRequestApplication }
