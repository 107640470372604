import clsx from 'clsx'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Person } from 'types'

interface Participant extends Person {
  guarantee?: { primary: boolean }
  borrow?: { primary: boolean }
}

interface Props {
  participant: Participant
}
function ParticipantBadge({ participant }: Props) {
  return (
    <>
      {participant.borrow && (
        <Flex
          className={clsx(
            'border border-solid border-grey-100 rounded-sm pl-[3px] pr-1.5 py-0.5 flex-shrink-0 text-grey-800',
            !participant.borrow.primary && 'pl-[6px]'
          )}
          gap={4}
          alignItems="center"
        >
          {participant.borrow.primary && (
            <Icon
              name={IconName.starFilled}
              className="text-[#FFC53D] flex-shrink-0 h-[14px] w-[14px]"
            />
          )}
          Borrower
        </Flex>
      )}
      {participant.guarantee && (
        <Flex
          className={clsx(
            'border border-solid border-grey-100 rounded-sm pl-[3px] pr-1.5 py-0.5 flex-shrink-0 text-grey-800',
            !participant.guarantee.primary && 'pl-[6px]'
          )}
          gap={4}
          alignItems="center"
        >
          {participant.guarantee.primary && (
            <Icon
              name={IconName.starFilled}
              className="text-[#FFC53D] flex-shrink-0 h-[14px] w-[14px]"
            />
          )}
          Guarantor
        </Flex>
      )}
    </>
  )
}

export { ParticipantBadge }
