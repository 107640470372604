import { ChangeEvent, useCallback } from 'react'
import { useEditTask } from 'admin/hooks/use-tasks'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IconInput } from 'components/IconInput'
import { Input } from 'components/Input'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Text } from 'components/Text'
import { Textarea } from 'components/Textarea'
import { TaskTemplateTask } from 'types'
import { Subtasks } from './Subtasks'

interface Props {
  task: Partial<TaskTemplateTask>
  onChange: (task: Partial<TaskTemplateTask>) => void
  onDelete: (id: string) => void
}

function TemplateTaskForm({ task, onChange, onDelete }: Props) {
  const { options: ownersOptions, roleOptions } = useOwnerSelector()
  const { mutate: update } = useEditTask()
  const selectedOwners = task.owners || []
  const missedOptions =
    selectedOwners?.filter(({ id }) => {
      return !ownersOptions.find((option) => option.id === id)
    }) || []
  const handleOwnerChange = useCallback(
    (owners) => {
      const ownersList =
        owners?.map((id) => {
          return {
            id,
            name: ownersOptions.find(({ id: optionId }) => optionId === id)
              ?.name,
          }
        }) || []

      onChange({ owners: ownersList as TaskTemplateTask['owners'] })
    },
    [ownersOptions, onChange]
  )
  const handleOtherOwnerChange = useCallback(
    (otherOwners: string[]) => {
      onChange({ otherOwners })
    },
    [ownersOptions, onChange]
  )
  const handleTextareaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      event.target.style.height = 'auto'
      event.target.style.height = `${event.target.scrollHeight}px`
      onChange({ description: event.target.value })
    },
    [onChange]
  )

  const handleSubtaskChange = useCallback(
    ({ subtasks }: Partial<TaskTemplateTask>) => {
      onChange({ subtasks })
      if (task.id) {
        update({
          ...task,
          subtasks: subtasks?.filter(({ name }) => !!name),
          owners: task.owners?.map(
            ({ id }) => id
          ) as unknown as TaskTemplateTask['owners'],
        })
      }
    },
    [task]
  )

  return (
    <Flex justifyContent="space-between" gap={32}>
      <Flex flexDirection="column" className="mt-2 flex-grow">
        <Input
          name="name"
          value={task.name}
          onChange={(e) => onChange({ name: e.target.value })}
          placeholder="Add name for your task"
          className="text-4xl border-none placeholder:font-bold font-bold"
        />
        <Textarea
          name="description"
          value={task.description}
          onChange={handleTextareaChange}
          placeholder="Add a description..."
          fieldClassName="h-auto"
          className="border-none text-lg h-8"
        />
        <Subtasks
          subtasks={task.subtasks as TaskTemplateTask['subtasks']}
          onChange={handleSubtaskChange}
        />
        <div className="flex-grow" />
      </Flex>
      <Flex
        className="w-[200px] flex-shrink-0"
        flexDirection="column"
        alignItems="start"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="w-full pt-0.5 pb-3"
        >
          {task.id && (
            <EllipsesActions
              trigger={
                <Button variant="ghost" className="w-8 h-8">
                  <Icon name={IconName.ellipses} size="md" />
                </Button>
              }
            >
              <EllipsesActions.Item
                icon
                onSelect={() => {
                  onDelete(task.id as string)
                }}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete Task
              </EllipsesActions.Item>
            </EllipsesActions>
          )}
        </Flex>
        <div className="w-full">
          <Text className="text-grey-700 font-normal">Assignee(s)</Text>
          <OwnerSelector
            variant="small"
            selectOwnersText="Select one or more assignees"
            selectedUsers={task.owners?.map(({ id }) => id) || []}
            selectedRoles={task.otherOwners || []}
            userOptions={[
              ...ownersOptions,
              ...missedOptions.map((option) => ({ ...option, email: '-' })),
            ]}
            roleOptions={roleOptions}
            onChange={handleOwnerChange}
            onRoleChange={handleOtherOwnerChange}
          />
        </div>
        <div>
          <Text className="text-grey-700 font-normal">Due Date</Text>
          <IconInput
            type="number"
            className="!border-none !pl-0"
            mask="# days after creation"
            value={task.daysDue}
            onChange={(e) =>
              onChange({
                daysDue: (e.target.value as unknown as number) ?? undefined,
              })
            }
          />
        </div>
      </Flex>
    </Flex>
  )
}

export { TemplateTaskForm }
