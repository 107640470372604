import { capitalize, compact, isArray, isNil, toNumber } from 'lodash'
import * as yup from 'yup'
import { getFieldById } from 'admin/pages/Settings/Application/helpers'
import {
  Address,
  IApplicationCondition,
  IApplicationScheme,
  IApplicationSchemeField,
  IApplicationSchemePage,
  Person,
} from 'types'
import { formatUsd } from 'utils/currency'
import {
  createScheme,
  email,
  mergeRules,
  required,
  requiredCheckbox,
} from 'utils/schemas'

const getLimitToType = (field: IApplicationSchemeField) => {
  if (['individual', 'entity'].includes(field.limitTo as string)) {
    return field.limitTo
  }
  return ''
}

const getAddressValues = ({
  fields,
  addresses = [],
  addressType,
  mapTo,
}: {
  fields: IApplicationSchemeField[]
  addresses?: Address[]
  addressType: 'primary' | 'mailing'
  mapTo: string
}) => {
  const address = addresses.find(({ type }) => type === addressType)
  return fields
    ?.filter((field) => field.mapTo === mapTo)
    .reduce(
      (acc, field) => ({
        ...acc,
        [field.id]: {
          street1: address?.street1 || '',
          street2: address?.street2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zipcode: address?.zipcode || '',
          country: address?.country || '',
        },
      }),
      {}
    )
}

const schemeToValues = (
  fields: IApplicationSchemeField[],
  borrower?: Person
) => {
  return fields
    .map((field) => {
      switch (field.type) {
        case 'borrower': {
          const individualMaritalStatusField = field.individualFields?.find(
            ({ mapTo }) => mapTo === 'borrower-individual-marital-status'
          )
          const individualGenderField = field.individualFields?.find(
            ({ mapTo }) => mapTo === 'borrower-individual-gender'
          )
          const individualDOBField = field.individualFields?.find(
            ({ mapTo }) => mapTo === 'borrower-individual-date-of-birth'
          )
          const individualPhoneFields = field.individualFields
            ?.filter(({ mapTo }) => mapTo === 'borrower-individual-phone')
            .reduce(
              (acc, field) => ({ ...acc, [field.id]: borrower?.phone }),
              {}
            )
          const individualEmailFields = field.individualFields
            ?.filter(({ mapTo }) => mapTo === 'borrower-individual-email')
            .reduce(
              (acc, field) => ({ ...acc, [field.id]: borrower?.email }),
              {}
            )
          const entityPhoneFields = field.entityFields
            ?.filter(({ mapTo }) => mapTo === 'borrower-entity-phone')
            .reduce(
              (acc, field) => ({ ...acc, [field.id]: borrower?.phone }),
              {}
            )
          const individualBorrower = borrower
            ? {
                type: 'individual',
                person_id: borrower.id,
                name: borrower.name,
                credit_score: borrower.creditScore,
                citizenship: borrower.citizenship,
                num_flipped: borrower.numFlipped,
                ...(individualMaritalStatusField
                  ? {
                      [individualMaritalStatusField.id]: borrower.maritalStatus
                        ? capitalize(borrower.maritalStatus)
                        : undefined,
                    }
                  : {}),
                ...(individualGenderField
                  ? {
                      [individualGenderField.id]: borrower.gender
                        ? capitalize(borrower.gender)
                        : undefined,
                    }
                  : {}),
                ...(individualDOBField
                  ? { [individualDOBField.id]: borrower.dateBirth }
                  : {}),
                ...individualPhoneFields,
                ...individualEmailFields,
                ...getAddressValues({
                  fields: field.individualFields!,
                  addresses: borrower.addresses,
                  addressType: 'primary',
                  mapTo: 'borrower-individual-primary-address',
                }),
                ...getAddressValues({
                  fields: field.individualFields!,
                  addresses: borrower.addresses,
                  addressType: 'mailing',
                  mapTo: 'borrower-individual-mailing-address',
                }),
              }
            : { type: 'individual' }
          const entityBorrower = borrower
            ? {
                type: 'entity',
                person_id: borrower.id,
                name: borrower.name,
                entity_type: borrower.entityType,
                jurisdiction: borrower.jurisdiction,
                num_flipped: borrower.numFlipped,
                ...entityPhoneFields,
                ...getAddressValues({
                  fields: field.entityFields!,
                  addresses: borrower.addresses,
                  addressType: 'primary',
                  mapTo: 'borrower-entity-primary-address',
                }),
                ...getAddressValues({
                  fields: field.entityFields!,
                  addresses: borrower.addresses,
                  addressType: 'primary',
                  mapTo: 'borrower-entity-mailing-address',
                }),
              }
            : { type: 'entity' }
          const shouldFillInBorrower =
            !!borrower &&
            (borrower.type === getLimitToType(field) ||
              getLimitToType(field) === '')
          return {
            [field.id]: (field.value as [])?.length
              ? field.value
              : shouldFillInBorrower
                ? [
                    borrower.type === 'entity'
                      ? entityBorrower
                      : individualBorrower,
                  ]
                : [{ type: getLimitToType(field) }],
          }
        }
        case 'guarantor': {
          return {
            [field.id]: (field.value as [])?.length
              ? field.value
              : [{ type: getLimitToType(field) }],
          }
        }
        case 'address':
          return {
            [field.id]: {
              street1: (field.value as any)?.street1 || '',
              street2: (field.value as any)?.street2 || '',
              city: (field.value as any)?.city || '',
              state: (field.value as any)?.state || '',
              zipcode: (field.value as any)?.zipcode || '',
              country: (field.value as any)?.country || '',
            },
          }
        case 'signature':
          return {
            [field.id]: field.value || field.defaultValue || '',
            [`${field.id}_checkbox`]:
              !!field.signatureConfirmationCheckbox as unknown as string,
          }
        default:
          return { [field.id]: field.value || field.defaultValue || '' }
      }
    })
    .reduce((acc, field) => ({ ...acc, ...field }), {})
}

const ruleGraterThan = (field: IApplicationSchemeField) =>
  yup
    .string()
    .test(
      'greaterThan',
      `The value should be greater or equal ${field.type === 'currency' ? formatUsd(field.min) : field.min}`,
      (val) => isNil(val) || val === '' || Number(val) >= Number(field.min)
    )

const ruleLessThan = (field: IApplicationSchemeField) =>
  yup
    .string()
    .test(
      'lessThan',
      `The value should be less or equal ${field.type === 'currency' ? formatUsd(field.max) : field.max}`,
      (val) => isNil(val) || val === '' || Number(val) <= Number(field.max)
    )

const schemeToValidationSchema = (
  scheme: IApplicationScheme,
  page: IApplicationSchemePage
) => {
  const parseFields = (
    fields: IApplicationSchemeField[],
    {
      ruleConditions,
    }: { ruleConditions: (schema: yup.AnySchema) => yup.AnySchema } = {
      ruleConditions: (schema: yup.AnySchema) => schema,
    }
  ) => {
    return fields
      .filter((field) => isFieldVisible(scheme, field))
      .map((field) => {
        // only borrower/guarantor has an id "name"
        if (field.id === 'name') {
          return {
            person_id: yup.string().when(['name', 'type'], {
              is: (name: string, type: string) =>
                !name && !!type && field.required,
              then: () => required,
            }),
            type: yup.string().when('person_id', {
              is: (id: string) => !id,
              then: () => required,
            }),
            name: yup.string().when('person_id', {
              is: (id: string) => !id && field.required,
              then: () => required,
            }),
          }
        }

        if (field.type === 'address' && field.required) {
          return {
            [field.id]: createScheme({
              street1: ruleConditions(required),
              city: ruleConditions(required),
              state: ruleConditions(required),
              zipcode: ruleConditions(required),
              country: ruleConditions(required),
            }),
          }
        }
        if (['borrower', 'guarantor'].includes(field.type)) {
          return {
            [field.id]: yup
              .array()
              .of(
                yup.object().shape(
                  {
                    ...parseFields(
                      field.individualFields as IApplicationSchemeField[],
                      {
                        ruleConditions: (schema: yup.AnySchema) =>
                          yup
                            .string()
                            .nullable()
                            .when('type', {
                              is: 'individual',
                              then: () => schema,
                            }),
                      }
                    ),
                    ...parseFields(
                      field.entityFields as IApplicationSchemeField[],
                      {
                        ruleConditions: (schema: yup.AnySchema) =>
                          yup
                            .string()
                            .nullable()
                            .when('type', {
                              is: 'entity',
                              then: () => schema,
                            }),
                      }
                    ),
                  },
                  [
                    ['name', 'person_id'],
                    ['type', 'person_id'],
                  ]
                )
              )
              .min(1, `Please, add at least one ${field.type}`),
          }
        }

        if (field.type === 'signature' && field.required) {
          return {
            [field.id]: ruleConditions(required),
            [`${field.id}_checkbox`]: requiredCheckbox,
          }
        }

        return {
          [field.id]: mergeRules(
            ...compact([
              field.type === 'email' ? ruleConditions(email) : undefined,
              field.min ? ruleGraterThan(field) : undefined,
              field.max ? ruleLessThan(field) : undefined,
              isFieldRequired(scheme, field)
                ? ruleConditions(required)
                : undefined,
            ])
          ),
        }
      })
      .reduce((acc, field) => ({ ...acc, ...field }), {})
  }

  return createScheme(parseFields(page.fields))
}

const isFirstPage = (scheme: IApplicationScheme, id: string) =>
  id === scheme.pages[0].id

const isLastPage = (scheme: IApplicationScheme, id: string) =>
  id === scheme.pages[scheme.pages.length - 1].id

const isConditionMet = (
  scheme: IApplicationScheme,
  condition: IApplicationCondition,
  formValues: Record<string, any> = {}
) => {
  return condition.conditions.every(({ fieldId, operator, value }) => {
    const field = getFieldById(scheme, fieldId)
    const formValue = formValues[fieldId] || field?.value
    if (
      ['option', 'yes-no'].includes(field?.type as string) &&
      isArray(value)
    ) {
      return value.includes(formValue)
    }
    if (field && operator === 'eq') {
      return formValue === value
    }
    if (field && operator === 'gt' && value) {
      return toNumber(formValue) > toNumber(value)
    }
    if (field && operator === 'gte' && value) {
      return toNumber(formValue) >= toNumber(value)
    }
    if (field && operator === 'lt' && value) {
      return toNumber(formValue) < toNumber(value)
    }
    if (field && operator === 'lte' && value) {
      return toNumber(formValue) <= toNumber(value)
    }
  })
}

const isFieldVisible = (
  scheme: IApplicationScheme,
  field: IApplicationSchemeField,
  formValues: Record<string, any> = {}
) => {
  const relatedConditions = scheme.conditions?.filter(({ actions }) => {
    return actions.some(
      ({ action, fieldIds }) =>
        action === 'hide-fields' && fieldIds?.includes(field.fullId || field.id)
    )
  })
  return (
    field.enabled &&
    !relatedConditions?.some((relatedCondition) =>
      isConditionMet(scheme, relatedCondition, formValues)
    )
  )
}

const isFieldRequired = (
  scheme: IApplicationScheme,
  field: IApplicationSchemeField,
  formValues: Record<string, any> = {}
) => {
  const requireFieldConditions = scheme.conditions?.filter(({ actions }) => {
    return actions.some(
      ({ action, fieldIds }) =>
        action === 'require-fields' &&
        fieldIds?.includes(field.fullId || field.id)
    )
  })
  const notRequireFieldConditions = scheme.conditions?.filter(({ actions }) => {
    return actions.some(
      ({ action, fieldIds }) =>
        action === 'not-require-fields' &&
        fieldIds?.includes(field.fullId || field.id)
    )
  })
  return (
    (field.required ||
      !!requireFieldConditions?.some((relatedCondition) =>
        isConditionMet(scheme, relatedCondition, formValues)
      )) &&
    !notRequireFieldConditions?.some((relatedCondition) =>
      isConditionMet(scheme, relatedCondition, formValues)
    )
  )
}

const isPageVisible = (
  scheme: IApplicationScheme,
  page: IApplicationSchemePage,
  formValues: Record<string, any> = {}
) => {
  const relatedConditions = scheme.conditions?.filter(({ actions }) => {
    return actions.some(
      ({ action, pageIds }) =>
        action === 'hide-pages' && pageIds?.includes(page.id)
    )
  })
  return !relatedConditions?.some((relatedCondition) =>
    isConditionMet(scheme, relatedCondition, formValues)
  )
}

export {
  schemeToValues,
  schemeToValidationSchema,
  isFirstPage,
  isLastPage,
  getLimitToType,
  isFieldVisible,
  isFieldRequired,
  isPageVisible,
}
