import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { ModalWorksheet } from 'admin/pages/Settings/Worksheets/ModalWorksheet'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import {
  useAddBudgetWorksheet,
  useUpdateBudgetWorksheet,
  useBudgetWorksheets,
  useDuplicateBudgetWorksheet,
  useDeleteBudgetWorksheet,
} from 'hooks/use-budget-worksheet'
import { IBudgetWorksheet } from 'types'
import { Banner } from './Banner'
import { TableWorksheets } from './TableWorksheets'

const SettingsWorksheets = () => {
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [deletingWorksheet, setDeletingWorksheet] = useState<IBudgetWorksheet>()
  const [editingWorksheet, setEditingWorksheet] = useState<IBudgetWorksheet>()
  const { data, isLoading } = useBudgetWorksheets()
  const { mutate: addWorksheet, isPending: adding } = useAddBudgetWorksheet()
  const { mutate: editWorksheet, isPending: updating } =
    useUpdateBudgetWorksheet()
  const { mutate: duplicateWorksheet } = useDuplicateBudgetWorksheet()
  const { mutate: deleteWorksheet, isPending: deleting } =
    useDeleteBudgetWorksheet()

  const handleSave = useCallback(
    (payload) => {
      if (editingWorksheet) {
        editWorksheet(
          { id: editingWorksheet.id, ...payload },
          {
            onSuccess: () => {
              setEditingWorksheet(undefined)
              setIsModalVisible(false)
            },
          }
        )
      } else {
        addWorksheet(payload, { onSuccess: () => setIsModalVisible(false) })
      }
    },
    [editingWorksheet, addWorksheet, editWorksheet]
  )

  return (
    <MainContent>
      <PageTop
        breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
        title="Scope of Work"
      />
      <Banner />
      <Panel
        className="mt-6"
        title="Worksheets"
        actionChildren={
          <Button variant="primary" onClick={() => setIsModalVisible(true)}>
            Add Worksheet
          </Button>
        }
      >
        <TableWorksheets
          data={data?.worksheets}
          loading={isLoading}
          onClick={(worksheet) =>
            navigate(pathTo('settingsWorksheet', worksheet?.id as string))
          }
          onDuplicate={(worksheet) => duplicateWorksheet(worksheet.id)}
          onRename={(worksheet) => {
            setIsModalVisible(true)
            setEditingWorksheet(worksheet)
          }}
          onDelete={setDeletingWorksheet}
        />
        <div className="mt-4">
          <TextLink onClick={() => setIsModalVisible(true)}>
            <Icon name={IconName.plus} size="sm" />
            Add Worksheet
          </TextLink>
        </div>
      </Panel>
      {isModalVisible && (
        <ModalWorksheet
          worksheet={editingWorksheet}
          saving={adding || updating}
          onSave={handleSave}
          onCancel={() => {
            setEditingWorksheet(undefined)
            setIsModalVisible(false)
          }}
        />
      )}
      {deletingWorksheet && (
        <ModalDelete
          resource="worksheet"
          loading={deleting}
          onDelete={() =>
            deleteWorksheet(deletingWorksheet.id, {
              onSuccess: () => {
                setDeletingWorksheet(undefined)
              },
            })
          }
          onCancel={() => setDeletingWorksheet(undefined)}
        />
      )}
    </MainContent>
  )
}

export { SettingsWorksheets }
