import { ColumnDef } from '@tanstack/react-table'
import { useCallback, useMemo } from 'react'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Table } from 'components/Table'
import { Sort, TaskTemplateTask } from 'types'
import { pluralize } from 'utils/pluralize'

interface Props {
  loading?: boolean
  templateTasks: TaskTemplateTask[]
  onDelete: (id: string) => void
  onEdit: (id: string) => void
  onChange: (task: TaskTemplateTask) => void
  onClick: (id: string) => void
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
}

function TableTemplateTasks({
  loading,
  templateTasks,
  onClick,
  onChange,
  onEdit,
  onDelete,
}: Props) {
  const { options, roleOptions } = useOwnerSelector()
  const handleOtherOwnerChange = useCallback(
    ({ task, otherOwners }) => {
      onChange({
        ...task,
        otherOwners,
      })
    },
    [onChange]
  )

  const handleOwnerChange = useCallback(
    ({ task, owners, ownerSelectorOptions }) => {
      const ownersList =
        owners?.map((id) => {
          return {
            id,
            name: ownerSelectorOptions.find(
              ({ id: optionId }) => optionId === id
            )?.name,
          }
        }) || []

      onChange({ ...task, owners: ownersList as TaskTemplateTask['owners'] })
    },
    [onChange]
  )

  const columns: ColumnDef<TaskTemplateTask>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Task',
      },
      {
        accessorKey: 'daysDue',
        header: 'Due Date',
        cell: ({ row }) => {
          const { daysDue } = row.original
          return daysDue ? `${pluralize(daysDue, 'day')} after creation` : '-'
        },
      },
      {
        accessorKey: 'tasks',
        header: 'Assignee',
        size: 200,
        cell: ({ row }) => {
          const selectedOwners = row.original.owners || []
          const missedOptions =
            selectedOwners?.filter(({ id }) => {
              return !options.find((option) => option.id === id)
            }) || []
          const ownerSelectorOptions = [
            ...options,
            ...missedOptions.map((option) => ({ ...option, email: '-' })),
          ]

          return (
            <OwnerSelector
              variant="small"
              selectOwnersText="Select one or more assignees"
              selectedUsers={row.original.owners?.map(({ id }) => id) || []}
              selectedRoles={row.original.otherOwners || []}
              userOptions={ownerSelectorOptions}
              roleOptions={roleOptions}
              onRoleChange={(otherOwners) => {
                handleOtherOwnerChange({
                  task: row.original,
                  otherOwners,
                })
              }}
              onChange={(owners) =>
                handleOwnerChange({
                  task: row.original,
                  owners,
                  ownerSelectorOptions,
                })
              }
            />
          )
        },
      },
      {
        accessorKey: 'id',
        header: '',
        size: 40,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => {
                  onEdit(row.original.id)
                }}
              >
                <Icon name={IconName.edit} />
                Edit Task
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => {
                  onDelete(row.original.id)
                }}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete Task
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    [options, onDelete, onEdit]
  )
  return (
    <Table
      columns={columns}
      data={templateTasks}
      loading={loading}
      onClick={(task) => task && onClick(task.id)}
    />
  )
}

export { TableTemplateTasks }
