import { Link } from 'react-router-dom'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Text } from 'components/Text'
import googleMailIcon from 'images/googlemail.png'
import microsoftOutlookIcon from 'images/microsoftoutlook.png'

interface CheckInboxProps {
  onReenterEmail: () => void
  email: string
}

const CheckInbox = ({ onReenterEmail, email }: CheckInboxProps) => {
  return (
    <Flex
      stack
      justifyContent="center"
      alignItems="center"
      gap={32}
      className="w-full max-w-2xl mx-auto py-8"
    >
      <div className="text-left">
        <Header variant="h2">Check your inbox</Header>
        <Text className="mt-2 text-grey-600 text-2xl">
          We just sent a magic link to <strong>{email}</strong>. Please click
          the link to submit your application.
        </Text>

        <Text className="mt-8 text-grey-700 ml-1 text-lg">
          This link will expire in 30m.
        </Text>

        <Flex gap={16} alignItems="center" className="my-8">
          <Button
            variant="secondary"
            onClick={() => window.open('https://gmail.google.com', '_blank')}
            className="flex items-center gap-2 px-4 py-2 border border-grey-300 rounded-md hover:bg-grey-50"
          >
            <img src={googleMailIcon} alt="" className="w-5 h-5" />
            Open Gmail
          </Button>
          <Button
            variant="secondary"
            onClick={() => window.open('https://outlook.live.com', '_blank')}
            className="flex items-center gap-2 px-4 py-2 border border-grey-300 rounded-md hover:bg-grey-50"
          >
            <img src={microsoftOutlookIcon} alt="" className="w-5 h-5" />
            Open Outlook
          </Button>
        </Flex>

        <Text className="mt-8 text-grey-600 text-lg">
          Can&apos;t see the email? Check your spam folder.
        </Text>
        <Text className="mt-2 text-grey-600 text-lg">
          Wrong email?{' '}
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault()
              onReenterEmail()
            }}
            className="font-bold no-underline"
          >
            Re-enter your address
          </Link>
        </Text>
      </div>
    </Flex>
  )
}

export { CheckInbox }
