import { HeaderGroup } from '@tanstack/react-table'
import { memo } from 'react'
import { Sort } from 'types'
import { HeaderLoader } from './HeaderLoader'
import { HeaderRow } from './HeaderRow'

interface Props<TData> {
  isLoaderVisible?: boolean
  headerGroups: HeaderGroup<TData>[]
  sort?: Sort
  onSort?: (sort: Sort | string | undefined) => void
}

const TheadComponent = <TData,>({
  isLoaderVisible,
  headerGroups,
  sort,
  onSort,
}: Props<TData>) => (
  <thead>
    {isLoaderVisible ? (
      <HeaderLoader />
    ) : (
      headerGroups.map((headerGroup) => (
        <HeaderRow<TData>
          key={headerGroup.id}
          headerGroup={headerGroup}
          sort={sort}
          onSort={onSort}
        />
      ))
    )}
  </thead>
)

export const Thead = memo(TheadComponent) as typeof TheadComponent
