import { flexRender } from '@tanstack/react-table'
import { Fragment, useRef, useMemo, useCallback } from 'react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { Callout } from 'components/Callout'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import TableCell from 'components/Table/Cell'
import tableStyles from 'components/Table/styles.module.scss'
import { TextLink } from 'components/TextLink'
import styles from './styles.module.scss'

interface Props {
  row: any
  page: string
  onMoveRow: (section: string, dragId: string, hoverId: string) => void
  onReorder: () => void
  onAddField: (section: string) => void
}

const Row = ({ page, row, onMoveRow, onReorder, onAddField }: Props) => {
  const dropRef = useRef<HTMLTableRowElement>(null)
  const dragRef = useRef<HTMLTableCellElement>(null)

  const rowSectionName = useMemo(
    () =>
      row.original.section ||
      row.original.sectionServicing ||
      'section-name-or-action',
    [row.original]
  )

  const handleHover = useCallback(
    (item: { id: string; order: number }, monitor: DropTargetMonitor) => {
      if (!dropRef.current) {
        return
      }
      // Don't replace items with themselves
      if (row.original.id === item.id) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      if (clientOffset) {
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (item.order < row.original.order && hoverClientY <= hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (item.order > row.original.order && hoverClientY > hoverMiddleY) {
          return
        }
        // Perform the action
        onMoveRow(
          row.original.section || row.original.sectionServicing,
          item.id,
          row.original.id
        )
        // Note: we're mutating the monitor item here as it's good here for the performance
        // to avoid expensive index searches.
        item.order = row.original.order
      }
    },
    [row, onMoveRow]
  )

  const [, drop] = useDrop({
    accept: rowSectionName,
    hover: handleHover,
    drop: onReorder,
  })
  const [{ isDragging }, drag, preview] = useDrag({
    type: rowSectionName,
    item: {
      type: rowSectionName,
      order: row.original.order,
      id: row.original.id,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drop(preview(dropRef))
  drag(dragRef)

  const { sectionName, action, section, sectionServicing } = row.original as any
  const isFieldRow = !sectionName && !action

  return (
    <tr ref={isFieldRow ? dropRef : undefined} style={{ opacity }}>
      {sectionName && (
        <td
          colSpan={row.getVisibleCells().length + 1}
          className={styles.section}
        >
          <Header variant="h4" className={styles.sectionName}>
            {sectionName}
          </Header>
          {sectionName === 'Unit Details' && (
            <div className="pt-4 pb-2 bg-grey-50 -m-[1px]">
              <Callout>
                Fields added here will be available for each unit of the
                property
              </Callout>
            </div>
          )}
        </td>
      )}
      {action === 'add-field' && (
        <td
          colSpan={row.getVisibleCells().length + 1}
          className={styles.action}
        >
          <TextLink onClick={() => onAddField(section || sectionServicing)}>
            <Icon name={IconName.plus} size="sm" />
            Add {page === 'documents' ? 'document' : 'field'}
          </TextLink>
        </td>
      )}
      {isFieldRow && <td></td>}
      {/* {isFieldRow && (
        <td
          ref={dragRef}
          className={clsx('px-2', 'text-grey-600', tableStyles.td)}
        >
          <Icon name={IconName.dndHandler} className="cursor-move" />
        </td>
      )} */}
      {isFieldRow &&
        row.getVisibleCells().map((cell) => (
          <Fragment key={cell.id}>
            <td className={tableStyles.td}>
              <TableCell {...cell.getContext()}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            </td>
          </Fragment>
        ))}
    </tr>
  )
}

Row.displayName = 'Row'

export { Row }
