import { isEqual, isUndefined, omitBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useBorrowers } from 'admin/hooks/use-borrowers'
import {
  useDeleteLoanPayment,
  useUpdateLoanPayment,
} from 'admin/hooks/use-loan-payment'
import { pathTo } from 'admin/path-to'
import { downloadPayments } from 'admin/services/csv/download-payments'
import { Button } from 'components/Button'
import { Callout } from 'components/Callout'
import { Download } from 'components/Download'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalPaymentDetails } from 'components/Modal/PaymentDetails'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { TablePayments } from 'components/TablePayments'
import { usePagination } from 'hooks/use-pagination'
import { usePayments } from 'hooks/use-payment'
import { Filter as FilterType, Payment } from 'types'
import { formatUsd } from 'utils/currency'

function ProcessedPaymentsPanel() {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const tab = (searchParams.get('tab') || 'all') as 'all' | 'manual' | 'pad'
  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )
  const { data: borrowers } = useBorrowers()

  const handleFilterChange = useCallback(
    (value: IFilterValue) => {
      setSearchParams(
        { filter: filterValueToUrl(value), tab: tab },
        { replace: true }
      )
    },
    [setSearchParams]
  )
  const [viewPayment, setViewPayment] = useState<Payment>()
  const [removingPayment, setRemovingPayment] = useState<Payment>()
  const [reversePayment, setReversePayment] = useState<Partial<Payment>>()
  const { mutate: update, isPending: updating } = useUpdateLoanPayment()
  const { mutate: remove, isPending: removing } = useDeleteLoanPayment()
  const {
    visibleItems: payments,
    search,
    setSearch,
    result,
    setPagination,
    filter,
    setFilter,
  } = usePagination<Payment>({
    property: 'payments',
    useData: (params) =>
      usePayments({
        ...params,
        filter: {
          ...params.filter,
          ...filterValueToTableFilter([
            ...(filtersValue as IFilterValue),
            { id: 'type', condition: 'eq', value: 'Regular Payment' },
          ]),
        },
        full: true,
      }),
  })

  const filterConfig: IFilterConfig[] = useMemo(
    () => [
      {
        id: 'date',
        type: 'date',
        label: 'Date',
      },
      {
        id: 'borrowers',
        type: 'selectOneOf',
        label: 'Borrower',
        options:
          borrowers?.borrowers?.map(({ id, name }) => ({
            value: id,
            label: name,
          })) || [],
        customCondition: 'oneOf',
      },
      {
        id: 'amount',
        type: 'currency',
        label: 'Amount',
      },
      {
        id: 'status',
        type: 'select',
        label: 'Status',
        options: [
          { value: 'cleared', label: 'Cleared' },
          { value: 'reversed', label: 'Reversed' },
        ],
      },
    ],
    [borrowers?.borrowers]
  )

  const handleTabChange = useCallback(
    (newFilter = {}) => {
      const nextFilter = omitBy({ ...filter, ...newFilter }, (value) =>
        isUndefined(value)
      )

      if (!isEqual(filter || {}, nextFilter)) {
        setFilter(nextFilter as FilterType)
      }
    },
    [tab]
  )

  useEffect(() => {
    switch (tab) {
      case 'pad':
        handleTabChange({ banking: [true] })
        break
      case 'manual':
        handleTabChange({ banking: [false] })
        break
      default:
        handleTabChange({ banking: undefined })
    }
  }, [tab, handleTabChange])

  return (
    <Panel>
      <Flex
        gap={4}
        justifyContent="space-between"
        flexWrap="wrap"
        className="pb-4"
      >
        <Flex gap={8}>
          {[
            { id: 'all', title: 'All' },
            { id: 'manual', title: 'Manual' },
            { id: 'pad', title: 'Pre-Authorized Debit' },
          ].map(({ id, title }) => (
            <Button
              key={id}
              active={tab === id}
              variant="panel"
              onClick={() =>
                navigate(
                  {
                    pathname: pathTo('payments', 'processed'),
                    search: createSearchParams({
                      tab: id,
                      filter: filterValueToUrl(filtersValue),
                    }).toString(),
                  },
                  { replace: true }
                )
              }
            >
              {title}
            </Button>
          ))}
        </Flex>
        <Flex gap={8}>
          <Filter
            config={filterConfig}
            value={filtersValue}
            onApply={handleFilterChange}
          />
          <Search search={search} onSearch={setSearch} />
          <Download
            filename="payments"
            download={() =>
              downloadPayments({
                search,
                filter: {
                  ...filter,
                  ...filterValueToTableFilter([
                    ...(filtersValue as IFilterValue),
                    { id: 'type', condition: 'eq', value: 'Regular Payment' },
                  ]),
                },
              })
            }
          />
        </Flex>
      </Flex>
      <TablePayments
        loading={result.isPending}
        data={payments}
        showLoans
        showDistributions
        showBorrowers
        hideTypeColumn
        onClickRow={(payment) => setViewPayment(payment)}
        onEdit={(payment, data) => {
          if (data.status === 'reversed') {
            setReversePayment({ ...payment, ...data })
          } else {
            update({ ...payment, ...data })
          }
        }}
        onDelete={(payment) => setRemovingPayment(payment)}
      />
      <LoadMore
        loading={result.isPending}
        count={payments.length}
        meta={result.data?.meta}
        onLoadMore={setPagination}
      />
      {viewPayment && (
        <ModalPaymentDetails
          loanId={viewPayment.loanId}
          paymentId={viewPayment.id}
          onCancel={() => setViewPayment(undefined)}
        />
      )}
      {reversePayment && (
        <ModalConfirm
          title="Reverse payment"
          text={
            <Flex stack gap={16}>
              Are you sure you want to reverse this payment?
              {reversePayment.meta?.usio ? (
                <Callout color="yellow">
                  Note: The borrower will be credited the payment amount of{' '}
                  <b>{formatUsd(reversePayment.amount)}</b>
                </Callout>
              ) : null}
            </Flex>
          }
          loading={updating}
          onConfirm={() => {
            update(reversePayment, {
              onSuccess: () => {
                setReversePayment(undefined)
              },
            })
          }}
          onCancel={() => setReversePayment(undefined)}
        />
      )}
      {removingPayment && (
        <ModalDelete
          resource="payment"
          loading={removing}
          onDelete={() =>
            remove(removingPayment, {
              onSuccess: () => setRemovingPayment(undefined),
            })
          }
          onCancel={() => setRemovingPayment(undefined)}
        />
      )}
    </Panel>
  )
}
export { ProcessedPaymentsPanel }
