import { isBefore, parseISO } from 'date-fns'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { Callout } from 'components/Callout'
import { IconName } from 'components/Icon'
import { friendlyDate } from 'utils/date'

interface Props {
  onRequestChanges: () => void
}

const TabApplicationAlert = ({ onRequestChanges }: Props) => {
  const { loan } = useLoanContext()
  const requestedDate = loan.dateApplicationRequested
    ? parseISO(loan.dateApplicationRequested)
    : null
  const submittedDate = loan.dateApplicationSubmitted
    ? parseISO(loan.dateApplicationSubmitted)
    : null

  if (loan.applicationId && !requestedDate && !!submittedDate) {
    return (
      <Callout color="green" iconName={IconName.circleCheckOutline}>
        Application submitted by <strong>John Smith</strong> on{' '}
        {friendlyDate(submittedDate)}
        <strong className="ml-3 cursor-pointer" onClick={onRequestChanges}>
          Request Changes
        </strong>
      </Callout>
    )
  }

  if (
    loan.applicationId &&
    !!requestedDate &&
    (!submittedDate || isBefore(submittedDate, requestedDate))
  ) {
    return (
      <Callout color="orange" iconName={IconName.requestDocument}>
        Changes requested on {friendlyDate(requestedDate)}
        <strong className="ml-3 cursor-pointer" onClick={onRequestChanges}>
          Resend Request
        </strong>
      </Callout>
    )
  }
  if (
    loan.applicationId &&
    !!requestedDate &&
    !!submittedDate &&
    isBefore(requestedDate, submittedDate)
  ) {
    return (
      <Callout color="green" iconName={IconName.circleCheckOutline}>
        Application re-submitted by <strong>John Smith</strong>{' '}
        (john.smith@gmail.com) on {friendlyDate(requestedDate)}
        <strong className="ml-3 cursor-pointer" onClick={onRequestChanges}>
          Request Changes
        </strong>
      </Callout>
    )
  }
  if (!loan.applicationId && !!requestedDate && !submittedDate) {
    return (
      <Callout color="blue" iconName={IconName.requestDocument}>
        Application requested on {friendlyDate(requestedDate)}
        <strong className="ml-3 cursor-pointer" onClick={onRequestChanges}>
          Resend Request
        </strong>
      </Callout>
    )
  }
  return null
}

export { TabApplicationAlert }
