import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import {
  contextmenu,
  jspreadsheet,
  Spreadsheet,
  Worksheet,
} from 'components/Spreadsheet'
import { sumDecimal } from 'utils/math'
import styles from './styles.module.scss'

interface Props {
  columns: any[]
  data: Record<string, string>[]
  section: string
  sectionItems: string[]
  hideHeaders?: boolean
  lastSection?: boolean
  onTotalChange?: (total: number, section: string) => void
  saving: boolean
  onSave: (data: Record<string, string>[], section: string) => void
}

const SectionWorksheet = ({
  data = [],
  columns: cols,
  section,
  sectionItems,
  hideHeaders,
  lastSection,
  onTotalChange,
  saving,
  onSave,
}: Props) => {
  const jssRef = useRef<jspreadsheet.Spreadsheet>()

  const handleAddRow = useCallback(() => {
    jssRef.current?.[0]?.insertRow()
  }, [])

  const columns = useMemo(() => {
    return [
      ...cols.map((col) => ({
        ...col,
        type: col.name === 'Work Item' ? 'dropdown' : col.type,
        source: col.name === 'Work Item' ? sectionItems : col.source,
      })),
      { name: 'id', type: 'hidden' },
    ]
  }, [cols])

  const isNonSectionEmpty = useMemo(
    () => !hideHeaders && data.length === 0 && sectionItems.length === 0,
    [hideHeaders, data.length, sectionItems.length]
  )

  const handleChange = useCallback(() => {
    const json = jssRef.current?.[0]?.getJson()

    onTotalChange?.(sumDecimal(json.map(({ Amount }) => Amount)), section)
  }, [onTotalChange, section])

  useEffect(() => {
    jssRef.current?.[0]?.hideIndex()
    if (hideHeaders) {
      jssRef.current?.[0]?.hideHeaders()
    }
  }, [])

  useEffect(() => {
    if (saving) {
      const json = jssRef.current?.[0]?.getJson()
      onSave(json, section)
    }
  }, [onSave, section, saving])

  return (
    <>
      <div
        className={clsx(
          '-mb-[3px] min-w-max',
          styles.sectionWorksheet,
          hideHeaders && styles.hideHeaders,
          !lastSection && styles.hideBottomBorder
        )}
      >
        {section && (
          <div className="h-11 bg-grey-50 font-bold px-3 flex items-center border border-solid border-[#ccc] min-w-max">
            {section}
          </div>
        )}
        <Spreadsheet
          ref={jssRef}
          allowExport={false}
          calculations={true}
          secureFormulas={false}
          editorFormulas={false}
          parseFormulas={false}
          debugFormulas={false}
          contextMenu={contextmenu}
          onchange={handleChange}
          ondeleterow={handleChange}
        >
          <Worksheet
            data={data}
            columns={columns}
            allowInsertColumn={false}
            allowManualInsertColumn={false}
            allowDeleteColumn={false}
            allowRenameColumn={false}
            columnDrag={false}
            allowComments={false}
            columnSorting={false}
            columnResize={false}
            minDimensions={[
              columns.length,
              isNonSectionEmpty ? 0 : data.length + 1,
            ]}
          />
        </Spreadsheet>
      </div>
      {!isNonSectionEmpty && (
        <div className={styles.sectionWorksheetAdd}>
          <div className=" border-solid border border-purple-200"></div>
          <Flex
            alignItems="center"
            gap={4}
            onClick={handleAddRow}
            className="absolute bg-purple-200 hover:bg-purple-300 text-white-100 rounded-full py-1.5 px-4 cursor-pointer font-bold z-10 -translate-x-1/2 left-1/2 transform -translate-y-1/2"
          >
            <Icon name={IconName.plusCircle} />
            Add Row
          </Flex>
        </div>
      )}
    </>
  )
}

export { SectionWorksheet }
