import clsx from 'clsx'
import { substatusToColor } from 'constants/substatus'
import { Indicator, IndicatorProps } from '../Indicator'
import { Text } from '../Text'
import styles from './styles.module.scss'

interface StatusBadgeProps {
  substatus: string
  color?: IndicatorProps['color']
  className?: string
}

function LoanSubstatusBadge({ substatus, color, className }: StatusBadgeProps) {
  return (
    <Text className={clsx(styles.substatus, className)}>
      <Indicator color={color || substatusToColor(substatus)} />
      {substatus}
    </Text>
  )
}

export default LoanSubstatusBadge
