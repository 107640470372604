import clsx from 'clsx'
import { compact } from 'lodash'
import { Fragment, useCallback, useMemo } from 'react'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Empty } from 'components/Table/Empty'
import tableStyles from 'components/Table/styles.module.scss'
import { FieldType, IBudgetWorksheet, IBudgetWorksheetItem } from 'types'
import { getSectionsOrder } from './helpers'

interface Props {
  worksheet: IBudgetWorksheet
  onSectionsChange: (sections: string[]) => void
  onDelete: (item: IBudgetWorksheetItem) => void
}

const WorkItemsTable = ({ worksheet, onSectionsChange, onDelete }: Props) => {
  const sectionsOrder = useMemo(() => getSectionsOrder(worksheet), [worksheet])

  const itemsBySection = useMemo(
    () =>
      worksheet.show_sections
        ? worksheet.items.reduce(
            (acc, item) => {
              if (!acc[item.Section]) {
                acc[item.Section] = []
              }
              acc[item.Section].push(item)
              return acc
            },
            {} as Record<string, IBudgetWorksheetItem[]>
          )
        : { '': worksheet.items },
    [sectionsOrder, worksheet]
  )

  const attributes = useMemo(() => {
    const workItem = worksheet?.attributes.find(
      (attribute) => attribute.name === 'Work Item'
    )
    return compact([
      workItem,
      worksheet.show_item_numbers && {
        name: 'Item Number',
        type: 'text' as FieldType,
      },
    ])
  }, [worksheet])

  const handleUpClick = useCallback(
    (index: number) => {
      const [removed] = sectionsOrder.splice(index, 1)
      sectionsOrder.splice(index - 1, 0, removed)
      onSectionsChange(sectionsOrder)
    },
    [sectionsOrder]
  )
  const handleDownClick = useCallback(
    (index: number) => {
      const [removed] = sectionsOrder.splice(index, 1)
      sectionsOrder.splice(index + 1, 0, removed)
      onSectionsChange(sectionsOrder)
    },
    [sectionsOrder]
  )

  return (
    <table className={clsx(tableStyles.table, 'w-full table-fixed')}>
      <thead>
        <tr>
          {attributes.map(({ name }) => (
            <th key={name} className={tableStyles.th}>
              <div className={clsx(tableStyles.cell, tableStyles.alignLeft)}>
                {name}
              </div>
            </th>
          ))}
          <th className={clsx(tableStyles.th, 'w-10')} />
        </tr>
      </thead>
      <tbody>
        {worksheet.items.length === 0 && (
          <tr>
            <td colSpan={attributes.length + 1}>
              <Empty hadData={false} />
            </td>
          </tr>
        )}
        {sectionsOrder.map((section, sectionIndex) => {
          const sectionItems = itemsBySection[section] || []

          return (
            <Fragment key={section}>
              {section !== '' &&
                worksheet.show_sections &&
                sectionItems.length > 0 && (
                  <tr>
                    <td
                      colSpan={attributes.length + 1}
                      className={clsx(tableStyles.td, 'bg-grey-50 px-3 py-1.5')}
                    >
                      <Flex
                        gap={10}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Flex gap={10} alignItems="center">
                          <div className="font-bold">{section}</div>
                          <div className="bg-green-25 text-green-100 px-2 py-0.5 rounded-full text-xs font-bold">
                            {sectionItems.length} items
                          </div>
                        </Flex>
                        <Flex gap={10} alignItems="center">
                          <Button
                            variant="ghost"
                            icon
                            size="small"
                            disabled={
                              sectionIndex ===
                              (itemsBySection['']?.length === 0 ? 0 : 1)
                            }
                            onClick={() => handleUpClick(sectionIndex)}
                          >
                            <Icon
                              name={IconName.arrowLeftLong}
                              className="rotate-90 text-grey-600"
                            />
                          </Button>
                          <Button
                            variant="ghost"
                            icon
                            size="small"
                            disabled={sectionIndex === sectionsOrder.length - 1}
                            onClick={() => handleDownClick(sectionIndex)}
                          >
                            <Icon
                              name={IconName.arrowLeftLong}
                              className="-rotate-90 text-grey-600"
                            />
                          </Button>
                        </Flex>
                      </Flex>
                    </td>
                  </tr>
                )}
              {sectionItems.map((item, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {attributes.map(({ name }, cellIndex) => (
                      <td
                        key={`${rowIndex}_${cellIndex}`}
                        className={clsx(tableStyles.td)}
                      >
                        <div
                          className={clsx(
                            tableStyles.cell,
                            tableStyles.alignLeft,
                            'truncate'
                          )}
                        >
                          {item[name]}
                        </div>
                      </td>
                    ))}
                    <td className={clsx(tableStyles.td)}>
                      <EllipsesActions>
                        <EllipsesActions.Item
                          icon
                          onSelect={() => onDelete(item)}
                          className="text-red-100"
                        >
                          <Icon name={IconName.delete} />
                          Delete
                        </EllipsesActions.Item>
                      </EllipsesActions>
                    </td>
                  </tr>
                )
              })}
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

export { WorkItemsTable }
