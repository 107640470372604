import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { Flex } from 'components/Flex'
import { Text } from 'components/Text'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import styles from './styles.module.scss'

interface ColumnProps {
  id: string
  title: string
  loans?: Loan[]
  children?: ReactNode
  onDrop: (id: string) => void
}

// Function to get color hex code based on column title
function getTitleColor(title: string): { bg: string; text: string } {
  // Map of title to color hex codes
  const colorMap: Record<string, { bg: string; text: string }> = {
    Lead: { bg: 'bg-blue-10', text: 'text-white-100' },
    Approved: { bg: 'bg-green-9', text: 'text-white-100' },
    Processing: { bg: 'bg-blueberry-9', text: 'text-white-100' },
    Underwriting: { bg: 'bg-teal-9', text: 'text-white-100' },
    Closed: { bg: 'bg-sand-3', text: 'text-sand-9' },
  }

  // Return the color for the title or default to blue if not found
  return colorMap[title] || { bg: 'bg-blue-10', text: 'text-white-100' }
}

function Column({ id, title, loans, children, onDrop }: ColumnProps) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'item',
      canDrop: (item) => id !== item.columnId,
      drop: (item: { id: string; columnId: string }) => onDrop(item.id),
      collect: (monitor) => {
        return {
          isOver: monitor.canDrop() && monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    }),
    []
  )
  const amount = useMemo(
    () => sumDecimal(loans?.map(({ amount }) => amount)),
    [loans]
  )

  const color = useMemo(() => getTitleColor(title), [title])

  return (
    <div>
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        gap={8}
        className={styles.columnTitle}
      >
        <div
          className={clsx(
            'h-[22px] px-2 py-2 overflow-hidden rounded-sm inline-flex items-center justify-center gap-2',
            color.bg
          )}
        >
          <div
            className={clsx(
              'text-center flex flex-col justify-center text-sm font-bold break-words',
              color.text
            )}
          >
            {title}
          </div>
          {loans?.length ? (
            <div
              className="h-4 px-[2px] rounded-[2px] inline-flex flex-col justify-center items-center gap-[10px]"
              style={{
                backgroundColor:
                  color.text === 'text-white-100'
                    ? 'rgba(255, 255, 255, 0.24)'
                    : 'rgba(69, 69, 67, 0.10)',
              }}
            >
              <div
                className={clsx(
                  'self-stretch h-[13px] text-center flex flex-col justify-center text-xs font-bold leading-[18.7px] break-words',
                  color.text
                )}
              >
                {loans?.length}
              </div>
            </div>
          ) : null}
        </div>
        {loans?.length ? (
          <Text variant="m" className="text-grey-700 normal-nums">
            {formatUsd(amount, { showZero: true })}
          </Text>
        ) : (
          <div />
        )}
      </Flex>
      <div ref={drop} className={styles.column}>
        <div className={clsx(styles.items, { [styles.over]: isOver })}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Column
