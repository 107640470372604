import { PropsWithChildren } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Field } from 'components/Form'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'

interface Props {
  magicLinkButtonLoading: boolean
  backButtonDisabled: boolean
  onBack: () => void
  hideBackButton?: boolean
}

const SendMagicLink = ({
  magicLinkButtonLoading,
  backButtonDisabled,
  onBack,
  hideBackButton = false,
}: PropsWithChildren<Props>) => {
  return (
    <Flex stack gap={32}>
      <Flex className="w-full">
        <div className="flex w-full flex-col gap-2">
          <Header className="text-left w-full">One last step!</Header>
          <Header
            variant="h2"
            className="text-left w-full text-grey-700 font-normal mb-4"
          >
            Verify your email to submit your application
          </Header>
          <Field
            name="user-name"
            label="Full name"
            placeholder="e.g. John Smith"
            autoComplete="username"
          />{' '}
          <Field
            name="user-email"
            label="E-mail"
            placeholder="e.g. name@mail.com"
            autoComplete="email"
          />{' '}
          <Flex gap={16} justifyContent="flex-end" className="w-full mt-auto">
            {!hideBackButton && (
              <Button
                type="button"
                variant="tertiary"
                className="h-11 w-11 rounded-full text-xl"
                onClick={onBack}
                disabled={backButtonDisabled}
              >
                <Icon name={IconName.arrowLeftLong} className="text-grey-600" />
              </Button>
            )}
            <Button
              type="submit"
              loading={magicLinkButtonLoading}
              className="h-11 rounded-full px-6 text-xl shadow-0"
            >
              Send Magic Link
            </Button>
          </Flex>
        </div>
      </Flex>
    </Flex>
  )
}

export { SendMagicLink }
