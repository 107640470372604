import { CellContext, ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { isString } from 'lodash'
import { Fragment, useMemo, useState } from 'react'
import { AssociatedBadges } from 'admin/components/AssociatedBadges'
import { useUpdateOwners } from 'admin/hooks/use-persons'
import { EllipsesMenu } from 'admin/pages/Loan/TabContacts/EllipsesMenu'
import { ParticipantBadge } from 'admin/pages/Loan/TabContacts/ParticipantBadge'
import { pathTo } from 'admin/path-to'
import { Vendor } from 'admin/services/api/vendors'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { BodyRow, Thead } from 'components/Table'
import { BodyLoader } from 'components/Table/BodyLoader'
import { Empty } from 'components/Table/Empty'
import tableStyles from 'components/Table/styles.module.scss'
import { useTable } from 'components/Table/use-table'
import { Person, Loan } from 'types'
import { formatPhone } from 'utils/phone'

interface Props {
  vendors?: Vendor[]
  participants?: Person[]
  loading?: boolean
  disabled?: boolean
  onEmailClick: (person: Person) => void
  onClick?: (vendor?: Person) => void
  onDelete: (person: Person) => void
  onUpdateItem: (person?: Person) => void
  loan: Loan
}

interface Row extends Person {
  guarantee?: { primary: boolean }
  borrow?: { primary: boolean }
  role?: Vendor['role']
}

function TableContacts({
  vendors = [],
  participants = [],
  loading,
  disabled,
  onEmailClick,
  onClick,
  onDelete,
  loan,
  onUpdateItem,
}: Props) {
  const { options } = useOwnerSelector()
  const { mutate: updateOwners } = useUpdateOwners()
  const [open, setOpen] = useState(false)
  const sections = useMemo(
    () => ['Borrowers, Guarantors and Viewers', 'Vendors'],
    []
  )

  const columns: ColumnDef<Row>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row }) => {
          return (
            <Flex gap={8} alignItems="center">
              <div className="inline-flex items-center justify-center bg-grey-75 text-grey-600 w-6 h-6 rounded-sm flex-shrink-0">
                <Icon
                  name={
                    row.original?.type === 'entity'
                      ? IconName.company
                      : IconName.person
                  }
                />
              </div>
              <div className="truncate">{row.original.name || '-'}</div>
            </Flex>
          )
        },
      },
      {
        header: 'Role',
        size: 205,
        cell: ({ row }) => {
          const person = row.original
          return (
            <Flex gap={8} alignItems="center" className="truncate">
              {(person.borrow || person.guarantee) && (
                <ParticipantBadge participant={person} />
              )}
              {!person.borrow && !person.guarantee && (person.role || '')}
            </Flex>
          )
        },
      },
      {
        header: 'Associated With',
        accessorKey: 'associatedWith',
        size: 204,
        cell: ({ row }) => {
          return (
            <AssociatedBadges
              associatedWith={row.original.associatedWith}
              getUrlById={(id: string) => pathTo('vendor', id)}
            />
          )
        },
      },
      {
        header: 'Email',
        accessorKey: 'email',
        cell: ({ row }) => {
          const person = row.original
          return isString(person.email) ? (
            <span
              className="link"
              onClick={(e) => {
                e.stopPropagation()
                onEmailClick(person)
              }}
            >
              {person.email}
            </span>
          ) : (
            '-'
          )
        },
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
        cell: ({ getValue }) => {
          const phone = getValue() as string
          return phone ? (
            <span
              className="link"
              onClick={(e) => {
                e.stopPropagation()
                window.location.href = `tel:${getValue()}`
              }}
            >
              {formatPhone(getValue() as string)}
            </span>
          ) : (
            '-'
          )
        },
      },
      {
        header: 'Account Owner',
        accessorKey: 'owners',
        size: 120,
        cell: (cellContext) => {
          const { row, hover, active, onActive } = cellContext as CellContext<
            Person,
            unknown
          > & {
            hover: boolean
            active: boolean
            onActive: (active: boolean) => void
          }
          const selectedOwners = row.original.owners || []
          const missedOptions =
            selectedOwners?.filter(({ id }) => {
              return !options.find((option) => option.id === id)
            }) || []

          return (
            <OwnerSelector
              variant="small"
              readOnly={!hover && !active}
              selectedUsers={selectedOwners.map(({ id }) => id)}
              userOptions={[
                ...options,
                ...missedOptions.map((option) => ({ ...option, email: '-' })),
              ]}
              onChange={(owners) => {
                updateOwners(
                  { person: row.original, owners },
                  { onSuccess: onUpdateItem }
                )
              }}
              onOpen={onActive}
            />
          )
        },
      },
      {
        header: '',
        accessorKey: 'id',
        size: 42,
        cell: ({ row }) => {
          if (disabled) {
            return null
          }
          return row.original.isVendor ? (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(row.original)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Remove
              </EllipsesActions.Item>
            </EllipsesActions>
          ) : (
            <EllipsesMenu
              open={open}
              setOpen={setOpen}
              participant={row.original}
              variant="ghost"
              className="hover:bg-transparent"
              loan={loan}
            />
          )
        },
      },
    ],
    [disabled, options, onEmailClick, onUpdateItem, onDelete]
  )

  const rows = useMemo(
    () => [...vendors, ...participants],
    [vendors, participants]
  )
  const { table, isEmpty, isScrollbarVisible, hadData, tableWrapperEl } =
    useTable<Person>({
      data: rows,
      columns: columns,
      getRowId: (originalRow: Person) => originalRow.id,
    })

  return (
    <div className={tableStyles.tableWrapper} ref={tableWrapperEl}>
      <table
        className={clsx(tableStyles.table, {
          [tableStyles.firstColumnBorder]: isScrollbarVisible,
          [tableStyles.loading]: loading,
          [tableStyles.empty]: isEmpty,
        })}
      >
        <Thead
          headerGroups={table.getHeaderGroups()}
          isLoaderVisible={loading}
        />
        <tbody>
          {isEmpty && (
            <tr>
              <td colSpan={columns.length}>
                <Empty hadData={hadData} />
              </td>
            </tr>
          )}
          {loading ? (
            <BodyLoader />
          ) : (
            sections.map((section) => {
              const sectionItems =
                section === 'Vendors' ? vendors : participants

              return (
                <Fragment key={section}>
                  {sectionItems.length > 0 && (
                    <tr>
                      <td
                        colSpan={2}
                        className={clsx(
                          tableStyles.td,
                          '!bg-grey-50 !border-r-0 px-3 py-2.5 sticky left-0'
                        )}
                      >
                        <div className="block font-bold">{section}</div>
                      </td>
                      <td
                        colSpan={columns.length - 2}
                        className={clsx(tableStyles.td, 'bg-grey-50')}
                      />
                    </tr>
                  )}
                  {sectionItems.map((item, index) => {
                    const row = table.getRow(item.id)
                    return (
                      <BodyRow
                        key={row.id + index}
                        row={row}
                        onClick={onClick}
                      />
                    )
                  })}
                </Fragment>
              )
            })
          )}
        </tbody>
      </table>
    </div>
  )
}

export { TableContacts }
