import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isLoanServicing, pathToLoan } from 'admin/path-to'
import { pathTo } from 'borrower/path-to'
import { BadgeBudgetStatus } from 'components/Badge'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { ModalAddBudget } from 'components/Modal/AddBudget'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalDelete } from 'components/Modal/Delete'
import { TableRehabBudget } from 'components/TableRehabBudget'
import { TextLink } from 'components/TextLink'
import { useBudgetWorksheet } from 'hooks/use-budget-worksheet'
import {
  useBudget,
  useDeleteBudgetItem,
  useUpdateBudgetItem,
  useUpdateBudgetStatus,
} from 'hooks/use-loan-budget'
import { Address, BudgetItem, Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import { ModalRejectBudgetItem } from '../Modal/RejectBudgetItem'
import styles from './PanelRehabBudget.module.scss'
import { Panel } from './index'

interface Props {
  loan: Loan
  address: Address
  borrower?: boolean
}

function PanelRehabBudget({ loan, address, borrower }: Props) {
  const navigate = useNavigate()
  const [editingBudgetItem, setEditingBudgetItem] = useState<BudgetItem>()
  const [deletingBudgetItem, setDeletingBudgetItem] = useState<BudgetItem>()
  const [isBudgetModalVisible, setIsBudgetModalVisible] = useState(false)
  const [rejectingBudgetItemId, setRejectingBudgetItemId] = useState<string>()
  const [isApproveConfirmationVisible, setIsApproveConfirmationVisible] =
    useState(false)
  const [isRevisionConfirmationVisible, setIsRevisionConfirmationVisible] =
    useState(false)
  const [isInReviewConfirmationVisible, setIsInReviewConfirmationVisible] =
    useState(false)
  const { data: budget, isPending } = useBudget(loan.id, address.id)
  const { mutate: updateStatus, isPending: updatingStatus } =
    useUpdateBudgetStatus(loan.id, address.id)
  const { mutate: updateItem, isPending: updatingItem } = useUpdateBudgetItem(
    loan.id,
    address.id
  )
  const { mutate: deleteItem, isPending: deletingItem } = useDeleteBudgetItem(
    loan.id,
    address.id
  )
  const { data: worksheet, isPending: isLoadingWorksheet } = useBudgetWorksheet(
    loan.settings.budgetWorksheetId ?? 'default'
  )

  const total = useMemo(
    () => sumDecimal(budget?.items.map(({ amount }) => amount)),
    [budget?.items]
  )
  const isEmpty = !budget?.items.length

  return (
    <Panel
      title={
        <Flex alignItems="center">
          Rehab Budget{' '}
          {budget?.status && <BadgeBudgetStatus status={budget?.status} />}
        </Flex>
      }
      actionChildren={
        borrower ? (
          <Flex gap={8}>
            <Button
              disabled={loan.lock}
              onClick={() => {
                const isActiveLoan = isLoanServicing(loan.status)
                if (isActiveLoan) {
                  navigate(pathTo('loanScopeOfWork', loan.id, address.id))
                } else {
                  navigate(
                    pathTo('applicationScopeOfWork', loan.id, address.id)
                  )
                }
              }}
              variant="secondary"
            >
              Bulk Edit
            </Button>
            <Button
              disabled={
                isEmpty || ['Approved', 'In Review'].includes(budget?.status)
              }
              onClick={() => setIsInReviewConfirmationVisible(true)}
            >
              Send For Review
            </Button>
          </Flex>
        ) : (
          <Flex gap={8}>
            <Button
              disabled={loan.lock}
              onClick={() => setIsRevisionConfirmationVisible(true)}
              variant="secondary"
            >
              Request Revisions
            </Button>
            <Button
              disabled={loan.lock}
              onClick={() =>
                navigate(pathToLoan(loan, 'loanScopeOfWork', address.id))
              }
              variant="secondary"
            >
              Bulk Edit
            </Button>
            <Button
              disabled={loan.lock || isEmpty || budget?.status === 'Approved'}
              onClick={() => setIsApproveConfirmationVisible(true)}
            >
              Approve
            </Button>
          </Flex>
        )
      }
      loading={isPending || isLoadingWorksheet}
    >
      <TableRehabBudget
        worksheet={worksheet!}
        data={budget?.items || []}
        onAccept={
          loan.lock || borrower
            ? undefined
            : (item) => updateItem({ id: item.id, status: 'Approved' })
        }
        onReject={
          loan.lock || borrower
            ? undefined
            : (item, message) => {
                if (message === 'Other') {
                  setRejectingBudgetItemId(item.id)
                } else {
                  updateItem({
                    id: item.id,
                    status: 'Rejected',
                    reason: message,
                  })
                }
              }
        }
        onEdit={
          loan.lock || (borrower && budget?.status === 'Approved')
            ? undefined
            : (item) => {
                setEditingBudgetItem(item)
                setIsBudgetModalVisible(true)
              }
        }
        onRemove={
          loan.lock || (borrower && budget?.status === 'Approved')
            ? undefined
            : (item) => setDeletingBudgetItem(item)
        }
      />
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        className={styles.addLink}
      >
        {loan.lock ? (
          <div />
        ) : (
          <TextLink onClick={() => setIsBudgetModalVisible(true)}>
            <Icon name={IconName.plus} size="sm" />
            Add Budget
          </TextLink>
        )}
        {!isEmpty && (
          <div>
            Total: <b>{formatUsd(total)}</b>
          </div>
        )}
      </Flex>

      {isBudgetModalVisible && (
        <ModalAddBudget
          worksheet={worksheet!}
          loanId={loan.id}
          addressId={address.id}
          budgetItem={editingBudgetItem}
          onCancel={() => {
            setIsBudgetModalVisible(false)
            setEditingBudgetItem(undefined)
          }}
        />
      )}
      {deletingBudgetItem && (
        <ModalDelete
          resource="budget item"
          name={deletingBudgetItem.name}
          loading={deletingItem}
          onDelete={() =>
            deleteItem(deletingBudgetItem.id, {
              onSuccess: () => {
                setDeletingBudgetItem(undefined)
              },
            })
          }
          onCancel={() => setDeletingBudgetItem(undefined)}
        />
      )}
      {isApproveConfirmationVisible && (
        <ModalConfirm
          title="Approve Budget?"
          text="The borrower will be notified that the budget has been approved, and all budget items will be marked as 'Approved'."
          loading={updatingStatus}
          onConfirm={() =>
            updateStatus('Approved', {
              onSuccess: () => {
                setIsApproveConfirmationVisible(false)
              },
            })
          }
          onCancel={() => setIsApproveConfirmationVisible(false)}
        />
      )}
      {isRevisionConfirmationVisible && (
        <ModalConfirm
          title="Request Revisions?"
          text={
            'The borrower will be notified that revisions to the budget are required, and the budget will be marked as "Rejected".'
          }
          loading={updatingStatus}
          buttonText="Send"
          onConfirm={() =>
            updateStatus('Rejected', {
              onSuccess: () => {
                setIsRevisionConfirmationVisible(false)
              },
            })
          }
          onCancel={() => setIsRevisionConfirmationVisible(false)}
        />
      )}
      {isInReviewConfirmationVisible && (
        <ModalConfirm
          title="Send For Review?"
          text="The lender will be notified that the budget is ready for review, and the budget will be marked as 'In Review'. You may continue to make changes to the budget until it is approved."
          loading={updatingStatus}
          buttonText="Send"
          onConfirm={() =>
            updateStatus('In Review', {
              onSuccess: () => {
                setIsInReviewConfirmationVisible(false)
              },
            })
          }
          onCancel={() => setIsInReviewConfirmationVisible(false)}
        />
      )}
      {rejectingBudgetItemId && (
        <ModalRejectBudgetItem
          saving={updatingItem}
          onReject={({ message }) =>
            updateItem(
              {
                id: rejectingBudgetItemId,
                status: 'Rejected',
                reason: message,
              },
              {
                onSuccess: () => {
                  setRejectingBudgetItemId(undefined)
                },
              }
            )
          }
          onCancel={() => setRejectingBudgetItemId(undefined)}
        />
      )}
    </Panel>
  )
}

export { PanelRehabBudget }
