import clsx from 'clsx'
import { ReactNode } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props {
  color?:
    | 'sand'
    | 'gold'
    | 'yellow'
    | 'amber'
    | 'orange'
    | 'ruby'
    | 'crimson'
    | 'raspberry'
    | 'plum'
    | 'purple'
    | 'violet'
    | 'iris'
    | 'indigo'
    | 'blue'
    | 'blueberry'
    | 'teal'
    | 'jade'
    | 'green'
    | 'volt'
  iconName?: IconName
  className?: string
  children: ReactNode
  onClose?: () => void
}

export const Callout = ({
  color = 'violet',
  iconName = IconName.info,
  className,
  children,
  onClose,
}: Props) => {
  const classNames = clsx(
    styles.callout,
    className,
    color === 'sand' && 'text-sand-12 bg-sand-alpha-3 border-sand-alpha-5',
    color === 'gold' && 'text-gold-12 bg-gold-alpha-3 border-gold-alpha-5',
    color === 'yellow' &&
      'text-yellow-12 bg-yellow-alpha-3 border-yellow-alpha-5',
    color === 'amber' && 'text-amber-12 bg-amber-alpha-3 border-amber-alpha-5',
    color === 'orange' &&
      'text-orange-12 bg-orange-alpha-3 border-orange-alpha-5',
    color === 'ruby' && 'text-ruby-12 bg-ruby-alpha-3 border-ruby-alpha-5',
    color === 'crimson' &&
      'text-crimson-12 bg-crimson-alpha-3 border-crimson-alpha-5',
    color === 'raspberry' &&
      'text-raspberry-12 bg-raspberry-alpha-3 border-raspberry-alpha-5',
    color === 'plum' && 'text-plum-12 bg-plum-alpha-3 border-plum-alpha-5',
    color === 'purple' &&
      'text-purple-12 bg-purple-alpha-3 border-purple-alpha-5',
    color === 'violet' &&
      'text-violet-12 bg-violet-alpha-3 border-violet-alpha-5',
    color === 'iris' && 'text-iris-12 bg-iris-alpha-3 border-iris-alpha-5',
    color === 'indigo' &&
      'text-indigo-12 bg-indigo-alpha-3 border-indigo-alpha-5',
    color === 'blue' && 'text-blue-12 bg-blue-alpha-3 border-blue-alpha-5',
    color === 'blueberry' &&
      'text-blueberry-12 bg-blueberry-alpha-3 border-blueberry-alpha-5',
    color === 'teal' && 'text-teal-12 bg-teal-alpha-3 border-teal-alpha-5',
    color === 'jade' && 'text-jade-12 bg-jade-alpha-3 border-jade-alpha-5',
    color === 'green' && 'text-green-12 bg-green-alpha-3 border-green-alpha-5',
    color === 'volt' && 'text-volt-12 bg-volt-alpha-3 border-volt-alpha-5'
  )

  return (
    <Flex className={classNames} alignItems="center" gap={8}>
      <Icon className={styles.icon} name={iconName} size="md" />
      <Flex
        gap={8}
        justifyContent="space-between"
        alignItems="center"
        className="w-full"
      >
        <div className="flex-grow">{children}</div>
        {onClose && (
          <div className="cursor-pointer" onClick={onClose}>
            <Icon
              className={styles.iconClose}
              name={IconName.close}
              size="sm"
            />
          </div>
        )}
      </Flex>
    </Flex>
  )
}
