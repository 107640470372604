import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDockContext } from 'admin/components/Dock/DockContext'
import { DockThreadTab } from 'admin/components/DockThreadTab/DockThreadTab'
import {
  useLoanVendors,
  useRemoveLoanVendor,
} from 'admin/hooks/use-loan-vendors'
import { useMailSignatures } from 'admin/hooks/use-thread-signature'
import { useAddThread } from 'admin/hooks/use-threads'
import { TableContacts } from 'admin/pages/Loan/TabContacts/TableContacts'
import { pathTo, pathToLoan } from 'admin/path-to'
import { Vendor } from 'admin/services/api/vendors'
import { Button } from 'components/Button'
import { Header } from 'components/Header'
import { ModalDelete } from 'components/Modal/Delete'
import { usePagination } from 'hooks/use-pagination'
import { Loan, Person } from 'types'

interface Props {
  loan: Loan
  participants: Person[]
  updateParticipant: (person: Person) => void
}

function PanelContacts({ loan, participants, updateParticipant }: Props) {
  const navigate = useNavigate()
  const { data: signatures } = useMailSignatures()
  const { mutate: addThread } = useAddThread()
  const signature = useMemo(
    () => signatures?.find(({ isDefault }) => isDefault),
    [signatures]
  )
  const { addTab } = useDockContext()
  const [removingId, setRemovingId] = useState<string | null>(null)
  const { visibleItems, result, updateItem } = usePagination<Vendor>({
    property: 'vendors',
    useData: (params) =>
      useLoanVendors({ ...params, loanId: loan.id, details: true }),
  })
  const remove = useRemoveLoanVendor()

  const handleEmailClick = useCallback(
    (person: Person) => {
      console.log(person)
      addThread(
        {
          loanId: loan.id,
          to: [
            {
              name: person.name,
              email: person.email,
              personId: person.id,
            },
          ],
          body: signature ? `<p></p><p></p><p>--</p>${signature.body}` : '',
        },
        {
          onSuccess: (thread) => {
            addTab({
              id: thread.id as string,
              title: thread.mails[0]?.subject as string,
              content: <DockThreadTab threadId={thread.id as string} />,
              expandByDefault: true,
              onMaximizeClick: () =>
                navigate(
                  pathToLoan(
                    loan,
                    'loanTabEntityId',
                    'mail',
                    thread.id as string
                  )
                ),
            })
          },
        }
      )
    },
    [addThread, loan, signature, addTab, navigate]
  )

  const handleRowClick = useCallback(
    (person?: Person) => {
      const path = person?.isVendor ? 'vendor' : 'borrower'
      if (person?.id) {
        navigate(pathTo(path, person.id))
      }
    },
    [navigate]
  )

  const handleDelete = useCallback(
    (person: Person) => {
      if (person.isVendor) {
        setRemovingId(person.id)
      }
    },
    [setRemovingId]
  )

  const handleUpdateItem = useCallback(
    (person?: Person) => {
      if (person?.isVendor) {
        updateItem(person as Vendor)
      } else {
        updateParticipant(person as Person)
      }
    },
    [updateItem, updateParticipant]
  )

  return (
    <div className="mb-6 relative">
      <Header variant="h3" className="mb-3">
        All Contacts
      </Header>
      <Button
        className="absolute top-0 right-0"
        onClick={() => {}}
        disabled={loan.lock}
      >
        Add Contact
      </Button>
      <TableContacts
        vendors={visibleItems}
        participants={participants}
        loading={result.isPending}
        disabled={loan.lock}
        onEmailClick={handleEmailClick}
        onClick={handleRowClick}
        onDelete={handleDelete}
        onUpdateItem={handleUpdateItem}
        loan={loan}
      />
      {removingId && (
        <ModalDelete
          title="Remove Vendor"
          action="Remove"
          resource="vendor"
          name={
            visibleItems?.find((vendor) => vendor.id === removingId)?.name ||
            visibleItems?.find((vendor) => vendor.id === removingId)
              ?.companyName
          }
          loading={remove.isPending}
          onDelete={() =>
            remove.mutate(
              { loanId: loan.id, vendorId: removingId },
              {
                onSettled: () => {
                  setRemovingId(null)
                },
              }
            )
          }
          onCancel={() => setRemovingId(null)}
        />
      )}
    </div>
  )
}

export { PanelContacts }
