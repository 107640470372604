import { Formik } from 'formik'
import { isNil, toNumber } from 'lodash'
import { Button } from 'components/Button'
import { Callout } from 'components/Callout'
import { Flex } from 'components/Flex'
import { Form, Checkbox, FieldIcon } from 'components/Form'
import { Grid } from 'components/Grid'
import { Payment } from 'types'
import { formatUsd } from 'utils/currency'
import { Modal } from '../index'
import styles from './styles.module.scss'

type FormValues = {
  sendNsf: boolean
  nsf: string
}

interface Props {
  payment: Payment | undefined
  defaultNsf?: string
  saving: boolean
  onSave: (nsf: number | undefined) => void
  onCancel: () => void
}

function ModalReversePayment({
  defaultNsf,
  payment,
  saving,
  onSave,
  onCancel,
}: Props) {
  const initialValue: FormValues = {
    sendNsf: !isNil(defaultNsf),
    nsf: defaultNsf || '',
  }

  return (
    <Modal title="Reverse payment" onClose={onCancel} className={styles.modal}>
      <div>Are you sure you want to reverse this payment?</div>
      <Formik
        initialValues={initialValue}
        onSubmit={({ sendNsf, nsf }) => {
          onSave(sendNsf && toNumber(nsf) > 0 ? toNumber(nsf) : undefined)
        }}
      >
        {({ values }) => (
          <Form>
            <Grid className={styles.form} columnGap={16}>
              <Grid.Item xs={12}>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  className={styles.nsfFeeRow}
                >
                  <Checkbox
                    name="sendNsf"
                    label="Apply NSF (Non-Sufficient Funds) Fee"
                  />
                  {values.sendNsf && (
                    <FieldIcon
                      type="currency"
                      name="nsf"
                      fieldClassName={styles.nsfFee}
                    />
                  )}
                </Flex>
                {payment?.meta?.usio ? (
                  <Flex className="mb-10">
                    <Callout color="yellow">
                      Note: The borrower will be credited the payment amount of{' '}
                      <b>{formatUsd(payment.amount)}</b>
                    </Callout>
                  </Flex>
                ) : null}
              </Grid.Item>
              <Grid.Item xs={12} className={styles.buttons}>
                <Button variant="tertiary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit" loading={saving}>
                  Reverse
                </Button>
              </Grid.Item>
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export { ModalReversePayment }
