import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Field, Form } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import styles from 'components/Modal/AddEmail/styles.module.scss'
import { TaskTemplate } from 'types'
import { createScheme, required } from 'utils/schemas'

interface Props {
  template?: TaskTemplate
  onCancel: () => void
  onSubmit: (data: Partial<TaskTemplate>) => void
  saving: boolean
}

type FormValues = {
  name: string
}
const Schema = createScheme({
  name: required,
})

function ModalTemplate({ template, onCancel, onSubmit, saving }: Props) {
  const initialValue: FormValues = {
    name: template?.name || '',
  }

  return (
    <Modal
      title={template ? 'Rename Template' : 'Add Template'}
      onClose={onCancel}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        <Form>
          <Grid columnGap={16}>
            <Grid.Item>
              <Field
                type="input"
                name="name"
                placeholder="Enter template name"
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalTemplate }
