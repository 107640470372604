import { useState } from 'react'
import {
  useTaskTemplate,
  useRemoveTaskTemplateTask,
  useEditTaskTemplateTask,
} from 'admin/hooks/use-task-templates'
import { TemplateTaskDrawer } from 'admin/pages/Tasks/TemplateTaskDrawer'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { TaskTemplateTask } from 'types'
import { TableTemplateTasks } from './TableTemplateTasks'

interface Props {
  id: string
}

function TaskTemplatePanel({ id }: Props) {
  const { data: taskTemplate, isPending } = useTaskTemplate(id)
  const [adding, setAdding] = useState<boolean>(false)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [editingId, setEditingId] = useState<string | null>(null)
  const { mutate: edit } = useEditTaskTemplateTask(id)
  const { mutate: remove, isPending: isRemoving } =
    useRemoveTaskTemplateTask(id)

  return (
    <>
      <Panel loading={isPending}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={10}
          className="mb-5"
        >
          <Header className="text-2xl">
            <Flex alignItems="center" gap={8}>
              <Flex alignItems="center" gap={8} className="text-grey-600">
                Templates <Icon name={IconName.arrowRightLong} />
              </Flex>
              {taskTemplate?.name}
            </Flex>
          </Header>
          <Button onClick={() => setAdding(true)}>New Task</Button>
        </Flex>
        <TableTemplateTasks
          templateTasks={taskTemplate?.tasks || []}
          onChange={(task) => {
            edit({
              ...task,
              owners: task.owners?.map(
                ({ id }) => id
              ) as unknown as TaskTemplateTask['owners'],
            })
          }}
          onClick={(templateTaskId) => {
            setEditingId(templateTaskId)
          }}
          onDelete={(templateTaskId) => {
            setRemovingId(templateTaskId)
          }}
          onEdit={(templateTaskId) => {
            setEditingId(templateTaskId)
          }}
        />
      </Panel>
      {(adding || editingId) && (
        <TemplateTaskDrawer
          templateId={id}
          templateTask={taskTemplate?.tasks?.find(({ id }) => id === editingId)}
          onClose={() => {
            setAdding(false)
            setEditingId(null)
          }}
          onDelete={(templateTaskId) => {
            setEditingId(null)
            setRemovingId(templateTaskId)
          }}
        />
      )}
      {removingId && (
        <ModalDelete
          resource="Task"
          name={taskTemplate?.tasks.find(({ id }) => id === removingId)?.name}
          loading={isRemoving}
          onDelete={() => {
            remove(removingId, {
              onSuccess: () => {
                setRemovingId(null)
              },
            })
          }}
          onCancel={() => setRemovingId(null)}
        />
      )}
    </>
  )
}
export { TaskTemplatePanel }
