import { uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CommentEditor,
  clearHTML,
  isEmpty,
} from 'admin/components/InlineWysiwyg/CommentEditor'
import {
  useDocumentComments,
  useAddDocumentComment,
  commentsPerPage,
  useUpdateDocumentComment,
  useDeleteDocumentComment,
} from 'admin/hooks/use-document'
import {
  useAddLoanComment,
  useDeleteLoanComment,
  useLoanComments,
  useUpdateLoanComment,
} from 'admin/hooks/use-loan-comments'
import { useUsers } from 'admin/hooks/use-users'
import { Button } from 'components/Button'
import { Comments } from 'components/Comments'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { Text } from 'components/Text'
import { useSession } from 'hooks/use-session'
import { useWebSocket } from 'hooks/use-websocket'
import { CurrentUser } from 'services/api/session'
import { IComment } from 'types'
import styles from './styles.module.scss'

export function PanelComments({
  loanId,
  documentId,
  isLocked,
}: {
  loanId?: string
  documentId: string
  isLocked: boolean
}) {
  const { user } = useSession()
  const { lastMessage } = useWebSocket()
  const [page, setPage] = useState(0)
  const [text, setText] = useState('')
  const [visibleComments, setVisibleComments] = useState<IComment[]>([])
  const [updatedComments, setUpdatedComments] = useState<IComment[]>([])
  const [deletedCommentIds, setDeletedCommentIds] = useState<string[]>([])
  const [showTypingIndicator, setShowTypingIndicator] = useState(false)

  const { data: newComments, isLoading } = loanId
    ? useLoanComments(
        { loanId },
        {
          refetchInterval: 10000,
        }
      )
    : useDocumentComments(
        { documentId },
        {
          refetchInterval: 10000,
        }
      )
  const { data: previousComments, isFetching: isFetchingPreviousComments } =
    loanId
      ? useLoanComments(
          {
            loanId,
            params: { page, size: commentsPerPage },
          },
          { enabled: page > 0 }
        )
      : useDocumentComments(
          {
            documentId,
            params: { page, size: commentsPerPage },
          },
          { enabled: page > 0 }
        )

  const { mutate: comment, isPending: posting } = loanId
    ? useAddLoanComment(loanId)
    : useAddDocumentComment(documentId)
  const { mutateAsync: updateCommentAsync } = loanId
    ? useUpdateLoanComment(loanId)
    : useUpdateDocumentComment(documentId)
  const { mutate: deleteComment } = loanId
    ? useDeleteLoanComment(loanId)
    : useDeleteDocumentComment(documentId)
  const { data: users } = useUsers({
    user,
    clientId: (user as CurrentUser)?.client?.id,
  })

  const mentionUsers = useMemo(
    () => [
      { id: 'baseline', value: 'Baseline', email: 'baseline' },
      ...(users?.map(({ id, name, email }) => ({ id, value: name, email })) ||
        []),
    ],
    [users]
  )
  const handleScroll = useCallback(
    (e) => {
      const hasScroll = e.target.scrollHeight > e.target.clientHeight
      const isScrollingToTop = e.target.scrollTop < 400
      const hasMore = (newComments?.total || 0) > visibleComments.length

      if (
        hasScroll &&
        isScrollingToTop &&
        !isFetchingPreviousComments &&
        hasMore
      ) {
        setPage((page) => page + 1)
      }
    },
    [isFetchingPreviousComments, visibleComments, newComments]
  )
  const handlePost = () => {
    if (!isEmpty(text)) {
      comment(clearHTML(text), {
        onSuccess: ({ comments }) => {
          setUpdatedComments(comments)
          setShowTypingIndicator(
            text.includes('&quot;id&quot;:&quot;baseline&quot;')
          )
        },
      })
      setText('')
    }
  }

  const handleEditComment = useCallback(
    ({ id, text }: Partial<IComment>) => {
      return updateCommentAsync(
        { id, text },
        {
          onSuccess: (comment) => {
            setUpdatedComments([comment])
          },
        }
      )
    },
    [updateCommentAsync]
  )
  const handleDeleteComment = useCallback(
    ({ id }) => {
      setDeletedCommentIds((deletedCommentIds) => [...deletedCommentIds, id])
      deleteComment(id)
    },
    [deleteComment]
  )

  useEffect(() => {
    if (lastMessage?.type === 'new-comment') {
      setShowTypingIndicator(false)
    }
  }, [lastMessage?.data])

  useEffect(() => {
    const nextComments = uniqBy(
      [
        ...updatedComments,
        ...(newComments?.comments || []),
        ...(previousComments?.comments || []),
        ...visibleComments,
      ]
        .filter(({ id }) => !deletedCommentIds.includes(id))
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ),
      'id'
    )
    setVisibleComments(nextComments)
  }, [newComments, previousComments])

  return (
    <div>
      <div className="sticky top-0 bg-white-100 px-3 pt-4">
        <Flex
          stack
          gap={12}
          className="p-3 border border-solid border-grey-200 rounded-xl"
        >
          <Flex gap={4}>
            <Icon name={IconName.message} className="text-black-100" />
            <Text>Comment</Text>
          </Flex>
          <CommentEditor
            disabled={isLocked}
            users={mentionUsers}
            placeholder="Write a comment and @mention teammates."
            dropdownPosition="bottom"
            value={text}
            onChange={setText}
            onEnter={handlePost}
          />
          <Flex justifyContent="flex-end">
            <Button
              onClick={handlePost}
              loading={posting}
              variant={isEmpty(text) ? 'tertiary' : 'primary'}
              disabled={isEmpty(text)}
              className="p-2"
            >
              <Icon name={IconName.moveUp} />
            </Button>
          </Flex>
        </Flex>
      </div>
      <div className={styles.comments} onScroll={handleScroll}>
        {isLoading && <PageLoader />}
        {!isLoading && visibleComments?.length === 0 && (
          <div className={styles.noComments}>No comments yet</div>
        )}
        <Comments
          users={mentionUsers}
          comments={visibleComments || []}
          onEdit={isLocked ? undefined : handleEditComment}
          onDelete={isLocked ? undefined : handleDeleteComment}
          showTypingIndicator={showTypingIndicator}
        />
      </div>
    </div>
  )
}
