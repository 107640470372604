import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAbilitiesContext } from 'admin/components/Abilities'
import { useLoanPeople } from 'admin/hooks/use-loan-people'
import { PanelContactCards } from 'admin/pages/Loan/TabContacts/PanelContactCards'
import styles from 'admin/pages/Loan/styles.module.scss'
import { Grid } from 'components/Grid'
import { Loan, Person } from 'types'
import PanelBorrower from './PanelBorrowers'
import { PanelContacts } from './PanelContacts'
import PanelGuarantor from './PanelGuarantors'

interface Props {
  loan: Loan
}

function TabContacts({ loan }: Props) {
  const ability = useAbilitiesContext()
  const [participants, setParticipants] = useState<Person[]>([])
  const { data: people } = useLoanPeople({ loanId: loan.id })

  const sortedPaticipants = useMemo(() => {
    return (
      people
        ?.filter((p) => !p.isVendor)
        .sort((a, b) => {
          const getOrder = (person) => {
            if (person.borrow?.primary) {
              return 1
            }
            if (person.borrow) {
              return 2
            }
            if (person.guarantee?.primary) {
              return 3
            }
            if (person.guarantee) {
              return 4
            }
            return 5
          }

          return getOrder(a) - getOrder(b)
        }) || []
    )
  }, [people])

  const updateParticipant = useCallback((person: Person) => {
    setParticipants((prev) =>
      prev.map((p) => (p.id === person.id ? person : p))
    )
  }, [])

  useEffect(() => {
    setParticipants(sortedPaticipants)
  }, [sortedPaticipants])

  return (
    <>
      <PanelContactCards participants={participants} loan={loan} />
      <PanelContacts
        loan={loan}
        participants={participants}
        updateParticipant={updateParticipant}
      />
      {ability.can('read', 'borrowers') ? (
        <Grid gap={16}>
          <Grid.Item sm={6} className={styles.panels}>
            <PanelBorrower loan={loan} />
          </Grid.Item>
          <Grid.Item sm={6} className={styles.panels}>
            <PanelGuarantor loan={loan} />
          </Grid.Item>
        </Grid>
      ) : null}
    </>
  )
}

export { TabContacts }
