import { useCallback } from 'react'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Option, Select } from 'components/Select'
import { Toggle } from 'components/Toggle'
import { useBudgetWorksheets } from 'hooks/use-budget-worksheet'
import { Product } from 'types'

interface Props {
  settings: Product['settings']
  onSettingsChange: (setting: Product['settings']) => void
}

function BalanceTable({ settings, onSettingsChange }: Props) {
  const { data } = useBudgetWorksheets()

  const handleChange = useCallback(
    (option: Option) => {
      onSettingsChange({
        budgetWorksheetId: option.value as string | undefined,
      })
    },
    [onSettingsChange]
  )

  return (
    <tr>
      <td colSpan={6}>
        <div className="bg-grey-75 h-[46px] leading-[46px]">
          <Header variant="h4" className="uppercase pl-3 text-2xl ">
            Budget
          </Header>
        </div>
        <table className="table w-full border-0 border-t-[1px] border-solid border-grey-200 border-collapse">
          <tbody>
            <tr>
              <td className="md:w-[65px] border-0 border-b-[1px] border-solid border-grey-200 px-2 py-3">
                <Toggle
                  checked={settings.showBudget}
                  onChange={(e) => {
                    onSettingsChange({
                      showBudget: e.target.checked,
                      showDrawRequests: e.target.checked,
                    })
                  }}
                />
              </td>
              <td className="md:w-[270px] text-grey-800 overflow-hidden text-ellipsis px-2 py-3 border-0 border-b-[1px] border-solid border-grey-200">
                Budget
              </td>
              <td className="text-grey-800 overflow-hidden text-ellipsis px-2 py-3 border-0 border-b-[1px] border-solid border-grey-200">
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  className="!flex-col md:!flex-row"
                >
                  <div>
                    Create and submit a Scope of Work budget for the project
                  </div>
                  <div className="w-full md:w-[335px] mr-9 shrink-0">
                    <Select
                      menuPlacement="top"
                      name="type"
                      portal
                      isDisabled={!settings.showBudget}
                      value={
                        settings.budgetWorksheetId ?? data?.worksheets?.[0]?.id
                      }
                      options={
                        data?.worksheets?.map((worksheet) => ({
                          value: worksheet.id,
                          label: worksheet.name,
                        })) || []
                      }
                      onChange={handleChange}
                    />
                  </div>
                </Flex>
              </td>
            </tr>
            <tr>
              <td className="px-2 py-3">
                <Toggle
                  checked={settings.showDrawRequests}
                  disabled={!settings.showBudget}
                  onChange={(e) =>
                    onSettingsChange({
                      showDrawRequests: e.target.checked,
                    })
                  }
                />
              </td>
              <td className="text-grey-800 overflow-hidden text-ellipsis px-2 py-3">
                Draw Requests
              </td>
              <td className="text-grey-800 overflow-hidden text-ellipsis px-2 py-3">
                Allow borrowers to submit draw requests in the borrower portal.
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  )
}

export { BalanceTable }
