import clsx from 'clsx'
import { ReactNode, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { DropdownItem } from 'admin/components/TopMenu/DropdownItem'
import { Dropdown } from 'components/Dropdown'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { useMobile } from 'hooks/use-mobile'
import { useSession } from 'hooks/use-session'
import styles from './styles.module.scss'

interface Props {
  children: ReactNode
  pathTo: any
  setIsMobileNavVisible: (visible: boolean) => void
}

const SideNavigation = ({ children, pathTo, setIsMobileNavVisible }: Props) => {
  const { user, isAdminManager } = useSession()
  const { isTablet } = useMobile()
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()
  const imageUrl = useMemo(() => user?.client.image?.logoUrl, [user])

  const target = ({ showDropdown = false }) => (
    <div
      className={clsx(
        'flex alignCenter gap-2 p-1 hover:bg-grey-100 hover:rounded cursor-pointer',
        menuOpen && 'bg-grey-100 rounded'
      )}
    >
      <div
        className={`${imageUrl ? '' : 'bg-purple-300'} bg-center bg-no-repeat w-[30px] h-[30px] rounded-[6px] flex items-center justify-center text-white-100 bg-contain `}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      >
        {imageUrl ? '' : user?.client?.name.charAt(0).toUpperCase()}
      </div>
      <div className="grow w-28 text-ellipsis overflow-hidden whitespace-nowrap text-base font-bold -tracking-[.1px]">
        {user?.client?.name}
      </div>
      {showDropdown && (
        <Icon name={IconName.dropdown} className="text-grey-600" />
      )}
    </div>
  )

  return (
    <div
      className={clsx('no-print', styles.nav, {
        [styles.mobile]: isTablet,
      })}
    >
      <div className={styles.navContent}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className={styles.navHeader}
        >
          <div className={styles.dropdown}>
            {isAdminManager ? (
              <Dropdown
                open={menuOpen}
                onOpenChange={(nextOpen) => {
                  setMenuOpen(nextOpen)
                }}
                trigger={target({ showDropdown: true })}
                className="!w-80 !left-3 !z-[1000]"
              >
                <div className="p-1">
                  <DropdownItem
                    itemName="Products"
                    iconName={IconName.productNavigation}
                    onSelect={() => {
                      navigate(pathTo('products'))
                      setMenuOpen(false)
                    }}
                  />
                  <DropdownItem
                    itemName="Automations"
                    iconName={IconName.automations}
                    onSelect={() => {
                      navigate(pathTo('settingsAutomations'))
                      setMenuOpen(false)
                    }}
                  />
                  <DropdownItem
                    itemName="Templates"
                    iconName={IconName.twoSheets}
                    onSelect={() => {
                      navigate(pathTo('settingsTemplates'))
                      setMenuOpen(false)
                    }}
                  />
                  <DropdownItem
                    itemName="Custom Data"
                    iconName={IconName.customField}
                    onSelect={() => {
                      navigate(pathTo('settingsCustomFields'))
                      setMenuOpen(false)
                    }}
                  />
                  <DropdownItem
                    itemName="Company Settings"
                    iconName={IconName.gearSettings}
                    onSelect={() => {
                      navigate(pathTo('settings'))
                      setMenuOpen(false)
                    }}
                  />
                </div>
              </Dropdown>
            ) : (
              <div onClick={() => navigate(pathTo('dashboard'))}>
                {target({ showDropdown: false })}
              </div>
            )}
          </div>
          {isTablet && (
            <Icon
              name={IconName.close}
              className={styles.closeIcon}
              size="lg"
              onClick={() => setIsMobileNavVisible(false)}
            />
          )}
        </Flex>
        <div className={styles.menus}>{children}</div>
      </div>
    </div>
  )
}

export default SideNavigation
