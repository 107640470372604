import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Sort, TaskTemplate } from 'types'
import { friendlyDate } from 'utils/date'
import { pluralize } from 'utils/pluralize'

interface Props {
  loading?: boolean
  templates: TaskTemplate[]
  onDelete: (id: string) => void
  onEdit: (id: string) => void
  onClick: (id: string) => void
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
}

function TableTemplates({
  sort,
  onSort,
  loading,
  templates,
  onClick,
  onEdit,
  onDelete,
}: Props) {
  const columns: ColumnDef<TaskTemplate>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'createdAt',
        header: 'Created',
        cell: ({ getValue }) => friendlyDate(getValue() as string),
      },
      {
        accessorKey: 'numTasks',
        header: 'Tasks',
        cell: ({ row }) => {
          const { numTasks } = row.original
          return `${pluralize(numTasks || 0, 'Task')}`
        },
      },
      {
        accessorKey: 'id',
        header: '',
        size: 40,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => {
                  onEdit(row.original.id)
                }}
              >
                <Icon name={IconName.edit} />
                Rename Template
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => {
                  onDelete(row.original.id)
                }}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete Template
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    [onDelete, onEdit]
  )
  return (
    <Table
      columns={columns}
      data={templates}
      loading={loading}
      sort={sort}
      onSort={onSort}
      onClick={(task) => task && onClick(task.id)}
    />
  )
}

export { TableTemplates }
