import { useState, useMemo } from 'react'
import {
  useAddTemplate,
  useUpdateTemplate,
  useDownloadTemplate,
  useRemoveTemplate,
  useResetTemplate,
  useTemplates,
  useUploadTemplate,
} from 'admin/hooks/use-templates'
import styles from 'admin/pages/Settings/styles.module.scss'
import { mergeTemplatesWithDefault } from 'admin/services/api/templates'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalTemplateName } from 'components/Modal/TemplateName'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { ITemplate } from 'types'
import { openBrowseFile } from 'utils/file'

export const PanelTemplates = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [search, setSearch] = useState<string>()
  const [uploaded, setUploaded] = useState<string[]>([])
  const [editingTemplate, setEditingTemplate] = useState<{
    id: string
    name: string
  }>()
  const [deletingTemplate, setDeletingTemplate] = useState<{
    id: string
    name: string
  }>()
  const [resettingTemplate, setResettingTemplate] = useState<Pick<
    ITemplate,
    'id' | 'name'
  > | null>(null)
  const { data: customTemplates = [], isPending } = useTemplates()
  const { mutate: addTemplate, isPending: adding } = useAddTemplate()
  const { mutate: updateTemplate, isPending: updating } = useUpdateTemplate()
  const { mutate: removeTemplate, isPending: deleting } = useRemoveTemplate()
  const { mutate: downloadTemplate } = useDownloadTemplate()
  const { mutate: reset, isPending: isResetting } = useResetTemplate()
  const { mutate: upload } = useUploadTemplate()

  const handleUpload = (id: string, name: string) => {
    openBrowseFile({
      onChoose: (files) =>
        upload(
          { id, name, file: files[0] },
          {
            onSuccess: () => setUploaded([...uploaded, id]),
          }
        ),
      accept: '.docx,.xlsx,.pdf',
    })
  }

  const templates = useMemo(
    () =>
      mergeTemplatesWithDefault(customTemplates).filter(
        ({ name }) =>
          !search || name.toLowerCase().includes(search.toLowerCase())
      ),
    [customTemplates, search]
  )

  return (
    <Panel loading={isPending}>
      <Flex stack gap={16}>
        <Flex justifyContent="flex-end" alignItems="center">
          <Search onSearch={setSearch} search={search} />
          <Button onClick={() => setIsModalVisible(true)}>
            <Icon name={IconName.documentPlus} />
            Add Template
          </Button>
        </Flex>
        <Flex>
          <Grid gap={16}>
            {templates.map(
              ({ id, name, mergeTags, fileType, isUploaded, isDefault }) => (
                <Grid.Item
                  sm={6}
                  lg={3}
                  md={4}
                  key={id}
                  className="p-4 border border-solid border-grey-200 rounded"
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center" gap={8}>
                      {!isUploaded && !!mergeTags && !uploaded.includes(id) ? (
                        <>{name}</>
                      ) : (
                        <a
                          className="link flex items-center"
                          onClick={() => downloadTemplate(id)}
                        >
                          {name}
                          {(isUploaded || !mergeTags) &&
                            !uploaded.includes(id) && (
                              <span className="uppercase text-grey-600 font-normal ml-2">
                                {fileType ??
                                  (id === 'rehab-budget' ? 'xlsx' : 'docx')}
                              </span>
                            )}
                          {uploaded.includes(id) && (
                            <Icon
                              name={IconName.circleCheck}
                              className="text-green-50 ml-2"
                            />
                          )}
                        </a>
                      )}
                    </Flex>
                    <Flex gap={4}>
                      <div className={styles.ellipses}>
                        <EllipsesActions>
                          <EllipsesActions.Item
                            icon
                            disabled={isDefault}
                            onSelect={() => {
                              setIsModalVisible(true)
                              setEditingTemplate({ id, name })
                            }}
                          >
                            <Icon name={IconName.edit} />
                            Rename
                          </EllipsesActions.Item>
                          <EllipsesActions.Item
                            icon
                            disabled={
                              !isUploaded &&
                              !!mergeTags &&
                              !uploaded.includes(id)
                            }
                            onSelect={() => {
                              downloadTemplate(id)
                            }}
                          >
                            <Icon name={IconName.download} />
                            Download
                          </EllipsesActions.Item>
                          <EllipsesActions.Item
                            icon
                            onSelect={() => {
                              handleUpload(id, name)
                            }}
                          >
                            <Icon name={IconName.upload} />
                            Upload
                          </EllipsesActions.Item>

                          <EllipsesActions.Item
                            icon
                            disabled={!isDefault}
                            onSelect={() => {
                              setResettingTemplate({ id, name })
                            }}
                          >
                            <Icon name={IconName.documentRefresh} />
                            Reset
                          </EllipsesActions.Item>

                          <EllipsesActions.Item
                            icon
                            disabled={isDefault}
                            onSelect={() => setDeletingTemplate({ id, name })}
                            className="text-red-100"
                          >
                            <Icon name={IconName.delete} />
                            Delete
                          </EllipsesActions.Item>
                        </EllipsesActions>
                      </div>
                    </Flex>
                  </Flex>
                </Grid.Item>
              )
            )}
          </Grid>
        </Flex>
      </Flex>
      {resettingTemplate && (
        <ModalConfirm
          title="Reset template"
          text={`Are you sure you want to reset ${resettingTemplate.name} template?`}
          loading={isResetting}
          onConfirm={() => {
            reset(resettingTemplate.id, {
              onSuccess: () => {
                setResettingTemplate(null)
              },
            })
          }}
          onCancel={() => setResettingTemplate(null)}
        />
      )}
      {isModalVisible && (
        <ModalTemplateName
          title={editingTemplate ? 'Rename Template' : 'Add New Template'}
          template={editingTemplate}
          saving={adding || updating}
          onSubmit={({ name }) => {
            const save = (editingTemplate ? updateTemplate : addTemplate) as any
            save(
              editingTemplate
                ? { id: editingTemplate.id, params: { name } }
                : name,
              {
                onSuccess: () => {
                  setIsModalVisible(false)
                  setEditingTemplate(undefined)
                },
              }
            )
          }}
          onCancel={() => {
            setIsModalVisible(false)
            setEditingTemplate(undefined)
          }}
        />
      )}
      {deletingTemplate && (
        <ModalDelete
          resource="template"
          name={deletingTemplate.name}
          loading={deleting}
          onDelete={() =>
            removeTemplate(deletingTemplate.id, {
              onSuccess: () => setDeletingTemplate(undefined),
            })
          }
          onCancel={() => setDeletingTemplate(undefined)}
        />
      )}
    </Panel>
  )
}
