import { ColumnDef, CellContext } from '@tanstack/react-table'
import { isString } from 'lodash'
import { useMemo, Dispatch, SetStateAction } from 'react'
import { AssociatedBadges } from 'admin/components/AssociatedBadges'
import { useUpdateOwners } from 'admin/hooks/use-persons'
import { pathTo } from 'admin/path-to'
import { Vendor } from 'admin/services/api/vendors'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LinkEmail } from 'components/LinkEmail'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Table } from 'components/Table'
import { Filter, Sort } from 'types/table'
import { formatUsd } from 'utils/currency'
import { formatPhone } from 'utils/phone'

type VendorsTableProps = {
  onDelete: (id: string) => void
  onFilter: Dispatch<SetStateAction<Filter | undefined>>
  filters?: Record<string, string[]>
  filter: Filter | undefined
  data?: Vendor[]
  loading?: boolean
  disabled?: boolean
  showCash?: boolean
  deleteCopy?: string
  onClick?: (vendor?: Vendor) => void
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  onUpdateItem: (investor: Vendor) => void
}

function TableVendors({
  onDelete,
  data = [],
  filters = {},
  filter = {},
  onFilter,
  sort,
  onSort,
  loading,
  disabled,
  showCash = true,
  deleteCopy = 'Delete',
  onClick,
  onUpdateItem,
}: VendorsTableProps) {
  const { options } = useOwnerSelector()
  const { mutate: updateOwners } = useUpdateOwners()

  const columns: ColumnDef<Vendor>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row }) => {
          return (
            <Flex gap={8} alignItems="center">
              <div className="inline-flex items-center justify-center bg-grey-75 text-grey-600 w-6 h-6 rounded-sm flex-shrink-0">
                <Icon
                  name={
                    row.original?.type === 'entity'
                      ? IconName.company
                      : IconName.person
                  }
                />
              </div>
              <div className="truncate">{row.original.name || '-'}</div>
            </Flex>
          )
        },
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Role',
        accessorKey: 'role',
        cell: ({ getValue }) => getValue() || '-',
        meta: {
          filter: {
            options: (filters.role || []).map((role) => ({
              value: role,
              label: role,
            })),
            selected: filter?.role,
            onChange: (role) => onFilter({ role }),
          },
        },
      },
      {
        header: 'Associated With',
        accessorKey: 'associatedWith',
        size: 204,
        cell: ({ row }) => {
          return (
            <AssociatedBadges
              associatedWith={row.original.associatedWith}
              getUrlById={(id: string) => pathTo('vendor', id)}
            />
          )
        },
      },
      {
        header: 'Email',
        accessorKey: 'email',
        cell: ({ getValue }) => {
          const email = getValue()
          return isString(email) ? LinkEmail({ email }) : '-'
        },
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
        cell: ({ getValue }) => (
          <span>{formatPhone(getValue() as string) || '-'}</span>
        ),
      },
      ...(showCash
        ? [
            {
              header: 'Cash',
              accessorKey: 'cashBalance',
              cell: ({ getValue }) => formatUsd(getValue() as string),
              meta: {
                align: 'right',
                sortable: true,
              },
            },
          ]
        : []),
      {
        header: 'Account Owner',
        accessorKey: 'owners',
        size: 120,
        cell: (cellContext) => {
          const { row, hover, active, onActive } = cellContext as CellContext<
            Vendor,
            unknown
          > & {
            hover: boolean
            active: boolean
            onActive: (active: boolean) => void
          }
          const selectedOwners = row.original.owners || []
          const missedOptions =
            selectedOwners?.filter(({ id }) => {
              return !options.find((option) => option.id === id)
            }) || []

          return (
            <OwnerSelector
              variant="small"
              readOnly={!hover && !active}
              selectedUsers={row.original.owners?.map(({ id }) => id) || []}
              userOptions={[
                ...options,
                ...missedOptions.map((option) => ({ ...option, email: '-' })),
              ]}
              onChange={(owners) => {
                updateOwners(
                  { person: row.original, owners },
                  { onSuccess: (person) => onUpdateItem(person as Vendor) }
                )
              }}
              onOpen={onActive}
            />
          )
        },
      },
      {
        header: '',
        accessorKey: 'id',
        size: 42,
        cell: ({ getValue }) => {
          return disabled ? null : (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(getValue() as string)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                {deleteCopy ?? 'Delete'}
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    [filters, onFilter, options]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClick}
      sort={sort}
      onSort={onSort}
    />
  )
}

export default TableVendors
