import { Form, Formik } from 'formik'
import { compact } from 'lodash'
import { useCallback, useState } from 'react'
import * as yup from 'yup'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Field, Option, Select, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { IBudgetWorksheet, FieldType } from 'types'
import { createScheme, mergeRules, required } from 'utils/schemas'
import { fields } from './fields'

type FormValues = {
  name: string
  type: FieldType
  options: string
}

interface Props {
  attribute?: IBudgetWorksheet['attributes'][0]
  saving: boolean
  onSave: (values: IBudgetWorksheet['attributes'][0]) => void
  onCancel: () => void
}

const schema = createScheme({
  name: mergeRules(
    yup.string().test(
      'restricted-name',
      ({ value }) => `"${value}" is a reserved name`,
      (value) =>
        value !== 'Work Item' && value !== 'Section' && value !== 'Amount'
    ),
    required
  ),
})

function ModalAttribute({ attribute, saving, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    name: attribute?.name || '',
    type: attribute?.type || 'text',
    options: attribute?.options
      ? attribute?.options.map(({ value }) => value).join('\r\n')
      : '',
  }
  const [type, setType] = useState<string>(initialValue.type)

  const handleTypeChange = useCallback(
    (option: Option) => {
      setType(option.value as FieldType)
    },
    [type]
  )

  const handleSubmit = useCallback(
    (values) => {
      let options: Option[] | undefined = undefined
      if (values.type === 'option') {
        options = values.options
          ? (compact(values.options.split(/\r?\n/)).map((value) => ({
              label: value,
              value: value,
            })) as Option[])
          : []
      }

      onSave({
        ...values,
        options,
      })
    },
    [onSave]
  )

  return (
    <Modal
      title={attribute ? 'Edit Attribute' : 'Add Attribute'}
      onClose={onCancel}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                label="Attribute Name"
                type="name"
                name="name"
                placeholder="Attribute name"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Select
                label="Attribute Type"
                name="type"
                portal
                options={fields.map(({ name, type, icon }) => ({
                  label: (
                    <Flex alignItems="center" gap={8}>
                      <img
                        className="h-5 w-5 flex-shrink-0"
                        src={icon}
                        alt=""
                      />{' '}
                      {name}
                    </Flex>
                  ),
                  value: type,
                }))}
                onChange={handleTypeChange}
              />
            </Grid.Item>
            {type === 'option' && (
              <Grid.Item xs={12}>
                <Textarea
                  name="options"
                  label="Options"
                  bottomHint="A list of options for people to choose from. Enter each option on a new line."
                  placeholder="A list of options for people to choose from. Enter each option on a new line."
                />
              </Grid.Item>
            )}
            <Grid.Item xs={12} className="flex justify-end gap-3.5">
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalAttribute }
