import { Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'
import { Callout } from 'components/Callout'
import { Icon, IconName } from 'components/Icon'
import { rootDomain } from '../../../constants/domain'
import { createScheme, required } from '../../../utils/schemas'
import { Button } from '../../Button'
import { Form, Field } from '../../Form'
import { Grid } from '../../Grid'
import { Text } from '../../Text'
import { Modal } from '../index'
import styles from './styles.module.scss'

const Schema = createScheme({
  name: required,
  subdomain: yup
    .string()
    .test(
      'valid-domain',
      'Must be a valid domain',
      (val) =>
        !!val?.match(
          /^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/
        )
    )
    .required(),
})

type FormValues = {
  name: string
  subdomain: string
}

interface Props {
  saving: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
  name: string
  subdomain: string
}

function ModalProfileDomainEdit({
  saving,
  onSubmit,
  onCancel,
  name,
  subdomain,
}: Props) {
  const [customDomain, setCustomDomain] = useState('')
  const initialValue: FormValues = {
    name: name || '',
    subdomain: subdomain || '',
  }

  const handleSubmit = (values: FormValues) => {
    if (!values.subdomain?.endsWith(rootDomain) && !customDomain) {
      setCustomDomain(values.subdomain)
    } else {
      onSubmit({
        ...values,
      })
    }
  }

  return (
    <Modal
      title="Workspace Settings"
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item>
              <Field
                type="text"
                label="Name"
                name="name"
                placeholder="Enter name"
              />
            </Grid.Item>
            <Grid.Item>
              <Field
                type="text"
                label="Domain"
                name="subdomain"
                placeholder="Enter domain"
                fieldClassName={styles.input}
                bottomHint={`YOUR_BRAND${rootDomain}`}
              />
              {customDomain && (
                <Callout color="yellow" className="mb-4">
                  <Text className="text-base">
                    You must properly configure a CNAME record from your domain
                    before making this change.
                  </Text>
                  <Text className="text-base font-bold mt-2">
                    CNAME <code>{customDomain}</code>{' '}
                    <Icon name={IconName.arrowRightLong} size="sm" />{' '}
                    <code>custom-domain{rootDomain}</code>
                  </Text>
                </Callout>
              )}
            </Grid.Item>
            <Grid.Item className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                {customDomain ? 'I have configured the CNAME' : 'Save'}
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalProfileDomainEdit
