import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { pathToLoan } from 'admin/path-to'
import { TabLoanApplication } from 'components/Application/TabLoanApplication'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Text } from 'components/Text'
import { ModalRequestApplication } from './ModalRequestApplication'
import { TabApplicationAlert } from './TabApplicationAlert'

const TabLoanOriginationApplication = () => {
  const { loan } = useLoanContext()
  const ability = useAbilitiesContext()
  const navigate = useNavigate()
  const hasPermissions = ability.can('read', 'borrowers')
  const [isRequestVisible, setIsRequestVisible] = useState(false)
  const handleOpenContacts = useCallback(() => {
    navigate(pathToLoan(loan, 'loanTab', 'contacts'))
  }, [loan, navigate])
  const handleRequestClick = useCallback(() => {
    setIsRequestVisible(true)
  }, [])
  const handleRequestClose = useCallback(() => {
    setIsRequestVisible(false)
  }, [])

  if (loan.applicationId) {
    return (
      <Flex stack gap={20}>
        <TabApplicationAlert onRequestChanges={handleRequestClick} />
        <TabLoanApplication
          loanId={loan.id}
          applicationId={loan.applicationId}
          btnClassName="-top-28"
        />
        {isRequestVisible && (
          <ModalRequestApplication onCancel={handleRequestClose} />
        )}
      </Flex>
    )
  }

  if (loan.dateApplicationRequested) {
    return (
      <>
        <TabApplicationAlert onRequestChanges={handleRequestClick} />
        {isRequestVisible && (
          <ModalRequestApplication onCancel={handleRequestClose} />
        )}
      </>
    )
  }

  if (loan.borrowers.length) {
    return (
      <Flex
        stack
        gap={32}
        justifyContent="center"
        alignItems="center"
        className="bg-grey-75 pb-[56px] pt-[50px] border border-solid border-grey-200 rounded-lg p-8"
      >
        <Flex stack gap={8} alignItems="center" className="text-grey-800">
          <Header variant="h3">Send an application request</Header>
          <Text variant="l">
            Once your borrower completes an application, it will show here.
          </Text>
        </Flex>
        <div>
          <Button variant="primary" onClick={handleRequestClick}>
            Request Application
          </Button>
        </div>
        {isRequestVisible && (
          <ModalRequestApplication onCancel={handleRequestClose} />
        )}
      </Flex>
    )
  }
  if (!loan.borrowers.length) {
    return (
      <Flex
        stack
        gap={32}
        justifyContent="center"
        alignItems="center"
        className="bg-grey-75 pb-[56px] pt-[50px] border border-solid border-grey-200 rounded-lg p-8"
      >
        <Header variant="h3" className="text-grey-800">
          {hasPermissions
            ? 'To request an application, you’ll first need to invite a borrower'
            : 'This loan does not have an application'}
        </Header>
        {hasPermissions && (
          <div>
            <Button variant="primary" onClick={handleOpenContacts}>
              Go to Contacts
            </Button>
          </div>
        )}
      </Flex>
    )
  }
  return null
}

export { TabLoanOriginationApplication }
