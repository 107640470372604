import { Formik } from 'formik'
import { toString } from 'lodash'
import { useMemo, useState } from 'react'
import { getSectionsOrder } from 'admin/pages/Settings/Worksheet/helpers'
import { Button } from 'components/Button'
import { Form, FieldIcon, Select, LoanField, Option } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { useAddBudgetItem, useUpdateBudgetItem } from 'hooks/use-loan-budget'
import {
  BudgetItem,
  Field,
  IBudgetWorksheet,
  IBudgetWorksheetItem,
} from 'types'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

const EmailSchema = createScheme({
  name: required,
  amount: required,
})

type FormValues = {
  name: string
  amount: string
} & Record<string, string>

interface ModalAddEmailProps {
  worksheet: IBudgetWorksheet
  loanId: string
  addressId: string
  budgetItem?: BudgetItem
  onCancel: () => void
}

function ModalAddBudget({
  worksheet,
  loanId,
  addressId,
  budgetItem,
  onCancel,
}: ModalAddEmailProps) {
  const [section, setSection] = useState(budgetItem?.section || '')
  const { mutate: add, isPending: adding } = useAddBudgetItem(loanId, addressId)
  const { mutate: edit, isPending: editing } = useUpdateBudgetItem(
    loanId,
    addressId
  )
  const handleSubmit = ({ name, amount, ...rest }) => {
    const save = (budgetItem ? edit : add) as any
    save(
      {
        id: budgetItem?.id,
        name,
        amount: parseFloat(amount),
        section,
        data: Object.entries(rest).map(([name, value]) => ({ name, value })),
      },
      {
        onSuccess: () => onCancel(),
      }
    )
  }

  const initialValue: FormValues = useMemo(
    () => ({
      name: budgetItem?.name || '',
      amount: toString(budgetItem?.amount) || '',
      ...worksheet.attributes
        .map(({ name }) => name)
        .filter((name) => name !== 'Work Item' && name !== 'Amount')
        .reduce((acc, name) => {
          const item = budgetItem?.data?.find((item) => item.name === name)
          return { ...acc, [name]: item?.value || '' }
        }, {}),
    }),
    [budgetItem]
  )

  const sectionsOrder = useMemo(() => getSectionsOrder(worksheet), [worksheet])

  const itemsBySection = useMemo(
    () =>
      worksheet?.show_sections
        ? worksheet.items.reduce(
            (acc, item) => {
              const section = item?.Section || ''
              if (!acc[section]) {
                acc[section] = []
              }
              acc[section].push(item)
              return acc
            },
            {} as Record<string, IBudgetWorksheetItem[]>
          )
        : { '': worksheet.items },
    [sectionsOrder, worksheet]
  )

  const workItemOptions: Option[] = Object.entries(itemsBySection).map(
    ([label, items]) => ({
      label: label || 'No section',
      options: items.map((item) => ({
        label: item['Work Item'],
        value: item['Work Item'],
        section: label,
      })),
    })
  )

  return (
    <Modal title="Add Budget" onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={initialValue}
        validationSchema={EmailSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            {worksheet.attributes.map(({ name, type, options }) => {
              if (name === 'Work Item') {
                return (
                  <Grid.Item xs={12} key={name}>
                    <Select
                      name="name"
                      label="Work to be done"
                      portal
                      options={workItemOptions}
                      onChange={(value) => {
                        setSection((value as any).section)
                      }}
                    />
                  </Grid.Item>
                )
              }
              if (name === 'Amount') {
                return (
                  <Grid.Item xs={12} key={name}>
                    <FieldIcon type="currency" name="amount" label="Amount" />
                  </Grid.Item>
                )
              }
              return (
                <Grid.Item xs={12} key={name}>
                  <LoanField
                    field={
                      {
                        id: name,
                        name,
                        type: [type],
                        options,
                      } as unknown as Field
                    }
                  />
                </Grid.Item>
              )
            })}
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={adding || editing} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalAddBudget }
