import { compact, union, uniq } from 'lodash'
import { IBudgetWorksheet } from 'types'

const getSectionsOrder = (worksheet: IBudgetWorksheet | undefined) => {
  if (worksheet?.show_sections) {
    const itemSections: string[] = uniq(
      compact(worksheet.items.map((item) => item.Section))
    )
    const existingSections = (worksheet.sections || []).filter((section) =>
      itemSections.includes(section)
    )
    return ['', ...union(existingSections, itemSections)]
  }
  return ['']
}

export { getSectionsOrder }
