import { createPath } from 'utils/path'

export const prefix = ''
export const routerPath = {
  root: '/',
  signIn: `${prefix}/sign-in`,
  invitation: `${prefix}/invitation`,
  verifyEmail: `${prefix}/email/:id/verify`,
  forgotPassword: `${prefix}/forgot-password`,
  forgotPasswordReset: `${prefix}/forgot-password/reset`,
  logout: `${prefix}/logout`,
  dashboard: `${prefix}/dashboard`,
  applications: `${prefix}/applications`,
  applicationDraft: `${prefix}/applications/draft/:id/:tab?`,
  applicationSubmitted: `${prefix}/applications/submitted/:id/:tab?/:entityId?`,
  applicationDocument: `${prefix}/applications/:applicationId/documents/:id`,
  applicationScopeOfWork: `${prefix}/applications/:loanId/:addressId/scope-of-work-bulk-edit`,
  loans: `${prefix}/loans`,
  loansTabActive: `${prefix}/loans/active`,
  loansTabPaidOff: `${prefix}/loans/paid-off`,
  loan: `${prefix}/loans/:id`,
  loanTab: `${prefix}/loans/:id/:tab`,
  loanTabEntityId: `${prefix}/loans/:id/:tab/:entityId`,
  loanDraw: `${prefix}/loans/:loanId/address/:addressId/draw/:drawId`,
  loanApplicationPrintable: `${prefix}/loans/:id/application/print`,
  loanDocument: `${prefix}/loans/:loanId/documents/:id`,
  loanScopeOfWork: `${prefix}/loans/:loanId/:addressId/scope-of-work-bulk-edit`,
  documents: `${prefix}/documents`,
  investorDocument: `${prefix}/documents/:id`,
  transactions: `${prefix}/transactions`,
  offerings: `${prefix}/offerings`,
  offeringFund: `${prefix}/offerings/offering-fund/:offeringId`,
  offeringLoan: `${prefix}/offerings/offering-loan/:offeringId`,
  offeringIndividualFund: `${prefix}/offerings/individual-fund/:fundId`,
  offeringIndividualLoan: `${prefix}/offerings/individual-loan/:loanId`,
  offeringDocument: `${prefix}/offerings/:offeringType/:offeringId/documents/:id`,
  publicDocuments: `${prefix}/public-documents/:authToken`,
  settings: `${prefix}/settings/:tab?`,
  createAccount: `${prefix}/create`,
  createInvestorAccount: `${prefix}/create-investor`,
  statement: `${prefix}/loan/:loanId/statement/:from/:to`,
  investorStatement: `${prefix}/investor/:investorId/statement/:from/:to`,
  quoteTermSheet: `${prefix}/quote/:id/term-sheet`,
  guestApplication: `${prefix}/application`,
  previewApplication: `${prefix}/preview-application`,
  receipt: `${prefix}/receipt/:loanId/:paymentId`,
}

export const pathTo = (key: keyof typeof routerPath, ...args: string[]) =>
  createPath(routerPath[key], args)
