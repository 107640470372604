import { Flex } from 'components/Flex'
import { Loan, Person } from 'types'
import { ContactCard } from './ContactCard'

interface Participant extends Person {
  guarantee?: { primary: boolean }
  borrow?: { primary: boolean }
}

interface Props {
  loan: Loan
  participants: Participant[]
}
function PanelContactCards({ loan, participants }: Props) {
  return (
    <Flex className="mb-6" flexWrap="wrap">
      {participants.map((participant) => (
        <ContactCard
          participant={participant}
          key={participant.id}
          loan={loan}
        />
      ))}
    </Flex>
  )
}

export { PanelContactCards }
