import { PropsWithChildren, useEffect, useState } from 'react'
import {
  Route,
  Routes,
  Navigate,
  matchPath,
  useNavigate,
} from 'react-router-dom'
import { pathTo as adminPathTo } from 'admin/path-to'
import { routerPath, pathTo } from 'borrower/path-to'
import { routes as borrowerRoutes } from 'borrower/routes'
import { AppCustomization } from 'components/AppCustomization'
import { LoaderOverlay } from 'components/LoaderOverlay'
import { useSessionManager } from 'hooks/use-session-manager'
import { CreateAccount } from 'pages/Session/CreateAccount'
import { SessionContext } from 'pages/Session/SessionContext'
import {
  SetPassword,
  SignIn,
  SignOut,
  ForgotPassword,
  VerifyEmail,
  SignUp,
} from 'pages/Session/index'
import {
  setFailedAuthCallback,
  setNotFoundAuthCallback,
} from 'services/request'
import { handlePermalink } from 'utils/permalink'

export const SessionProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const [originUrl, setOriginUrl] = useState('')
  const sessionManager = useSessionManager()

  const isSignedIn = sessionManager.isSignedIn && !sessionManager.isAdmin

  // Redirect to dashboard if user is signed as an admin and is not on the sign in page or create account page
  const publicPaths = [
    routerPath.createAccount,
    routerPath.verifyEmail,
    ...borrowerRoutes
      .filter(({ isPublic }) => isPublic)
      .map(({ path }) => path),
  ]
  if (
    sessionManager.isAdmin &&
    publicPaths.every(
      (path) => !matchPath({ path: path as string }, window.location.pathname)
    )
  ) {
    window.location.href = adminPathTo('dashboard')
    return null
  }
  useEffect(() => {
    sessionManager.start()
    setOriginUrl(window.location.pathname)
    // Notify session manager when an auth failure in the client occurs
    setFailedAuthCallback(sessionManager.invalidate)
    setNotFoundAuthCallback(() => navigate(pathTo('dashboard')))
  }, [])

  return (
    <SessionContext.Provider value={sessionManager}>
      {sessionManager.isLoading && !sessionManager.isSigningIn && (
        <LoaderOverlay />
      )}
      <Routes>
        <Route
          path={routerPath.createAccount}
          element={
            isSignedIn ? (
              <Navigate replace to={routerPath.root} />
            ) : (
              <AppCustomization allowNotAuthorized>
                <CreateAccount pathTo={pathTo} />
              </AppCustomization>
            )
          }
        />
        <Route
          path={routerPath.createInvestorAccount}
          element={
            isSignedIn ? (
              <Navigate replace to={routerPath.root} />
            ) : (
              <AppCustomization allowNotAuthorized>
                <CreateAccount pathTo={pathTo} investor />
              </AppCustomization>
            )
          }
        />
        <Route
          path={routerPath.signIn}
          element={
            isSignedIn ? (
              <Navigate
                replace
                to={handlePermalink(routerPath, originUrl, () =>
                  setOriginUrl('')
                )}
              />
            ) : (
              <AppCustomization allowNotAuthorized>
                <SignIn pathTo={pathTo} />
              </AppCustomization>
            )
          }
        />
        <Route
          path={routerPath.forgotPassword}
          element={
            isSignedIn ? (
              <Navigate replace to={routerPath.root} />
            ) : (
              <AppCustomization allowNotAuthorized>
                <ForgotPassword pathTo={pathTo} />
              </AppCustomization>
            )
          }
        />
        <Route
          path={routerPath.forgotPasswordReset}
          element={
            isSignedIn ? (
              <Navigate replace to={routerPath.root} />
            ) : (
              <AppCustomization allowNotAuthorized>
                <SetPassword pathTo={pathTo} />
              </AppCustomization>
            )
          }
        />
        <Route
          path={routerPath.verifyEmail}
          element={
            <AppCustomization allowNotAuthorized>
              <VerifyEmail pathTo={pathTo} />
            </AppCustomization>
          }
        />
        <Route
          path={routerPath.invitation}
          element={
            isSignedIn ? (
              <Navigate replace to={routerPath.root} />
            ) : (
              <AppCustomization allowNotAuthorized>
                <SignUp pathTo={pathTo} />
              </AppCustomization>
            )
          }
        />
        <Route
          path={routerPath.logout}
          element={
            !isSignedIn ? (
              <Navigate replace to={routerPath.signIn} />
            ) : (
              <SignOut pathTo={pathTo} />
            )
          }
        />
        {borrowerRoutes
          .filter(({ isPublic }) => isPublic)
          .map(({ path, component: Component, props = {} }) => (
            <Route
              key={path as string}
              path={path as string}
              element={
                <AppCustomization>
                  <Component {...props} />
                </AppCustomization>
              }
            />
          ))}

        <Route
          path="*"
          element={
            !isSignedIn && !sessionManager.isLoading ? (
              <Navigate replace to={routerPath.signIn} />
            ) : (
              children
            )
          }
        />
      </Routes>
    </SessionContext.Provider>
  )
}
