import { differenceInSeconds, format, parseISO } from 'date-fns'

const divisions: { amount: number; name: Intl.RelativeTimeFormatUnit }[] = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
]

const relativeTimeFormatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: 'auto',
})

export const formatDate = (
  date: string | Date | undefined,
  dateFormat = 'MM/dd/yyyy'
) => {
  const regexpDateFormat = new RegExp('\\d{4}-\\d{2}-\\d{2}')
  if (date instanceof Date) {
    return format(date, dateFormat)
  } else if (date && regexpDateFormat.test(date)) {
    const [year, month, day] = date.split('-')
    return format(
      new Date(parseInt(year), parseInt(month) - 1, parseInt(day)),
      dateFormat
    )
  } else if (date) {
    try {
      return format(new Date(date), dateFormat)
    } catch {
      /* ignore */
    }
  }
  return ''
}

export const friendlyDate = (date: string | Date | undefined) =>
  date ? formatDate(date, 'MMMM d, yyyy') : ''

export const friendlyDateTime = (
  date: string,
  dateFormat = "MMMM d, yyyy 'at' h:mm aaa"
) => {
  return date ? format(new Date(date), dateFormat) : ''
}

export const formatRelativeDate = (
  date: string | Date,
  {
    short = false,
    absolute = false,
  }: { short?: boolean; absolute?: boolean } = {}
) => {
  const dateObj = typeof date === 'string' ? parseISO(date) : date
  let duration = differenceInSeconds(dateObj, new Date())

  for (let i = 0; i < divisions.length; i++) {
    const division = divisions[i]
    if (Math.abs(duration) < division.amount) {
      if (short) {
        const divisionName =
          division.name === 'months' ? 'mo' : division.name.charAt(0)
        const amount = absolute
          ? Math.abs(Math.round(duration))
          : Math.round(duration)

        return `${amount}${divisionName}`
      }
      return relativeTimeFormatter.format(Math.round(duration), division.name)
    }
    duration = duration / division.amount
  }
}
