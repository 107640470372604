import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { isObject, isString } from 'lodash'
import qs from 'qs'
import { snakeCaseKeys, camelCaseKeys } from 'utils/keys'
import { message } from 'utils/message'
import { version } from 'utils/version'
import { setAuthTokens, getAuthTokens } from './storage'

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const snakeCaseInterceptor = (config: AxiosRequestConfig) => {
  const newConfig = { ...config }

  if (config.params) {
    newConfig.params = snakeCaseKeys(config.params)
  }
  if (config.data && !config.params?.noSnakeCase) {
    newConfig.data = snakeCaseKeys(config.data)
  }
  return newConfig
}

const camelCaseInterceptor = (response: AxiosResponse) => {
  if (response.data instanceof Blob || response.data instanceof ArrayBuffer) {
    return response
  }

  const newConfig = { ...response }
  if (response.data && !response.config.params?.no_camel_case) {
    newConfig.data = camelCaseKeys(response.data)
  }
  return newConfig
}

let failedAuthCallback: CallableFunction = () => {}
let failedNotFoundCallback: CallableFunction = () => {}
let redirectOnLoginUrl = ''

export const setFailedAuthCallback = (fn: CallableFunction) =>
  (failedAuthCallback = fn)
export const setNotFoundAuthCallback = (fn: CallableFunction) =>
  (failedNotFoundCallback = fn)
export const setRedirectOnLoginUrl = (url: string) => (redirectOnLoginUrl = url)
export const getRedirectOnLoginUrl = () => redirectOnLoginUrl

const refreshSession = async (failedRequest: any) => {
  const { accessToken, refreshToken } = getAuthTokens()
  const { data } = await request.patch('/login', {
    authToken: accessToken,
    refreshToken: refreshToken,
  })

  setAuthTokens(data.authToken, data.refreshToken)

  failedRequest.response.config.headers['Authorization'] =
    `Bearer ${data.authToken}`
  return Promise.resolve()
}

const request = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 20000,
  paramsSerializer: (params) =>
    qs.stringify(
      Object.keys(params).reduce(
        (acc, key) => ({
          ...acc,
          [key]: isObject(params[key])
            ? JSON.stringify(params[key])
            : params[key],
        }),
        {}
      )
    ),
})

createAuthRefreshInterceptor(request, refreshSession, {
  statusCodes: [403],
})

request.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('access_token')

  if (!accessToken) {
    failedAuthCallback()
    config.cancelToken = new axios.CancelToken((cancel) =>
      cancel('Missing access token')
    )
  }

  if (!config['headers']) {
    config['headers'] = {}
  }

  config['headers']['Authorization'] = `Bearer ${accessToken}`
  config['headers']['X-Baseline-Version'] = version
  config['headers']['X-Baseline-Timezone'] = timezone

  return config
})

request.interceptors.request.use(snakeCaseInterceptor)
request.interceptors.response.use(camelCaseInterceptor)

const requestWithoutToken = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 20000,
})

requestWithoutToken.interceptors.request.use(snakeCaseInterceptor)
requestWithoutToken.interceptors.response.use(camelCaseInterceptor)

const handleErrorResponse = (error: AxiosError) => {
  if ([401, 403].includes(error?.response?.status as number)) {
    failedAuthCallback()
  } else if (error?.response?.status === 404) {
    failedNotFoundCallback()
    message.error(
      isString(error.response?.data) ? error.response?.data : 'Not found'
    )
  } else if (error.response?.data && isString(error.response?.data)) {
    message.error(error.response.data)
  } else if (
    (error.response?.data as any)?.error &&
    isString((error.response?.data as any)?.error)
  ) {
    message.error((error.response?.data as any).error)
  } else if (error.name !== 'CanceledError') {
    console.error(error)
  }
}

export { request, requestWithoutToken, handleErrorResponse }
